import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { PaymentMethodSelect as PaymentMethodSelectField } from "../../../components/PaymentMethodSelect/PaymentMethodSelect";
import { Container } from "./PaymentMethodSelect.styles";

export function PaymentMethodSelect() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const handleChange = (paymentMethod) =>
    setCurrentPayment({
      paymentMethod,
      paymentInstallments: "1",
      chargedSeparately: false,
      cardFlag: null,
      pixService: null,
      nsu: null,
      aut: null,
      automaticConciliation: null,
    });

  return (
    <Container>
      <PaymentMethodSelectField
        value={currentPayment.paymentMethod}
        onChange={handleChange}
      />
    </Container>
  );
}
