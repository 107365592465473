import { Icon } from "@shopify/polaris";
import { DeleteMajor, CircleTickMajor } from "@shopify/polaris-icons";

import { ActionCard } from "../../../../../../component/ActionCard/ActionCard";
import {
  ActionCardContentContainer,
  IconContainer,
  SelectedAutomaticConciliationContainer,
  Text,
  TitleContainer,
  TitleText,
} from "./SelectedAutomaticConciliation.styles";

function ActionCardContent({ automaticConciliation }) {
  return (
    <ActionCardContentContainer>
      <Text>{automaticConciliation.value}</Text>
      <Text>NSU: {automaticConciliation.nsu}</Text>
      <Text>AUT: {automaticConciliation.aut}</Text>
      <Text>{automaticConciliation.date}</Text>
    </ActionCardContentContainer>
  );
}

function Title() {
  return (
    <TitleContainer>
      <TitleText>Conciliado</TitleText>

      <IconContainer>
        <Icon source={CircleTickMajor} color="success" />
      </IconContainer>
    </TitleContainer>
  );
}

export function SelectedAutomaticConciliation({
  automaticConciliation,
  setAutomaticConciliation,
}) {
  return (
    <SelectedAutomaticConciliationContainer>
      <ActionCard
        title={<Title />}
        onIconClick={() => setAutomaticConciliation(null)}
        Icon={DeleteMajor}
      >
        <ActionCardContent automaticConciliation={automaticConciliation} />
      </ActionCard>
    </SelectedAutomaticConciliationContainer>
  );
}
