import { setHours } from "date-fns";
import { create } from "zustand";

const calculateFilterUntilDate = (selectedDate) => {
  const nextDay = new Date(selectedDate);
  nextDay.setDate(nextDay.getDate() + 1);
  nextDay.setHours(3, 0, 0, 0);
  return nextDay;
};

export const usePixAnalyticsStore = create((set) => ({
  selectedDate: setHours(new Date(), 3),
  filterUntilDate: calculateFilterUntilDate(new Date()),

  setSelectedDate(selectedDate) {
    set({
      selectedDate,
      filterUntilDate: calculateFilterUntilDate(selectedDate),
    });
  },

  setFilterUntilDate(filterUntilDate) {
    set({ filterUntilDate });
  },
}));
