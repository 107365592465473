import { isShopifyPOS } from "@shopify/app-bridge-utils";
import { Page, Layout, List, Banner } from "@shopify/polaris";
import { useFlags } from "launchdarkly-react-client-sdk";
import LocationListCard from "../component/LocationListCard/LocationListCard.jsx";
import LocationReport from "../component/LocationReport/LocationReport.jsx";
import TitleBar from "../component/TitleBar/TitleBar.jsx";
import VersionFooter from "../component/VersionFooter.jsx";
import VirtualStoreCard from "../component/VirtualStoreCard/VirtualStoreCard.jsx";
import useHideLoadingIndicator from "../hook/useHideLoadingIndicator.js";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";

const _isShopifyPOS = isShopifyPOS();

export default function ConfigurationPage() {
  const shopifyShopUrl = useShopifyShopUrl();
  const { pixReport: pixReportFlag } = useFlags();

  useHideLoadingIndicator();

  return (
    <Page narrowWidth>
      <TitleBar title="Configuração" />
      <Layout>
        <Layout.Section>
          {!_isShopifyPOS && (
            <Banner
              status="warning"
              title="Algumas funcionalidades necessitam do Shopify POS"
            >
              <List>
                <List.Item>
                  Não será possível associar dispositivos PdV às maquininhas.
                </List.Item>
              </List>
              <p style={{ marginTop: "4px" }}>
                Este app foi criado para ser usado dentro do Shopify POS. Por
                favor, adicione o Shopify POS à sua loja e acesse este app
                dentro dele.
              </p>
            </Banner>
          )}
        </Layout.Section>
        <Layout.Section>
          <VirtualStoreCard shopifyShopUrl={shopifyShopUrl} />
        </Layout.Section>
        <Layout.Section>
          <LocationListCard shopifyShopUrl={shopifyShopUrl} />
        </Layout.Section>
        {pixReportFlag && (
          <Layout.Section>
            <LocationReport shopifyShopUrl={shopifyShopUrl} />
          </Layout.Section>
        )}
      </Layout>
      <VersionFooter />
    </Page>
  );
}
