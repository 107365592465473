import { Button } from "@shopify/polaris";

import { useCurrentMultiplePayment } from "../../../../../../../../store/useMultiplePaymentsStore";
import {
  MULTIPLE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../../store/usePaymentFlowStore";

export function PixSubmit({ disabled, onClick }) {
  const { currentPayment } = useCurrentMultiplePayment();

  const { setMultiplePaymentsStep } = usePaymentFlowStore();

  const getIsDisabled = () => {
    return disabled;
  };

  const isDisabled = getIsDisabled();

  const buttonText = currentPayment.chargedSeparately
    ? "Continuar"
    : "Gerar QR Code";

  const handleClick = () => {
    onClick();

    if (!currentPayment.chargedSeparately) {
      setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.PIX.value);
    }
  };

  return (
    <Button primary disabled={isDisabled} onClick={handleClick}>
      {buttonText}
    </Button>
  );
}
