import styled from "styled-components";

export const Container = styled.div``;

export const ActionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const FieldAmountPaidContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 12px;
`;
export const Title = styled.h3`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
`;
