import { ChevronRightMinor } from "@shopify/polaris-icons";
import { CardInformation } from "../../../../component/CardInformation/CardInformation";
import useFiscalData from "../../../../hook/useFiscalData";
import {
  ANALYTICS_TABS,
  useAnalyticsTabsStore,
} from "../../../../store/useAnalyticsTabsStore";
import { PaymentTypesCard } from "../PaymentTypesCard/PaymentTypesCard";
import {
  Container,
  ContainerTableInformation,
} from "./CardDetailsGroup.styles";

export function CardDetailsGroup() {
  const { setDashboardTab } = useAnalyticsTabsStore();

  const {
    numberOfOrders,
    totalSalesValue,
    averageTicket,
    averageItemsPerOrder,
  } = useFiscalData();

  const goToSallesSummaryTab = () => {
    setDashboardTab(ANALYTICS_TABS.SUMMARY_SALE_STEP.value);
  };

  function formatCurrency(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  const orders = numberOfOrders;
  const salesTotal = formatCurrency(totalSalesValue);
  const items = averageItemsPerOrder.toFixed(2);
  const avaregeValueOrder = formatCurrency(averageTicket);
  const CARD_DETAILS_GROUP = [
    {
      id: 1,
      title: "Pedidos",
      text: orders,
    },
    {
      id: 2,
      title: "Vendas",
      text: salesTotal,
      icon: ChevronRightMinor,
    },
    {
      id: 3,
      title: "Itens/Pedido",
      text: items,
    },
    {
      id: 4,
      title: "Ticket Médio",
      text: avaregeValueOrder,
    },
  ];

  return (
    <>
      <Container>
        {CARD_DETAILS_GROUP.map((item) => (
          <CardInformation
            key={item.id}
            title={item.title}
            text={item.text}
            onClick={item.id === 2 && goToSallesSummaryTab}
            icon={item.id === 2 && item.icon}
          />
        ))}
      </Container>
      <ContainerTableInformation>
        <PaymentTypesCard />
      </ContainerTableInformation>
    </>
  );
}
