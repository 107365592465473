import { TextField, Icon } from "@shopify/polaris";
import { CircleAlertMajor } from "@shopify/polaris-icons";

import { format, isValidCPF } from "../../../helper/cpf";
import {
  Container,
  ErrorField,
  ErrorFieldContainer,
  IconContainer,
} from "./CPFField.styles";

export function CPFField({ value, onChange, errorText }) {
  const handleChangeValue = (newValue) => {
    const CPF = newValue.replace(/\D/g, "");

    if (CPF.length < 11) {
      onChange(CPF);
      return;
    }

    if (!isValidCPF(CPF)) {
      onChange(CPF.substring(0, 11));
      return;
    }

    onChange(format(CPF));
  };

  const getCPF = () => {
    const CPF = value?.replace(/\D/g, "");
    if (!CPF) {
      return "";
    }

    if (CPF.length < 11 || !isValidCPF(CPF)) {
      return CPF;
    }

    return format(CPF);
  };

  const getError = () => {
    if (errorText) {
      return errorText;
    }

    const CPF = value?.replace(/\D/g, "");

    if (!CPF || CPF.length < 11 || isValidCPF(CPF)) {
      return null;
    }

    return "CPF inválido.";
  };

  const CPF = getCPF(value);
  const error = getError();

  return (
    <Container>
      <TextField
        label="CPF (Opcional)"
        value={CPF}
        onChange={handleChangeValue}
        inputMode="numeric"
        maxLength={14}
        error={Boolean(error)}
      />

      {error && (
        <ErrorFieldContainer>
          <IconContainer>
            <Icon source={CircleAlertMajor} color="critical" />
          </IconContainer>

          <ErrorField>{error}</ErrorField>
        </ErrorFieldContainer>
      )}
    </Container>
  );
}
