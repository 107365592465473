import { Button } from "@shopify/polaris";
import React from "react";
import {
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../store/usePaymentFlowStore";
import { AutField } from "../AutFied/AutFied";
import { AutomaticConciliation } from "../AutomaticConciliation/AutomaticConciliation";
import { CardFlagSelect } from "../CardFlagSelect/CardFlagSelect";
import { ChargedSeparatelySwitcher } from "../ChargedSeparatelySwitcher/ChargedSeparatelySwitcher";
import { InstallmentSelect } from "../InstallmentSelect/InstallmentSelect";
import { NsuIdField } from "../NsuIdField/NsuIdField";
import { PaymentMethodSelect } from "../PaymentMethodSelect/PaymentMethodSelect";
import { SubmitButtonUniquePayment } from "./components/SubmitButton/SubmitButtonUniquePayment";
import { ActionsContainer, Container } from "./PaymentForm.styles";

export function PaymentForm() {
  const { setPaymentStep } = usePaymentFlowStore();
  const goToPreviousStep = () => {
    setPaymentStep(PAYMENT_STEPS.CHOOSE_STEP.value);
  };

  return (
    <Container>
      <PaymentMethodSelect />

      <InstallmentSelect />

      <ChargedSeparatelySwitcher />

      <CardFlagSelect />

      <NsuIdField />

      <AutField />

      <AutomaticConciliation />

      <ActionsContainer>
        <Button onClick={goToPreviousStep}>Voltar</Button>
        <SubmitButtonUniquePayment />
      </ActionsContainer>
    </Container>
  );
}
