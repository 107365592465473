import { Title } from "../../../../../Title/Title";
import { AmountPaidField } from "../../../components/AmountPaidField/AmountPaidField";
import { AutField } from "../../../components/AutFied/AutFied";
import { AutomaticConciliation } from "../../../components/AutomaticConciliation/AutomaticConciliation";
import { CardFlagSelect } from "../../../components/CardFlagSelect/CardFlagSelect";
import { ChargedSeparatelySwitcher } from "../../../components/ChargedSeparatelySwitcher/ChargedSeparatelySwitcher";
import { InstallmentSelect } from "../../../components/InstallmentSelect/InstallmentSelect";
import { NsuField } from "../../../components/NsuField/NsuField";
import { PaymentMethodSelect } from "../../../components/PaymentMethodSelect/PaymentMethodSelect";
import { BackButton } from "../BackButton/BackButton";
import { SubmitButton } from "../SubmitButton/SubmitButton";
import { ActionsContainer, Container } from "./Payment.styles";

export function Payment({ paymentNumber }) {
  return (
    <Container>
      <Title>Pagamento {paymentNumber}</Title>

      <AmountPaidField />

      <PaymentMethodSelect />

      <InstallmentSelect />

      <ChargedSeparatelySwitcher />

      <CardFlagSelect />

      <NsuField />

      <AutField />

      <AutomaticConciliation />

      <ActionsContainer>
        <BackButton />
        <SubmitButton />
      </ActionsContainer>
    </Container>
  );
}
