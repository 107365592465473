import { useCallback } from "react";

import { usePaymentFlowStore } from "../../../../../../../store/usePaymentFlowStore";
import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { InstallmentSelect as InstallmentSelectInput } from "../../../components/InstallmentSelect/InstallmentSelect";
import { Container } from "./InstallmentSelect.styles";

export function InstallmentSelect() {
  const { paymentMethod, paymentInstallments, setPaymentInstallments } =
    useUniquePaymentStore();

  const { installmentOptions } = usePaymentFlowStore();

  const handleSelectChange = useCallback(
    (value) => setPaymentInstallments(value),
    []
  );

  if (paymentMethod !== "CREDIT") {
    return null;
  }

  return (
    <Container>
      <InstallmentSelectInput
        options={installmentOptions}
        value={paymentInstallments}
        onChange={handleSelectChange}
      />
    </Container>
  );
}
