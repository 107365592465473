import { formatCurrency } from "../component/FormatCurrency/R$.jsx";

export default function getMaxInstallments({
  installments = { default: 3 },
  totalAmount,
}) {
  const maxInstallmentsPerValue = installments?.minimumInstallmentValue
    ? Math.floor(totalAmount / installments.minimumInstallmentValue)
    : installments?.default;

  const maxInstallmentsPerOrderValue = installments.orderValue
    ? installments.orderValue
        .sort((a, b) => b.amount - a.amount) // we want the largest value first because
        .find(({ amount }) => parseFloat(totalAmount) >= amount) // find returns the first one that satisfies the condition
        ?.maxInstallments || installments.default
    : installments.default;

  let _maxInstallments = Math.min(
    installments.default,
    maxInstallmentsPerValue,
    maxInstallmentsPerOrderValue,
  );

  _maxInstallments = Math.max(_maxInstallments, 1);

  return [...Array(_maxInstallments).keys()].map((x) => {
    const times = x + 1;

    if (times === 1) {
      return {
        label: "1x",
        value: `${times}`,
      };
    }

    return {
      label: `${times}x de ${formatCurrency(parseFloat(totalAmount) / times)}`,
      value: `${times}`,
    };
  });
}
