import { useCallback, useEffect, useState } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import firestore from "@iglu/common/firestore";

const STATUS = { IS_FETCHING: 0, DID_SUCCEED: 1, DID_FAIL: 2 };

const cache = {
  // [collectionName]: { [JSON.stringify(queryConstraintList)]: documentValueList }
};
function readFromCache(collectionName, queryConstraintListJson) {
  return (cache[collectionName] ?? {})[queryConstraintListJson];
}
function writeToCache(
  collectionName,
  queryConstraintListJson,
  documentValueList
) {
  if (!cache[collectionName]) {
    cache[collectionName] = {};
  }
  cache[collectionName][queryConstraintListJson] = documentValueList;
}

export default function useFirestoreQuery(
  collectionName,
  ...queryConstraintList
) {
  const queryConstraintListJson = JSON.stringify(queryConstraintList);

  const [data, setData] = useState(() =>
    readFromCache(collectionName, queryConstraintListJson)
  );
  const [status, setStatus] = useState(() =>
    data === undefined ? STATUS.IS_FETCHING : STATUS.DID_SUCCEED
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    const firestoreQuery = query(
      collection(firestore, collectionName),
      ...queryConstraintList.map(([field, operator, value]) =>
        where(field, operator, value)
      )
    );

    const unsubscribe = onSnapshot(firestoreQuery, {
      error: (error) => {
        setError(error);
        if (status !== STATUS.DID_FAIL) setStatus(STATUS.DID_FAIL);
      },
      next: ({ docs: snapshotList }) => {
        const nextData = snapshotList.map((snapshot) => snapshot.data());
        setData(nextData);
        if (status !== STATUS.DID_SUCCEED) setStatus(STATUS.DID_SUCCEED);
        writeToCache(collectionName, queryConstraintListJson, nextData);
      },
    });

    return unsubscribe;
  }, [collectionName, queryConstraintListJson, status]);

  const isLoading = status === STATUS.IS_FETCHING;
  const didFail = status === STATUS.DID_FAIL;
  const didSucceed = status === STATUS.DID_SUCCEED;

  const optimisticUpdate = useCallback(
    (stateUpdateFunction) =>
      setData((previousData) => {
        const nextData = stateUpdateFunction(previousData);
        writeToCache(collectionName, queryConstraintListJson, nextData);
        return nextData;
      }),
    [collectionName, queryConstraintListJson]
  );

  return { isLoading, didFail, didSucceed, error, data, optimisticUpdate };
}
