import { useCallback, useMemo, useState } from "react";
import {
  Card,
  FormLayout,
  TextContainer,
  Stack,
  Spinner,
} from "@shopify/polaris";
import PspDeviceItem from "./PspDeviceItem.jsx";
import CreatePspDeviceModal from "./CreatePspDeviceModal.jsx";
import ConfirmationModal from "../../component/modal/Confirmation.jsx";
import useFirestoreQuery from "../../hook/useFirestoreQuery.js";
import useDevice from "../../hook/useDevice.js";
import backend from "../../service/backend.js";
import useFirestoreDoc from "../../hook/useFirestoreDoc.js";

export default function PspDeviceListCard({ shopifyShopUrl, posLocation }) {
  const configurationDoc = useFirestoreDoc("configuration", shopifyShopUrl);

  const pspDeviceListQuery = useFirestoreQuery(
    "pspDevice",
    ["companyShop", "==", shopifyShopUrl],
    ["posLocationId", "==", posLocation.id]
  );

  const device = useDevice();
  const posDeviceId = device?.pos?.device?.serialNumber ?? null;
  // ^ This sometimes fails... we're assuming it always succeeds.

  const deviceRelationListQuery = useFirestoreQuery(
    "deviceRelation",
    ["companyShop", "==", shopifyShopUrl],
    ["posLocationId", "==", posLocation.id],
    ["posDeviceId", "==", posDeviceId]
  );

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const openCreateModal = () => setCreateModalIsOpen(true);
  const closeCreateModal = () => setCreateModalIsOpen(false);
  const createPspDevice = useCallback(
    async ({ newPspDeviceSerialNumber }) => {
      if (newPspDeviceSerialNumber !== "") {
        closeCreateModal();

        // Optimistic update
        const psp =
          newPspDeviceSerialNumber.length > 11 ? "mercadopago" : "pagarme";
        // eslint-disable-next-line no-inner-declarations
        function documentKey({ companyShop, posLocationId, id }) {
          return `${companyShop}:${posLocationId}:${id}`;
        }
        pspDeviceListQuery.optimisticUpdate((previousList) =>
          [
            ...previousList,
            {
              companyShop: shopifyShopUrl,
              id:
                psp === "pagarme"
                  ? newPspDeviceSerialNumber
                  : `${newPspDeviceSerialNumber}`,
              posLocationId: posLocation.id,
              psp,
              serialNumber: newPspDeviceSerialNumber,
            },
          ].sort((d0, d1) => documentKey(d0).localeCompare(documentKey(d1)))
        );
        // Optimistic update

        backend.executeProcess(
          {
            type: "config/createPspDevice",
            input: {
              companyShop: shopifyShopUrl,
              posLocationId: posLocation.id,
              newPspDeviceSerialNumber,
            },
          },
          { unique: true }
        );
      }
    },
    [shopifyShopUrl, posLocation.id]
  );
  // adding a comment to force deploy

  // Confirm PSP-device deletion.
  const [deletePspDeviceId, setDeletePSPDeviceId] = useState(null);
  const confirmModalIsOpen = deletePspDeviceId !== null;
  const openConfirmModal = setDeletePSPDeviceId;
  const closeConfirmModal = () => setDeletePSPDeviceId(null);
  const confirmModalPrompt = useMemo(
    () => `Deseja realmente excluir a maquininha de ID ${deletePspDeviceId}?`,
    [deletePspDeviceId]
  );
  const deletePspDevice = useCallback(() => {
    // Optimistic update
    pspDeviceListQuery.optimisticUpdate((previousList) => {
      const nextList = [...previousList];
      nextList.splice(
        nextList.findIndex((d) => d.id === deletePspDeviceId),
        1
      );
      return nextList;
    });
    // Optimistic update

    backend.executeProcess(
      {
        type: "config/deletePspDevice",
        input: {
          companyShop: shopifyShopUrl,
          posLocationId: posLocation.id,
          pspDeviceId: deletePspDeviceId,
        },
      },
      { unique: true }
    );
  }, [shopifyShopUrl, posLocation.id, deletePspDeviceId]);

  const associate = useCallback(
    ({ id: pspDeviceId, psp: pspDevicePsp }) => {
      // Optimistic update
      deviceRelationListQuery.optimisticUpdate((previousList) => [
        ...previousList,
        {
          companyShop: shopifyShopUrl,
          posLocationId: posLocation.id,
          posDeviceId,
          pspDeviceId,
          pspDevicePsp,
        },
      ]);
      // Optimistic update

      backend.executeProcess(
        {
          type: "config/associateDevices",
          input: {
            companyShop: shopifyShopUrl,
            posLocationId: posLocation.id,
            posDeviceId,
            pspDeviceId,
            pspDevicePsp,
          },
        },
        { unique: true }
      );
    },
    [shopifyShopUrl, posLocation.id, posDeviceId]
  );
  const dissociate = useCallback(
    (pspDeviceId) => {
      // Optimistic update
      deviceRelationListQuery.optimisticUpdate((previousList) => {
        const nextList = [...previousList];
        nextList.splice(
          nextList.findIndex(
            (d) =>
              d.companyShop === shopifyShopUrl &&
              d.posLocationId === posLocation.id &&
              d.pspDeviceId === pspDeviceId &&
              d.posDeviceId === posDeviceId
          ),
          1
        );
        return nextList;
      });
      // Optimistic update

      backend.executeProcess(
        {
          type: "config/dissociateDevices",
          input: {
            companyShop: shopifyShopUrl,
            posLocationId: posLocation.id,
            pspDeviceId,
            posDeviceId,
          },
        },
        { unique: true }
      );
    },
    [shopifyShopUrl, posLocation.id, posDeviceId]
  );

  if (pspDeviceListQuery.isLoading || deviceRelationListQuery.isLoading) {
    return (
      <Card sectioned title="Maquininhas">
        <Stack distribution="center" alignment="center">
          <Stack.Item>
            <Spinner />
          </Stack.Item>
        </Stack>
      </Card>
    );
  } else if (pspDeviceListQuery.didFail || deviceRelationListQuery.didFail) {
    return (
      <Card sectioned title="Maquininhas">
        <TextContainer>
          <p style={{ color: "#f00", textAlign: "center" }}>
            {
              [
                configurationDoc.error,
                pspDeviceListQuery.error,
                deviceRelationListQuery.error,
              ].find((r) => r.error).message
            }
          </p>
        </TextContainer>
      </Card>
    );
  } else {
    return (
      <>
        <ConfirmationModal
          isOpen={confirmModalIsOpen}
          onClose={closeConfirmModal}
          onConfirm={deletePspDevice}
          onCancel={closeConfirmModal}
          prompt={confirmModalPrompt}
        />
        <CreatePspDeviceModal
          posLocationCNPJ={posLocation.cnpj}
          isOpen={createModalIsOpen}
          onClose={closeCreateModal}
          onSubmit={createPspDevice}
        />
        <Card
          sectioned
          title="Maquininhas"
          actions={[
            {
              content: "Adicionar",
              onAction: openCreateModal,
            },
          ]}
        >
          {pspDeviceListQuery.data.length === 0 ? (
            <TextContainer>
              <p style={{ textAlign: "center" }}>
                Nenhuma maquininha neste local
              </p>
            </TextContainer>
          ) : (
            <FormLayout>
              {pspDeviceListQuery.data.map(({ psp, id, serialNumber }) => {
                return (
                  <PspDeviceItem
                    key={id}
                    isInsideShopifyPOS={device.type === "shopifyPos"}
                    psp={psp ?? "pagarme"}
                    id={id}
                    serialNumber={serialNumber ?? id}
                    deviceRelationList={deviceRelationListQuery.data}
                    onDelete={() => openConfirmModal(id)}
                    onAssociate={associate}
                    onDissociate={dissociate}
                  />
                );
              })}
            </FormLayout>
          )}
        </Card>
      </>
    );
  }
}
