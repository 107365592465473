import { DeleteMajor, CircleTickMajor } from "@shopify/polaris-icons";
import { useState } from "react";

import { ActionCard } from "../../../../../../../../component/ActionCard/ActionCard";
import ConfirmationModal from "../../../../../../../../component/modal/Confirmation";
import R$ from "../../../../../../../../component/FormatCurrency/R$";
import useDevice from "../../../../../../../../hook/useDevice";
import useLocation from "../../../../../../../../hook/useLocation";
import backend from "../../../../../../../../service/backend";
import { useMultiplePaymentStore } from "../../../../../../../../store/useMultiplePaymentsStore";
import { useOrderStore } from "../../../../../../../../store/useOrderStore";

import { ActionCardContentContainer, Text } from "./PaidPayment.styles";

const PAYMENT_METHODS = {
  CREDIT: "Crédito",
  DEBIT: "Débito",
  CASH: "Dinheiro",
  PIX: "PIX",
  LINK: "Link de pagamento",
  "STORE-CREDIT": "Troca - Créditos",
  "GIFT-CARD": "Cartão presente",
};

export function PaidPayment({ payment, paymentNumber, disabled }) {
  const isAutomaticPayment =
    !payment.chargedSeparately &&
    !["CASH", "LINK", "STORE-CREDIT"].includes(payment.paymentMethod);

  const isTefPayment =
    payment?.service === "softwareexpress" && !payment?.wasConciliation;

  const isRemovablePayment = isTefPayment || !isAutomaticPayment;

  const [isModalOpen, setIsModalOpen] = useState();

  const { companyShop, shopifyOrderId } = useOrderStore();

  const location = useLocation();
  const locationCnpj = location?.cnpj ?? "";
  const locationSoftwareexpressClientId =
    location?.payment?.card?.softwareexpress?.clientId;

  const device = useDevice();
  const posDeviceId = `${device?.pos?.device?.serialNumber}`;

  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenModal = () => setIsModalOpen(true);

  const { setPaymentStatus } = useMultiplePaymentStore();

  const handleConfirm = async () => {
    try {
      setPaymentStatus(payment.id, "PENDING");
      await removeBackendPayment(payment.id);
    } catch (e) {
      setPaymentStatus(payment.id, "PAID");
    }
  };

  const removeBackendPayment = (paymentId) => {
    if (isTefPayment) {
      window.open(
        `iglu://refund-tef-payment/?${new URLSearchParams({
          cnpj: locationCnpj,
          clid: locationSoftwareexpressClientId,
          pdid: posDeviceId,
          borg:
            !import.meta.env || !import.meta.env.PROD
              ? window.location.origin
              : window.location.origin.replace(/app-/, ""),
          pkey: `${companyShop}:${shopifyOrderId}:${payment.id}`, // payment-document-key
          pnsu: payment.nsu,
          pcat: getPaymentCreatedAtDate(payment), // payment-created-at-date ddmmyyyy
          pcent: payment.amountCents,
          pmeth: payment.method,
          pinst: payment.installments,
          pwip: location?.printer?.wifi?.ip,
        })}`,
        "_blank",
      );
    } else {
      backend.executeProcess(
        {
          type: "removePayment",
          input: {
            companyShop,
            shopifyOrderId,
            paymentId,
          },
        },
        { unique: true },
      );
    }
  };

  return (
    <>
      <ActionCard
        title={`Pagamento ${paymentNumber}`}
        loading={payment.status !== "PAID"}
        disabled={isAutomaticPayment ? false : disabled}
        Icon={isRemovablePayment ? DeleteMajor : CircleTickMajor}
        onIconClick={isRemovablePayment ? handleOpenModal : undefined}
      >
        <ActionCardContentContainer>
          <Text>{PAYMENT_METHODS[payment.paymentMethod]}</Text>
          <R$>{payment.value / 100}</R$>
          {payment.nsu && <Text>NSU: {payment.nsu}</Text>}
          {payment.aut && <Text>AUT: {payment.aut}</Text>}
        </ActionCardContentContainer>
      </ActionCard>

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Excluir pagamento"
        prompt="Após confirmação, o pagamento cadastrado será excluído."
        confirmText="Excluir pagamento"
        onConfirm={handleConfirm}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
      />
    </>
  );
}

// Format ddmmyyyy
function getPaymentCreatedAtDate({ createdAt }) {
  const date = new Date(createdAt),
    day = `${date.getDate()}`.padStart(2, "0"),
    month = `${date.getMonth() + 1}`.padStart(2, "0"),
    year = `${date.getFullYear()}`;
  return `${day}${month}${year}`;
}
