import { withLDProvider } from "launchdarkly-react-client-sdk";
import {
  BrowserRouter as BrowserRouterProvider,
  Routes,
  Route,
} from "react-router-dom";
import BarcodePage from "../../page/BarcodePage.jsx";
import CancelSalePage from "../../page/CancelSale.jsx";
import ConfigurationPage from "../../page/Configuration.jsx";
import { IgluAnalytics } from "../../page/IgluAnalytics/IgluAnalytics.jsx";
import LinkPage from "../../page/LinkPage/LinkPage.jsx";
import LocationPage from "../../page/Location.jsx";
import NfcePage from "../../page/NfcePage/NfcePage.jsx";
import NotFoundPage from "../../page/NotFound.jsx";
import PaymentsPage from "../../page/Payments/Payments.jsx";
import PixAnalytics from "../../page/PixAnalytics/PixAnalytics.jsx";
import ReportZPage from "../../page/ReportZ.jsx";
import ReturnPage from "../../page/Return/Return.jsx";
import UnexpectedErrorPage from "../../page/unexpectedErrorPage/UnexpectedErrorPage.jsx";
import OneButton from "../oneButton/OneButton.jsx";
import AppBridgeProvider from "./AppBridgeProvider.jsx";
import ErrorBoundary from "./ErrorBoundary.jsx";
import ErrorFallback from "./ErrorFallback.jsx";
import handleError from "./handleError.js";
import PolarisProvider from "./PolarisProvider.jsx";
import useCoreWebVitalsMeasurements from "./useCoreWebVitalsMeasurements.js";

window.addEventListener("error", handleError);

function App() {
  useCoreWebVitalsMeasurements();

  return (
    <BrowserRouterProvider>
      <AppBridgeProvider>
        <PolarisProvider>
          <ErrorBoundary fallback={ErrorFallback}>
            <Routes>
              <Route path="/" element={<ConfigurationPage />} />

              <Route path="/barcode" element={<BarcodePage />} />

              <Route path="/one-button-page" element={<OneButton />} />

              <Route path="/nfce-page" element={<NfcePage />} />

              <Route path="/location/:locationId" element={<LocationPage />} />

              <Route path="/return" element={<ReturnPage />} />

              <Route path="/cancel-sale" element={<CancelSalePage />} />

              <Route path="/payments" element={<PaymentsPage />} />

              <Route path="/relatorioz" element={<ReportZPage />} />

              <Route path="*" element={<NotFoundPage />} />

              <Route path="/iglu-analytics" element={<IgluAnalytics />} />

              <Route path="/pix-analytics" element={<PixAnalytics />} />

              <Route path="/payment-summary" element={<PaymentsPage />} />

              <Route path="/pix-analytics" element={<PixAnalytics />} />

              <Route path="/link" element={<LinkPage />} />

              <Route path="/error-page" element={<UnexpectedErrorPage />} />
            </Routes>
          </ErrorBoundary>
        </PolarisProvider>
      </AppBridgeProvider>
    </BrowserRouterProvider>
  );
}

export default withLDProvider({
  clientSideID: "65565e8753357f13b56b231f",
  context: {
    kind: "organization",
    key: process.env.SHOPIFY_SHOP_URL,
  },
})(App);
