import { create } from "zustand";

export const useIgluAnalyticsStore = create((set) => ({
  staffFilter: "allSales",
  dateToFilter: "today",
  fiscalInvoice: [],

  setStaffFilter(staffFilter) {
    set({ staffFilter });
  },

  setDateToFilter(dateToFilter) {
    set({ dateToFilter });
  },

  setFiscalInvoice(fiscalInvoice) {
    set({ fiscalInvoice });
  },
}));
