import React from "react";
import { v4 as uuid } from "uuid";

import { useCurrentMultiplePendingPayment } from "../../../../../../store/useMultiplePaymentsStore.js";
import {
  MULTIPLE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore.js";
import { MachinePayment as MachinePaymentPage } from "../../components/MachinePayment/MachinePayment.jsx";

export function MachinePayment() {
  const { currentPayment, setCurrentPayment } =
    useCurrentMultiplePendingPayment();

  const { setMultiplePaymentsStep } = usePaymentFlowStore();

  const handleCancelPayment = () => {
    setCurrentPayment({ status: "EDITING", id: uuid() });
    setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value);
  };

  if (!currentPayment) {
    return null;
  }

  return (
    <MachinePaymentPage
      paymentId={currentPayment.id}
      paymentMethod={currentPayment.paymentMethod}
      value={currentPayment.value}
      onCancel={handleCancelPayment}
    />
  );
}
