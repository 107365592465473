import styled from "styled-components";

export const StyledPaymentCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const StyledPaymentCard = styled.div`
  flex: 1;
  max-width: 300px;

  @media (max-width: 767px) {
    flex: 1 0 100%;
  }
`;
