import { Button, Spinner } from "@shopify/polaris";
import { useState } from "react";
import useHideLoadingIndicator from "../hook/useHideLoadingIndicator.js";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";

const formatter = Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export default function ReportZPage() {
  useHideLoadingIndicator();
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(new Date());

  const companyShop = useShopifyShopUrl();

  const [total, setTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalPag, setTotalPag] = useState(0);

  const getOrdersFromDay = async () => {
    try {
      setLoading(true);
      const result = await fetch(
        `${process.env.HOST}/api/relatorioz/${encodeURIComponent(
          date
        )}/${encodeURIComponent(companyShop)}`
      );

      const resultJson = await result.json();

      let _totalPag = 0;
      let _totalTax = 0;

      if (resultJson.length === 0) {
        setTotalPag(_totalPag);
        setTotalTax(_totalTax);
        setTotal(0);
        return;
      }

      for (let i = 0; i < resultJson.length; i += 1) {
        const order = resultJson[i];

        for (let i = 0; i < Object.keys(order).length; i += 1) {
          const orderId = Object.keys(order)[i];

          const _order = order[orderId];

          _totalPag += parseFloat(_order.pag ?? "0");
          _totalTax += parseFloat(_order.vICMS ?? "0");
        }
      }

      setTotalPag(_totalPag);
      setTotalTax(_totalTax);
      setTotal(_totalPag - _totalTax);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>RelatorioZ</h1>
      <div>
        <input
          type="date"
          onChange={(e) => setDate(e.target.value)}
          value={date}
          max={jsDateTimeToLocalIsoDateString(new Date())}
          disabled={loading}
        />
        <Button onClick={getOrdersFromDay} disabled={loading}>
          Aplicar
        </Button>
        <Button
          size="large"
          fullWidth
          url={`${process.env.HOST}/api/relatorioz/${encodeURIComponent(
            date
          )}/${encodeURIComponent(companyShop)}/print`}
          external={true}
          disabled={loading}
        >
          Imprimir
        </Button>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div id="printable">
          <h2>Resumo</h2>
          <div>
            <div>
              <span>Total Pag: </span>
              <span>{formatter.format(totalPag)}</span>
            </div>
            <div>
              <span>Total Tax: </span>
              <span>{formatter.format(totalTax)}</span>
            </div>

            <div>
              <span>Total Liq: </span>
              <span>{formatter.format(total)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function jsDateTimeToLocalIsoDateString(d) {
  const year = `${d.getFullYear()}`,
    month = `${d.getMonth() + 1}`.padStart(2, "0"),
    day = `${d.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
}
