import React, { useState } from "react";
import { TertiaryButton } from "./CopyClipboard.styles";

const CopyToClipboard = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <TertiaryButton onClick={handleCopyClick}>
      {copied ? "Copiado!" : "Copiar"}
    </TertiaryButton>
  );
};

export default CopyToClipboard;
