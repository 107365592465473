import { Select } from "@shopify/polaris";

import { Container } from "./styles";

export function CardFlagSelect({ value, onChange }) {
  const options = [
    { label: "Mastercard", value: "Mastercard" },
    { label: "Visa", value: "Visa" },
    { label: "Elo", value: "Elo" },
    { label: "American Express", value: "AMEX" },
    { label: "Hipercard", value: "Hipercard" },
    { label: "Outros", value: "Other" },
  ];

  return (
    <Container>
      <Select
        placeholder="Selecione a bandeira do cartão..."
        options={options}
        value={value ?? undefined}
        onChange={onChange}
      />
    </Container>
  );
}
