import styled from "styled-components";

export const RadioButtonCardContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
`;

export const RadioButtonCardOption = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-top: 8px;
`;
