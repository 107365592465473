import { TextField } from "@shopify/polaris";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Container } from "./styles";

export function AutField({ value, onChange }) {
  const { requiredConciliationFields: requiredFieldsFlag } = useFlags();

  return (
    <Container>
      <TextField
        label={requiredFieldsFlag ? "AUT (Obrigatório)" : "AUT (Opcional)"}
        requiredIndicator={requiredFieldsFlag}
        value={value ?? undefined}
        onChange={onChange}
      />
    </Container>
  );
}
