import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  addHours,
  formatISO,
  format,
} from "date-fns";
import { ptBR } from "date-fns/locale";

const today = new Date();
const adjustedToday = addHours(startOfDay(today), 3);

const startOfYesterday = addHours(startOfDay(subDays(today, 1)), 3);
const endOfYesterday = addHours(endOfDay(subDays(today, 1)), 3);

export const datesToFilter = {
  today: {
    label: "Hoje",
    placeholder: format(adjustedToday, "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR,
    }),
    query: [["referenceDate", ">=", formatISO(adjustedToday)]],
  },
  yesterday: {
    label: "Ontem",
    placeholder: format(
      addHours(startOfYesterday, 3),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: ptBR,
      },
    ),
    query: [
      ["referenceDate", ">=", formatISO(startOfYesterday)],
      ["referenceDate", "<=", formatISO(endOfYesterday)],
    ],
  },
  thisWeek: {
    label: "Esta semana",
    placeholder: `${format(
      startOfWeek(addHours(today, 3), { weekStartsOn: 1 }),
      "dd 'de' MMMM 'de' yyyy",
      { locale: ptBR },
    )} a ${format(
      endOfWeek(addHours(today, 3), { weekStartsOn: 1 }),
      "dd 'de' MMMM 'de' yyyy",
      { locale: ptBR },
    )}`,
    query: [
      [
        "referenceDate",
        ">=",
        formatISO(addHours(startOfWeek(today, { weekStartsOn: 1 }), 3)),
      ],
    ],
  },
  lastWeek: {
    label: "Semana passada",
    placeholder: `${format(
      startOfWeek(addHours(subDays(today, 7), 3), { weekStartsOn: 1 }),
      "dd 'de' MMMM 'de' yyyy",
      { locale: ptBR },
    )} a ${format(
      endOfWeek(addHours(subDays(today, 7), 3), { weekStartsOn: 1 }),
      "dd 'de' MMMM 'de' yyyy",
      { locale: ptBR },
    )}`,
    query: [
      [
        "referenceDate",
        ">=",
        formatISO(
          addHours(startOfWeek(subDays(today, 7), { weekStartsOn: 1 }), 3),
        ),
      ],
      [
        "referenceDate",
        "<=",
        formatISO(
          addHours(endOfWeek(subDays(today, 7), { weekStartsOn: 1 }), 3),
        ),
      ],
    ],
  },
  thisMonth: {
    label: "Este mês",
    placeholder: `${format(
      addHours(startOfMonth(today), 3),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: ptBR,
      },
    )} a ${format(addHours(endOfMonth(today), 3), "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR,
    })}`,
    query: [
      ["referenceDate", ">=", formatISO(addHours(startOfMonth(today), 3))],
      ["referenceDate", "<=", formatISO(addHours(endOfMonth(today), 3))],
    ],
  },
  lastMonth: {
    label: "Mês passado",
    placeholder: `${format(
      startOfMonth(addHours(subMonths(today, 1), 3)),
      "dd 'de' MMMM 'de' yyyy",
      { locale: ptBR },
    )} a ${format(
      endOfMonth(addHours(subMonths(today, 1), 3)),
      "dd 'de' MMMM 'de' yyyy",
      {
        locale: ptBR,
      },
    )}`,
    query: [
      [
        "referenceDate",
        ">=",
        formatISO(addHours(startOfMonth(subMonths(today, 1)), 3)),
      ],
      [
        "referenceDate",
        "<=",
        formatISO(addHours(endOfMonth(subMonths(today, 1)), 3)),
      ],
    ],
  },
};
