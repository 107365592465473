import styled from "styled-components";

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 8px;
  margin-top: 18px;
  margin-left: 12px;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 24px;

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin: 0;
  }
`;

export const IgluAnalyticsButtonContainer = styled.div`
  margin-top: 14px;
  margin-left: 16px;
`;
