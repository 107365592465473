import firestore from "@iglu/common/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

const STATUS = { IS_LOADING: 0, DID_SUCCEED: 1, DID_FAIL: 2 };

const cache = {
  // [collectionName]: { [documentKey]: documentValue }
};
function readFromCache(collectionName, documentKey) {
  return (cache[collectionName] ?? {})[documentKey];
}
function writeToCache(collectionName, documentKey, documentValue) {
  if (!cache[collectionName]) {
    cache[collectionName] = {};
  }
  cache[collectionName][documentKey] = documentValue;
}

export default function useFirestoreDoc(
  collectionName,
  documentKey,
  { next = () => undefined } = {}
) {
  const [data, setData] = useState(() =>
    readFromCache(collectionName, documentKey)
  );
  const [status, setStatus] = useState(() =>
    data === undefined ? STATUS.IS_LOADING : STATUS.DID_SUCCEED
  );
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, collectionName, documentKey),
      {
        error: (error) => {
          setError(error);
          if (status !== STATUS.DID_FAIL) setStatus(STATUS.DID_FAIL);
        },
        next: (snapshot) => {
          const nextData = snapshot.data() ?? null;
          setData(nextData);
          if (status !== STATUS.DID_SUCCEED) setStatus(STATUS.DID_SUCCEED);
          writeToCache(collectionName, documentKey, nextData);
          next(nextData);
        },
      }
    );
    return unsubscribe;
  }, [collectionName, documentKey, status]);

  const isLoading = status === STATUS.IS_LOADING;
  const didFail = status === STATUS.DID_FAIL;
  const didSucceed = status === STATUS.DID_SUCCEED;

  const optimisticUpdate = useCallback(
    (stateUpdateFunction) =>
      setData((previousData) => {
        const nextData = stateUpdateFunction(previousData);
        writeToCache(collectionName, documentKey, nextData);
        return nextData;
      }),
    [collectionName, documentKey]
  );

  return { isLoading, didFail, didSucceed, error, data, optimisticUpdate };
}
