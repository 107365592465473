import { Button } from "@shopify/polaris";
import { useConfigurationStore } from "../../../../../../../../../store/useConfigurationStore.js";
import { useUniquePaymentStore } from "../../../../../../../../../store/useUniquePaymentStore.js";
import { useFlags } from "launchdarkly-react-client-sdk";

export function CardsSubmit({ onClick }) {
  const {
    paymentMethod,
    paymentInstallments,
    chargedSeparately,
    cardFlag,
    automaticConciliation,
    nsu,
    aut,
  } = useUniquePaymentStore();

  const { requiredConciliationFields: requiredFieldsFlag } = useFlags();

  const { configuration } = useConfigurationStore();

  const getIsButtonDisabled = () => {
    if (chargedSeparately && automaticConciliation) {
      return false;
    }
    if (paymentMethod === "CREDIT" && paymentInstallments === null) {
      return true;
    }
    if (
      requiredFieldsFlag &&
      chargedSeparately &&
      (nsu === "" || aut === "") &&
      (paymentMethod === "CREDIT" || paymentMethod === "DEBIT")
    ) {
      return true;
    }

    return chargedSeparately && cardFlag === null;
  };

  const getButtonText = () => {
    if (chargedSeparately) {
      return "Gerar NF";
    }

    const paymentCardService = configuration?.payment?.card?.service;
    if (
      paymentCardService === "softwareexpress" &&
      ["CREDIT", "DEBIT"].includes(paymentMethod)
    ) {
      return "Enviar para pinpad";
    }

    return "Enviar para maquininha";
  };

  const isButtonDisabled = getIsButtonDisabled();
  const buttonText = getButtonText();

  return (
    <Button primary disabled={isButtonDisabled} onClick={onClick}>
      {buttonText}
    </Button>
  );
}
