import useFirestoreDoc from "../hook/useFirestoreDoc.js";
import { useConfigurationStore } from "../store/useConfigurationStore.js";
import useShopifyShopUrl from "./useShopifyShopUrl.js";

export const DEFAULT_CREDIT_INSTALLMENTS = { default: 3 };

export default function useConfiguration() {
  const shopifyShopUrl = useShopifyShopUrl();

  const { configuration, setConfiguration } = useConfigurationStore();

  useFirestoreDoc("configuration", shopifyShopUrl, { next: setConfiguration });

  return configuration;
}
