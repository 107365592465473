import React from "react";

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20ZM29.4929 16.2929C30.1959 15.59 30.1959 14.4503 29.4929 13.7473C28.79 13.0444 27.6503 13.0444 26.9473 13.7473L17.7201 22.9745L14.1929 19.4473C13.49 18.7444 12.3503 18.7444 11.6473 19.4473C10.9444 20.1503 10.9444 21.29 11.6473 21.9929L16.4473 26.7929C17.1503 27.4959 18.29 27.4959 18.9929 26.7929L29.4929 16.2929Z"
      fill="#202222"
    />
  </svg>
);

export default CheckIcon;
