import { TextField } from "@shopify/polaris";
import {
  Container,
  LabelContainer,
  Label,
  DescriptionLabel,
  ErrorField,
} from "./MoneyField.styles";

const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function MoneyField({
  value,
  error,
  label,
  labelDescription,
  errorText,
  onChange,
}) {
  const formatValue = (value) => {
    return moneyFormatter.format(value / 100);
  };

  const handleChange = (newValue) => {
    const money = newValue.replace(/\D/g, "");
    if (!money) {
      return onChange(0);
    }

    onChange(Number(money));
  };

  return (
    <Container>
      <LabelContainer>
        <Label htmlFor="money-field" error={error}>
          {label}
        </Label>
        <DescriptionLabel htmlFor="money-field" error={error}>
          {labelDescription}
        </DescriptionLabel>
      </LabelContainer>

      <TextField
        value={formatValue(value)}
        onChange={handleChange}
        error={error}
        id="money-field"
        inputMode="numeric"
      />

      {error && errorText && <ErrorField>{errorText}</ErrorField>}
    </Container>
  );
}
