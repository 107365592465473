export default function LoadingSkeleton({ ...style }) {
  return (
    <div
      style={{
        ...style,
        width: style.width ?? "100%",
        height: style.height ?? "100%",
        borderRadius: style.borderRadius ?? "3px",
        backgroundColor: "rgb(228, 229, 231)",
      }}
    ></div>
  );
}
