import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { Pos } from "@shopify/app-bridge/actions";
import { CircleTickMajor } from "@shopify/polaris-icons";

import Feedback from "./Feedback.jsx";

export const OrderCanceledFeedback = () => {
  const shopifyPos = Pos.create(useShopifyAppBridge());
  const closeApp = () => shopifyPos.dispatch(Pos.Action.CLOSE);

  return (
    <Feedback
      PolarisIcon={CircleTickMajor}
      primaryText="Pedido cancelado com sucesso"
      primaryAction={{ text: "Fechar", onTap: closeApp }}
    />
  );
};
