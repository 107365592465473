import styled from "styled-components";

export const CardInformationContainer = styled.div`
  width: 171px;
  height: 107px;
  top: 40px;
  left: 40px;
  padding: 16px;
  border-radius: 16px;
  background-color: #c1ddf5;
`;

export const CardInformationButtonDescription = styled.p`
  font-size: 16px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CardInformationGrid = styled.div`
  display: flex;
`;

export const CardInformationText = styled.p`
  font-size: 18px;
  font-weight: bold;
  justify-content: flex-start;
  color: #197adc;
`;

export const CardChevron = styled.div``;
