import { useMultiplePaymentStore } from "../../../../../../store/useMultiplePaymentsStore";
import { GenerateNfceButton } from "./components/GenerateNfceButton/GenerateNfceButton";
import { PaidPayment } from "./components/Payment/PaidPayment";
import { Payment } from "./components/Payment/Payment";
import { Container } from "./PaymentList.styles";

export function PaymentList() {
  const { payments } = useMultiplePaymentStore();

  const hasPending = payments.some((payment) => payment.status === "PENDING");

  return (
    <Container>
      {payments.map((payment, index) => {
        if (payment.status === "PAID" || payment.status === "PENDING") {
          return (
            <PaidPayment
              disabled={hasPending}
              key={payment.id}
              payment={payment}
              paymentNumber={index + 1}
            />
          );
        }

        return <Payment key={payment.id} paymentNumber={index + 1} />;
      })}
      <GenerateNfceButton />
    </Container>
  );
}
