import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import { Share } from "@shopify/app-bridge/actions";
import React, { useCallback } from "react";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import useLink from "../../hook/useLink.js";
import { PaymentLinkProgressStatus } from "./components/PaymentLinkProgressStatus/PaymentLinkProgressStatus";

const _isShopifyPos = isShopifyPos();

let useAppBridge = null;
if (_isShopifyPos) {
  useAppBridge = useShopifyAppBridge;
} else {
  useAppBridge = () => null;
}

export default function LinkPage() {
  const appBridge = useAppBridge();
  useHideLoadingIndicator();
  const { data: checkoutLink } = useLink();

  const copyLinkToClipboard = useCallback(() => {
    if (checkoutLink) {
      navigator.clipboard
        .writeText(checkoutLink)
        .then(() => {
          alert("O link foi copiado com sucesso!");
        })
        .catch((error) => {
          console.error("Erro ao copiar o link:", error);
          alert(
            "Ocorreu um erro ao copiar o link. Por favor, tente novamente.",
          );
        });
    }
  }, [checkoutLink]);

  const handleShare = useCallback(() => {
    if (appBridge) {
      Share.create(appBridge).dispatch(Share.Action.SHOW, {
        text: checkoutLink,
      });
    } else if (navigator.share) {
      navigator.share({ url: checkoutLink });
    } else {
      console.error("Couldn't share");
    }
  }, [appBridge, checkoutLink]);

  return (
    <div>
      <PaymentLinkProgressStatus
        handleCopy={copyLinkToClipboard}
        handleShare={handleShare}
      />
    </div>
  );
}
