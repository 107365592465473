import { create } from "zustand";

export const ANALYTICS_PAYMENT_TABS = {
  PAYMENTS_SUMMARY: {
    value: 1,
  },
  PAYMENTS_SUMMARY_CASH: {
    value: 2,
  },
  PAYMENTS_SUMMARY_CREDIT: {
    value: 3,
  },
  PAYMENTS_SUMMARY_DEBIT: {
    value: 4,
  },
  PAYMENTS_SUMMARY_PIX: {
    value: 5,
  },
  PAYMENTS_SUMMARY_STORE_CREDIT: {
    value: 6,
  },
  PAYMENTS_SUMMARY_LINK: {
    value: 7,
  },
  PAYMENTS_SUMMARY_OTHERS: {
    value: 8,
  },
  PAYMENTS_SUMMARY_TOTAL: {
    value: 9,
  },
};

export const usePaymentsSummaryTabsStore = create((set) => ({
  paymentsSummary: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value,
  paymentsSummaryCash: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CASH.value,
  paymentsSummaryCredit: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CREDIT.value,
  paymentsSummaryDebit: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_DEBIT.value,
  paymentsSummaryPix: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_PIX.value,
  paymentsSummaryStoreCredit:
    ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_STORE_CREDIT.value,
  paymentsSummaryLink: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_LINK.value,
  paymentsSummaryOthers: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_OTHERS.value,
  paymentsSummaryTotal: ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_TOTAL.value,

  setPaymentsSummary(paymentsSummary) {
    set({ paymentsSummary });
  },
}));
