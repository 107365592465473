import { Form, FormLayout, Modal, Stack, TextField } from "@shopify/polaris";
import { useState } from "react";
import { isShopifyPOS } from "@shopify/app-bridge-utils";
export default function SendEmailModal({
  isOpen,
  onClose,
  onSubmit,
  initialCustomerEmail,
}) {
  const [customerEmail, setCustomerEmail] = useState(initialCustomerEmail);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      fullScreen={isShopifyPOS()}
      title="Enviar e-mail"
      primaryAction={{
        content: "Enviar e-mail",
        onAction: () => onSubmit({ customerEmail }),
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Form onSubmit={() => onSubmit({ customerEmail })}>
            <FormLayout>
              <TextField
                type="email"
                value={customerEmail}
                onChange={setCustomerEmail}
                autoComplete="off"
              />
            </FormLayout>
          </Form>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
