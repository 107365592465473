import firestore from "@iglu/common/firestore";
import { Page, Layout, Button } from "@shopify/polaris";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame";
import ThreeDots from "../../component/skeletonLoading/ThreeDots";
import { TabPanel } from "../../component/Tabs/TabPanel";
import useDevice from "../../hook/useDevice";
import useFirestoreQuery from "../../hook/useFirestoreQuery";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import {
  ANALYTICS_TABS,
  useAnalyticsTabsStore,
} from "../../store/useAnalyticsTabsStore";
import { useIgluAnalyticsStore } from "../../store/useIgluAnalyticsStore.js";
import { CardDetailsGroup } from "./components/CardDetailsGroup/CardDetailsGroup.jsx";
import { datesToFilter } from "./components/datesToFilter/datesToFilter.jsx";
import { FilterDate } from "./components/FilterDate/FilterDate.jsx";
import { FilterStaff } from "./components/FilterStaff/FilterStaff.jsx";
import { SalesSummary } from "./components/SalesSummary/SalesSummary.jsx";
import { IgluAnalyticsButtonContainer, Title } from "./IgluAnalytics.styles.js";

export function IgluAnalytics() {
  useHideLoadingIndicator();

  const { dashboardTab, setDashboardTab } = useAnalyticsTabsStore();
  const device = useDevice();
  const companyShop = useShopifyShopUrl();
  const [storeName, setStoreName] = useState(null);
  const { staffFilter, dateToFilter, setFiscalInvoice } =
    useIgluAnalyticsStore();

  const staffId = device?.pos?.user?.id ? `${device.pos.user.id}` : null;

  const posLocationId = device?.pos?.location?.id
    ? `${device.pos.location.id}`
    : null;

  useEffect(async () => {
    const posLocation = (
      await getDoc(
        doc(firestore, "posLocation", `${companyShop}:${posLocationId}`),
      )
    ).data();
    setStoreName(posLocation?.name);
  }, [companyShop, posLocationId]);

  const [filter, setFilter] = useState([
    ["companyShop", "==", companyShop],
    ["type", "in", ["sale", "return"]],
    ["status", "==", "authorized"],
    ["locationId", "==", posLocationId],
    ...datesToFilter[dateToFilter].query,
  ]);

  const fiscalInvoices = useFirestoreQuery("fiscalInvoice", ...filter);

  useEffect(() => {
    setFiscalInvoice(fiscalInvoices?.data || []);
  }, [fiscalInvoices?.data]);

  useEffect(() => {
    {
      staffFilter === "mineSales" && staffId
        ? setFilter([
            ["companyShop", "==", companyShop],
            ["type", "in", ["sale", "return"]],
            ["status", "==", "authorized"],
            ["locationId", "==", posLocationId],
            ["staffId", "==", staffId],
            ...datesToFilter[dateToFilter].query,
          ])
        : setFilter([
            ["companyShop", "==", companyShop],
            ["type", "in", ["sale", "return"]],
            ["status", "==", "authorized"],
            ["locationId", "==", posLocationId],
            ...datesToFilter[dateToFilter].query,
          ]);
    }
  }, [staffFilter, dateToFilter, posLocationId, staffId]);

  if (!posLocationId || !staffId || fiscalInvoices.isLoading) {
    return (
      <Page>
        <LoadingFrame />
      </Page>
    );
  }
  const goToDashboardTab = () => {
    setDashboardTab(ANALYTICS_TABS.DASHBOARD.value);
  };

  const showButton = () => {
    if (dashboardTab === ANALYTICS_TABS.DASHBOARD.value) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Page>
        {showButton() && (
          <IgluAnalyticsButtonContainer>
            <Button plain large onClick={goToDashboardTab}>
              Voltar
            </Button>
          </IgluAnalyticsButtonContainer>
        )}

        <Layout>
          <Layout.Section>
            <Title>{storeName}</Title>

            <FilterStaff />
            <FilterDate />

            {fiscalInvoices.isLoading ? (
              <ThreeDots />
            ) : (
              <>
                <TabPanel
                  value={dashboardTab}
                  index={ANALYTICS_TABS.DASHBOARD.value}
                >
                  <CardDetailsGroup />
                </TabPanel>
                <TabPanel
                  value={dashboardTab}
                  index={ANALYTICS_TABS.SUMMARY_SALE_STEP.value}
                >
                  <SalesSummary />
                </TabPanel>
              </>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}
