import { Button } from "@shopify/polaris";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useConfigurationStore } from "../../../../../../../../store/useConfigurationStore";
import { useLocationStore } from "../../../../../../../../store/useLocationStore";
import { useCurrentMultiplePayment } from "../../../../../../../../store/useMultiplePaymentsStore";
import { useOrderStore } from "../../../../../../../../store/useOrderStore";
import {
  MULTIPLE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../../store/usePaymentFlowStore";

export function CardsSubmit({ disabled, onClick }) {
  const { requiredConciliationFields: requiredFieldsFlag } = useFlags();

  const { currentPayment } = useCurrentMultiplePayment();

  const { setMultiplePaymentsStep } = usePaymentFlowStore();

  const { configuration } = useConfigurationStore();

  const { location } = useLocationStore();

  const { shopifyOrderId, companyShop, staffId, posDeviceId } = useOrderStore();

  const getButtonText = () => {
    if (currentPayment.chargedSeparately) {
      return "Continuar";
    }

    const paymentCardService = configuration?.payment?.card?.service;
    if (
      paymentCardService === "softwareexpress" &&
      ["CREDIT", "DEBIT"].includes(currentPayment.paymentMethod)
    ) {
      return "Enviar para pinpad";
    }

    return "Enviar para maquininha";
  };

  const getIsButtonDisabled = () => {
    if (disabled) {
      return disabled;
    }

    if (
      currentPayment.chargedSeparately &&
      currentPayment.automaticConciliation
    ) {
      return false;
    }

    if (
      currentPayment.paymentMethod === "CREDIT" &&
      currentPayment.paymentInstallments === null
    ) {
      return true;
    }

    if (requiredFieldsFlag) {
      if (
        (currentPayment.paymentMethod === "CREDIT" &&
          currentPayment.paymentInstallments === null) ||
        (currentPayment.chargedSeparately &&
          (currentPayment.nsu === null || currentPayment.nsu === "")) ||
        (currentPayment.chargedSeparately &&
          (currentPayment.aut === null || currentPayment.aut === ""))
      ) {
        return true;
      }
    }
    return currentPayment.chargedSeparately && currentPayment.cardFlag === null;
  };

  const isButtonDisabled = getIsButtonDisabled();

  const buttonText = getButtonText();

  const handleClick = () => {
    onClick();

    if (!currentPayment.chargedSeparately) {
      setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.CARD.value);
    }

    if (currentPayment.chargedSeparately) {
      return;
    }

    const paymentCardService =
      location?.payment?.card?.service ?? configuration?.payment?.card?.service;

    // const printerService =
    //   location?.printer?.service ?? configuration?.printer?.service;

    /* TEF */
    if (paymentCardService === "softwareexpress") {
      // Using "location" here as a synonym for "physical store".
      const softwareexpressClientId =
        location?.payment?.card?.softwareexpress?.clientId ?? "";
      const locationCnpj = location?.cnpj ?? "";
      const cents = String(currentPayment.value);
      const method = currentPayment.paymentMethod.toLowerCase();
      const installments = currentPayment.paymentInstallments;

      window.open(
        `iglu://receive-tef-payment/?${new URLSearchParams({
          // backend-origin
          borg:
            process.env.NODE_ENV === "development"
              ? window.location.origin
              : window.location.origin.replace(/app-/, ""),
          pkey: `${companyShop}:${shopifyOrderId}:${currentPayment.id}`, // payment-document-key
          // ^ It doesn't feel safe to do this...
          clientId: softwareexpressClientId,
          clientCnpj: locationCnpj,
          staffId,
          posDeviceId,
          cents,
          method,
          ...(method === "credit" ? { installments } : {}),
          pwip: location?.printer?.wifi?.ip,
        }).toString()}`,
        "_blank",
      );
    }
    /* TEF */
  };

  return (
    <Button primary disabled={isButtonDisabled} onClick={handleClick}>
      {buttonText}
    </Button>
  );
}
