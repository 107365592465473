import { usePixAnalyticsStore } from "../../store/usePixAnalyticsStore";
import {
  CalendarContainer,
  FilterContainer,
  FilterLabel,
} from "./PaymentFilter.styles";

const PaymentFilter = () => {
  const { selectedDate, setSelectedDate } = usePixAnalyticsStore();

  const handleDateChange = (e) => {
    const dateString = e.target.value;
    const localSelectedDate = new Date(dateString + "T03:00:00");
    setSelectedDate(localSelectedDate);
  };

  return (
    <FilterContainer>
      <FilterLabel>Selecione um dia:</FilterLabel>
      <CalendarContainer>
        <input
          type="date"
          value={selectedDate ? selectedDate.toISOString().slice(0, 10) : ""}
          onChange={handleDateChange}
        />
      </CalendarContainer>
    </FilterContainer>
  );
};

export default PaymentFilter;
