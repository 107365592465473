import { useCallback, useEffect, useState } from "react";
import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import { Form, FormLayout, Stack, Modal, TextField } from "@shopify/polaris";
import { format as formatCnpj } from "../../helper/cnpj.js";

export default function CreatePspDeviceModal({
  posLocationCNPJ,
  isOpen,
  onClose,
  onSubmit,
}) {
  const [newPspDeviceSerialNumber, setNewPspDeviceSerialNumber] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setNewPspDeviceSerialNumber("");
    }
  }, [isOpen]);

  const handleNewPspDeviceSerialNumberChange = useCallback(
    (nextNewPspDeviceSerialNumber) =>
      setNewPspDeviceSerialNumber(nextNewPspDeviceSerialNumber.toUpperCase()),
    []
  );

  const _onSubmit = useCallback(
    () => onSubmit({ newPspDeviceSerialNumber }),
    [onSubmit, newPspDeviceSerialNumber]
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      fullScreen={isShopifyPos()}
      title="Adicionar maquininha"
      secondaryActions={[
        {
          content: "Fechar",
          onAction: onClose,
        },
      ]}
      primaryAction={{
        content: "Adicionar",
        disabled: newPspDeviceSerialNumber === "",
        onAction: _onSubmit,
      }}
    >
      <Modal.Section>
        <Stack vertical>
          <Form onSubmit={_onSubmit}>
            <FormLayout>
              <TextField
                label="CNPJ"
                autoComplete="off"
                value={
                  typeof posLocationCNPJ === "string"
                    ? formatCnpj(posLocationCNPJ)
                    : "Sem CNPJ configurado"
                }
                disabled
              />
              <TextField
                label="Número de Série"
                autoComplete="off"
                value={newPspDeviceSerialNumber}
                onChange={handleNewPspDeviceSerialNumberChange}
              />
            </FormLayout>
          </Form>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
