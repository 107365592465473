import { RadioButton } from "@shopify/polaris";
import {
  RadioButtonCardContainer,
  RadioButtonCardOption,
} from "./RadioButtonCard.styles";

export function RadioButtonCard({
  label,
  helpText,
  onClick,
  checked,
  onChange,
  value,
}) {
  return (
    <div>
      <RadioButtonCardContainer>
        <RadioButtonCardOption>
          <RadioButton
            label={label}
            helpText={helpText}
            onClick={onClick}
            checked={checked}
            onChange={onChange}
            name={value}
          />
        </RadioButtonCardOption>
      </RadioButtonCardContainer>
    </div>
  );
}
