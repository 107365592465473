import { TabPanel } from "../../../../component/Tabs/TabPanel";
import {
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../store/usePaymentFlowStore";
import { ChooseStep } from "./ChooseStep/ChooseStep";
import { MultiplePayments } from "./MultiplePayments/MultiplePayments";
import { Container } from "./styles";
import { UniquePayment } from "./UniquePayment/UniquePayment";

export function PaymentPanel() {
  const { paymentStep, setPaymentStep } = usePaymentFlowStore();

  const goToThisStep = () => setPaymentStep(PAYMENT_STEPS.CHOOSE_STEP.value);

  return (
    <Container>
      <TabPanel value={paymentStep} index={PAYMENT_STEPS.CHOOSE_STEP.value}>
        <ChooseStep />
      </TabPanel>

      <TabPanel value={paymentStep} index={PAYMENT_STEPS.UNIQUE_PAYMENT.value}>
        <UniquePayment previousStep={goToThisStep} />
      </TabPanel>

      <TabPanel
        value={paymentStep}
        index={PAYMENT_STEPS.MULTIPLE_PAYMENTS.value}
      >
        <MultiplePayments previousStep={goToThisStep} />
      </TabPanel>
    </Container>
  );
}
