import { Card, Page, EmptyState } from "@shopify/polaris";
import "./UnexpectedErrorPage.css";
import { useEffect } from "react";
import mountain from "../../asset/mountain.svg";
import handleError from "../../component/App/handleError.js";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator.js";

export default function UnexpectedErrorPage({ error }) {
  useHideLoadingIndicator();

  useEffect(() => {
    handleError(error);
  }, []);

  return (
    <Page>
      <Card>
        <Card.Section>
          <EmptyState
            heading="Ops, ocorreu um erro inesperado... "
            image={mountain}
            imageContained
          >
            <p> Estamos tentando resolver, tente novamente mais tarde.</p>
          </EmptyState>
        </Card.Section>
      </Card>
    </Page>
  );
}
