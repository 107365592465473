import { Button } from "@shopify/polaris";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isValidCPF } from "../../../../../../helper/cpf";
import { useOrderStore } from "../../../../../../store/useOrderStore";
import {
  PAYMENT_STEPS,
  MULTIPLE_PAYMENT_STEPS,
  NFCE_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore";

export function ContinueButton() {
  const {
    shouldAvoidPaymentTypeStep,
    setNfceStep,
    setPaymentStep,
    setMultiplePaymentsStep,
  } = usePaymentFlowStore();

  const { cpf } = useOrderStore();
  const { disableMultiplePayments } = useFlags();

  const goToPaymentTab = () => {
    if (shouldAvoidPaymentTypeStep && !disableMultiplePayments) {
      setNfceStep(NFCE_STEPS.PAYMENT.value);
      setPaymentStep(PAYMENT_STEPS.MULTIPLE_PAYMENTS.value);
      setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value);
      return;
    }
    setNfceStep(NFCE_STEPS.PAYMENT.value);
    return;
  };

  const getIsDisabled = () => {
    if (!cpf) {
      return false;
    }

    if (isValidCPF(cpf)) {
      return false;
    }

    return true;
  };

  const disabled = getIsDisabled();

  return (
    <Button onClick={goToPaymentTab} disabled={disabled} primary>
      Continuar
    </Button>
  );
}
