import React, { useEffect, useState } from "react";

import QRCodeModal from "../../../../../../component/QRCodeModal/QRCodeModal.jsx";
import R$ from "../../../../../../component/FormatCurrency/R$.jsx";
import QRCodeSkeleton from "../../../../../../component/skeletonLoading/QrCodeSkeleton.jsx";
import useFirestoreDoc from "../../../../../../hook/useFirestoreDoc.js";
import backend from "../../../../../../service/backend.js";
import { useOrderStore } from "../../../../../../store/useOrderStore.js";
import CancelPaymentButton from "../../components/CancelPaymentButton/CancelPaymentButton.jsx";
import {
  Price,
  PriceValue,
} from "../CancelPaymentButton/CancelPaymentButton.styles.js";
import {
  Title,
  Subtitle,
  Container,
  QrCodeContainer,
} from "./PixPayment.styles";

export function PixPayment({
  paymentId,
  paymentMethod,
  value,
  onCancel,
  onPaid,
}) {
  const [qrCodeModalIsOpen, setQRCodeModalIsOpen] = useState(true);
  const [cancelClicked, setCancelClicked] = useState(false);

  const { shopifyOrderId, companyShop, posLocationId } = useOrderStore();

  const paymentSnapShot = useFirestoreDoc(
    "payment",
    `${companyShop}:${shopifyOrderId}:${paymentId}`,
  );

  useEffect(() => {
    if (paymentSnapShot?.data?.status === "settled") {
      onPaid?.();
      return;
    }
    if (paymentSnapShot?.data?.status === "canceled") {
      onCancel();
    }
  }, [paymentSnapShot?.data?.status]);

  const handleCancelPayment = () => {
    setCancelClicked(true);

    return backend.executeProcess(
      {
        type: "cancelPayment",
        input: {
          companyShop,
          shopifyOrderId,
          posLocationId,
          paymentMethod: paymentMethod.toLowerCase(),
          paymentId: paymentId,
          pagarmeOrderId: null,
          mercadopagoPaymentIntentIds: null,
          tecnospeedPixPaymentId: null,
        },
      },
      { unique: true },
    );
  };

  const getIsWaitingPayment = () => {
    return paymentSnapShot?.data?.status === "pending";
  };

  const getIsPaymentInProgress = () => {
    switch (paymentSnapShot?.data?.status) {
      case "settled":
      case "pending":
      case "canceled":
      case "error":
        return false;
      default:
        return true;
    }
  };

  const getIsPaymentInError = () => {
    switch (paymentSnapShot?.data?.status) {
      case "error":
        return true;
      case "settled":
      case "pending":
      case "canceled":
      default:
        return false;
    }
  };

  const isPaymentInProgress = getIsPaymentInProgress();
  const isWaitingPayment = getIsWaitingPayment();
  const isPaymentInError = getIsPaymentInError();

  return (
    <Container>
      <Title>Receber pix</Title>
      {isPaymentInError && (
        <Subtitle>
          Houve um erro ao gerar o PIX. Cobre avulso pela maquininha.
        </Subtitle>
      )}

      {isPaymentInProgress && (
        <QrCodeContainer>
          <Subtitle>Gerando QR Code...</Subtitle>

          <QRCodeSkeleton />

          <Price>Valor</Price>
          <PriceValue>
            <R$>{value / 100}</R$>
          </PriceValue>
        </QrCodeContainer>
      )}

      {isWaitingPayment && (
        <>
          <QrCodeContainer>
            <QRCodeModal
              isOpen={qrCodeModalIsOpen}
              onClose={() => setQRCodeModalIsOpen(false)}
              qrCode={paymentSnapShot?.data?.qrCode}
              emvCode={paymentSnapShot?.data?.emvCode}
            />
          </QrCodeContainer>

          <CancelPaymentButton
            value={value}
            onCancel={handleCancelPayment}
            disabled={cancelClicked}
            loading={cancelClicked}
            modalPrompt="Após confirmar, o pagamento será cancelado."
          />
        </>
      )}
    </Container>
  );
}
