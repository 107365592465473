import styled from "styled-components";

const fontFamily =
  "'San Francisco', 'Helvetica Neue', Helvetica, Arial, sans-serif";

export const CardInformationContainer = styled.div`
  width: 171px;
  height: 70px;
  padding: 14px;
  border-radius: 4px;
  background-color: #d8f7ff;
`;

export const CardInformationButtonDescription = styled.p`
  font-size: 15px;
  font-family: ${fontFamily};
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  color: #4b818f;
  width: 100%;
`;

export const CardInformationGrid = styled.div`
  display: flex;
`;

export const CardInformationText = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-family: ${fontFamily};
  justify-content: flex-start;
  color: #284b54;
`;
