import { Button } from "@shopify/polaris";

import { useFlags } from "launchdarkly-react-client-sdk";
import {
  NFCE_STEPS,
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../../store/usePaymentFlowStore";

export function BackButton({ disabled }) {
  const { disableMultiplePayments } = useFlags();

  const { setPaymentStep, shouldAvoidPaymentTypeStep, setNfceStep } =
    usePaymentFlowStore();

  const handleBack = () => {
    if (shouldAvoidPaymentTypeStep && !disableMultiplePayments) {
      setNfceStep(NFCE_STEPS.INFORMATION.value);
      return;
    }

    setPaymentStep(PAYMENT_STEPS.CHOOSE_STEP.value);
    return;
  };

  return (
    <Button disabled={disabled} onClick={handleBack}>
      Voltar
    </Button>
  );
}
