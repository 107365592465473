import styled from "styled-components";

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardHeaderOrder = styled.h1`
  color: #347c84;
  font-size: 16px;
  font-weight: bold;
`;

export const CardHeaderPrice = styled.p`
  color: #347c84;
  font-size: 16px;
  font-weight: bold;
`;
