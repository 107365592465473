import { v4 as uuid } from "uuid";
import { create } from "zustand";

import { useOrderStore } from "./useOrderStore";
import { usePaymentFlowStore } from "./usePaymentFlowStore";

const DEFAULT_PAYMENT = {
  id: uuid(),
  value: 0,
  status: "EDITING",
  paymentMethod: null,
  paymentInstallments: "1",
  linkPaymentService: null,
  chargedSeparately: false,
  cardFlag: null,
  pixService: null,
  nsu: "",
  aut: "",
  automaticConciliation: null,
};

export const useMultiplePaymentStore = create((set, get) => ({
  installmentsConfig: null,

  payments: [],

  setInstallmentsConfig(installmentsConfig) {
    set({ installmentsConfig });
  },

  updatePayment(paymentId, newPayment) {
    set((store) => {
      const newPayments = store.payments.map((payment) => {
        if (payment.id === paymentId) {
          return { ...payment, ...newPayment };
        }

        return payment;
      });

      return { payments: newPayments };
    });
  },

  setCurrentPayment(newPayment) {
    set((store) => {
      const currentPayment = store.payments.find(
        (payment) => payment.status === "EDITING",
      );

      const newPayments = store.payments.map((payment) => {
        if (payment.id === currentPayment.id) {
          return { ...payment, ...newPayment };
        }

        return payment;
      });

      return { payments: newPayments };
    });
  },

  setPayments(newPayments) {
    const { orderTotalInCents } = useOrderStore.getState();

    const totalPaymentsAmount = newPayments.reduce((total, payment) => {
      return total + payment.value;
    }, 0);

    usePaymentFlowStore.setState({
      shouldAvoidPaymentTypeStep: newPayments.some(
        (payment) => payment.status === "PAID",
      ),
    });

    if (orderTotalInCents !== totalPaymentsAmount) {
      const currentPayment = get().payments.find(
        (payment) => payment.status === "EDITING",
      );

      const restTotal = orderTotalInCents - totalPaymentsAmount;

      const newPayment = {
        ...DEFAULT_PAYMENT,
        id: uuid(),
        value: newPayments.length === 0 ? 0 : restTotal,
      };

      return set({
        payments: [...newPayments, currentPayment ?? newPayment],
      });
    }

    set({ payments: newPayments });
  },

  setPaymentStatus(paymentId, status) {
    set((store) => {
      const newPayments = store.payments.map((payment) => {
        if (payment.id === paymentId) {
          return { ...payment, status };
        }

        return payment;
      });

      return { payments: newPayments };
    });
  },

  addDefaultPayment() {
    const { orderTotalInCents } = useOrderStore.getState();

    const totalPaymentsAmount = get().payments.reduce((total, payment) => {
      return total + payment.value;
    }, 0);

    const newPayment = {
      ...DEFAULT_PAYMENT,
      id: uuid(),
      value: orderTotalInCents - totalPaymentsAmount,
    };

    usePaymentFlowStore.setState({
      shouldAvoidPaymentTypeStep: get().payments.some((payment) =>
        ["PAID", "PENDING"].includes(payment.status),
      ),
    });

    set((state) => ({
      payments: [...state.payments, newPayment],
    }));
  },
}));

export const useCurrentMultiplePayment = () => {
  const { payments, setCurrentPayment } = useMultiplePaymentStore();

  const getCurrentPayment = () => {
    const currentPayment = payments.find(
      (payment) => payment.status === "EDITING",
    );

    return currentPayment;
  };

  const currentPayment = getCurrentPayment();

  return { currentPayment, setCurrentPayment };
};

export const useCurrentMultiplePendingPayment = () => {
  const { payments, updatePayment } = useMultiplePaymentStore();

  const getCurrentPayment = () => {
    const currentPayment = payments.find(
      (payment) => payment.status === "PENDING",
    );

    return currentPayment;
  };

  const setCurrentPayment = (paymentData) => {
    updatePayment(currentPayment.id, paymentData);
  };

  const currentPayment = getCurrentPayment();

  return { currentPayment, setCurrentPayment };
};

export function useIsReadyToGenerateNfce() {
  const { payments } = useMultiplePaymentStore();
  const { orderTotalInCents } = useOrderStore();

  const getTotalPaymentsNotPaidAmount = () => {
    const total = payments.reduce((total, payment) => {
      if (payment.status !== "PAID") {
        return total;
      }

      return payment.value + total;
    }, 0);

    return total;
  };

  const getTotalPaymentsAmount = () => {
    return payments.reduce((total, payment) => {
      if (payment.status === "EDITING") {
        return total;
      }

      return payment.value + total;
    }, 0);
  };

  const getHasPendingPayment = () => {
    return payments.some((payment) => payment.status === "PENDING");
  };

  const totalPaymentsAmount = getTotalPaymentsNotPaidAmount();

  return {
    isReady: totalPaymentsAmount === orderTotalInCents,
    isAlmostReady:
      getTotalPaymentsAmount() === orderTotalInCents && getHasPendingPayment(),
  };
}

export function getIsReadyToGenerateNfce() {
  const getTotalPaymentsAmount = () => {
    const total = useMultiplePaymentStore
      .getState()
      .payments.reduce((total, payment) => {
        return payment.value + total;
      }, 0);

    return total;
  };

  const totalPaymentsAmount = getTotalPaymentsAmount();

  return totalPaymentsAmount === useOrderStore.getState().orderTotalInCents;
}

export function useMultiplePaymentsUtils() {
  const { payments } = useMultiplePaymentStore();
  const { orderTotalInCents } = useOrderStore();

  const calculateRemainAmount = () => {
    const paymentsTotalAmount = calculatePaymentsTotalAmount();

    const remainAmount = orderTotalInCents - paymentsTotalAmount;

    return remainAmount;
  };

  const calculatePaymentsTotalAmount = () => {
    const totalPaymentsAmount = payments.reduce((total, payment) => {
      return total + payment.value;
    }, 0);

    return totalPaymentsAmount;
  };

  const remainAmount = calculateRemainAmount();

  return { remainAmount };
}
