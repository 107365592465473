import { Button } from "@shopify/polaris";
import { ExternalMinor, LinkMinor } from "@shopify/polaris-icons";
import React, { useCallback } from "react";
import AlertIcon from "../../../../../../asset/Alert";
import CheckIcon from "../../../../../../asset/check";
import ClockIcon from "../../../../../../asset/clock";
import useFirestoreQuery from "../../../../../../hook/useFirestoreQuery";
import useShopifyOrderId from "../../../../../../hook/useShopifyOrderId";
import useShopifyShopUrl from "../../../../../../hook/useShopifyShopUrl";
import backend from "../../../../../../service/backend";
import { useUniquePaymentStore } from "../../../../../../store/useUniquePaymentStore";
import { SubmitButtonUniquePayment } from "../../UniquePayment/components/PaymentForm/components/SubmitButton/SubmitButtonUniquePayment";
import {
  LoadingIcon,
  LoadingStatusContainer,
  PaymentStatus,
  PaymentStatusDone,
  ProgressStatusContainer,
  SubtitlePayment,
  WaitingPaymentContainer,
} from "./PaymentLink.styles";

export function PaymentLink({ handleCopy, handleShare }) {
  const shopifyOrderId = useShopifyOrderId();
  const companyShop = useShopifyShopUrl();
  const { status } = useUniquePaymentStore();
  let statusComponent;

  const allPayments = useFirestoreQuery(
    "payment",
    ["companyShop", "==", companyShop],
    ["shopifyOrderId", "==", shopifyOrderId],
  );

  const addPaymentAttributes = useCallback(
    async (payments) => {
      await backend.executeProcess({
        type: "hook/addPaymentInformationOnShopifyNoteAttribute",
        input: {
          companyShop,
          shopifyOrderId,
          payments,
        },
      });
    },
    [companyShop, shopifyOrderId],
  );

  switch (status) {
    case "creating":
      statusComponent = (
        <LoadingStatusContainer>
          <LoadingIcon />
          <PaymentStatus>Gerando Link de Pagamento...</PaymentStatus>
        </LoadingStatusContainer>
      );
      break;
    case "pending":
      statusComponent = (
        <WaitingPaymentContainer>
          <ClockIcon />
          <PaymentStatus>Aguardando a conclusão do pagamento</PaymentStatus>
          <Button fullWidth icon={LinkMinor} onClick={handleCopy}>
            Copiar link
          </Button>
          <Button fullWidth icon={ExternalMinor} onClick={handleShare}>
            Compartilhar link
          </Button>
        </WaitingPaymentContainer>
      );
      break;
    case "settled":
      addPaymentAttributes(allPayments);
      statusComponent = (
        <LoadingStatusContainer>
          <CheckIcon />
          <PaymentStatusDone>Pagamento recebido!</PaymentStatusDone>
          <SubtitlePayment>
            Assim que o pedido for processado, vamos enviar a NF-e da compra
            para o e-mail do cliente.
          </SubtitlePayment>
        </LoadingStatusContainer>
      );
      break;
    case "error":
      statusComponent = (
        <LoadingStatusContainer>
          <AlertIcon />
          <PaymentStatus>Link expirado por falta de pagamento</PaymentStatus>
          <SubtitlePayment>
            Gere um novo link para enviar para o cliente.
          </SubtitlePayment>
          <SubmitButtonUniquePayment />
        </LoadingStatusContainer>
      );
      break;
  }

  return <ProgressStatusContainer>{statusComponent}</ProgressStatusContainer>;
}
