import { useState } from "react";
import ConfirmationModal from "../../../../component/modal/Confirmation";
import { RadioButtonCard } from "../../../../component/RadioButtonCard/RadioButtonCard";
import { SelectButton } from "../../../../SelectButton/SelectButton";
import { usePaymentsStore } from "../../../../store/usePaymentsStore";

const STAFF_OPTIONS = [
  {
    id: 1,
    label: "Todas as vendas da Loja",
    value: "allSales",
  },
  {
    id: 2,
    label: "Minhas vendas",
    value: "mineSales",
  },
];

export function FilterStaffOrLocation() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { staffFilter, setStaffFilter } = usePaymentsStore();
  const [selectedOption, setSelectedOption] = useState(staffFilter);

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const [selectedButtonLabel, setSelectedButtonLabel] = useState(
    STAFF_OPTIONS[0].label,
  );

  const updateSelectedButtonLabel = () => {
    setSelectedButtonLabel(
      STAFF_OPTIONS.find((option) => option.value === selectedOption)?.label,
    );
  };

  const handleConfirmation = () => {
    setStaffFilter(selectedOption);
    updateSelectedButtonLabel();
    closeConfirmationModal();
  };

  return (
    <>
      <SelectButton
        text={selectedButtonLabel}
        onClick={openConfirmationModal}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        title="Filtrar por pessoa vendedora"
        prompt={STAFF_OPTIONS.map((option) => (
          <RadioButtonCard
            key={option.id}
            label={option.label}
            value={option.value}
            onChange={() => handleOptionChange(option.value)}
            checked={option.value === selectedOption}
          />
        ))}
        confirmText="Confirmar"
        cancelText="Cancelar"
        onConfirm={handleConfirmation}
        onCancel={closeConfirmationModal}
      />
    </>
  );
}
