import { Select } from "@shopify/polaris";

import { Container } from "./InstallmentSelect.styles";

export function InstallmentSelect({ options, value, onChange, disabled }) {
  return (
    <Container>
      <Select
        placeholder="Selecione a quantidade de parcelas..."
        options={options}
        value={value ?? undefined}
        onChange={onChange}
        disabled={disabled}
      />
    </Container>
  );
}
