import { Icon } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { usePaymentsStore } from "../../store/usePaymentsStore";
import {
  BoldText,
  ButtonSelectDate,
  CalendarContainer,
  SelectButtonContainer,
  SelectButtonIconContainer,
} from "./DateRangePicker.styles";

const DateRangePicker = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [showCalendar, setShowCalendar] = useState(false);
  const maxDate = new Date();
  const { setStartDate: setStoreStartDate, setEndDate: setStoreEndDate } =
    usePaymentsStore();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const handleSelect = () => {
    const utcStartDate = new Date(startDate).toISOString();
    const utcEndDate = new Date(endDate).toISOString();
    setStoreStartDate(utcStartDate);
    setStoreEndDate(utcEndDate);
    closeCalendar();
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("pt-BR", {
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <SelectButtonContainer>
      <BoldText onClick={openCalendar}>
        {formatDate(startDate)} - {formatDate(endDate)}
      </BoldText>
      {showCalendar && (
        <CalendarContainer>
          <Calendar
            selectRange
            onChange={([start, end]) => {
              handleStartDateChange(start);
              handleEndDateChange(end);
            }}
            value={[startDate, endDate]}
            maxDate={maxDate}
          />
          <ButtonSelectDate onClick={handleSelect}>Selecionar</ButtonSelectDate>
        </CalendarContainer>
      )}
      <SelectButtonIconContainer onClick={openCalendar}>
        <Icon source={ChevronDownMinor} color="subdued" />
      </SelectButtonIconContainer>
    </SelectButtonContainer>
  );
};

export default DateRangePicker;
