export const isValidCPF = (cpf) => {
  if (cpf === "") {
    return false;
  }
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export function getCustomerCPF({ customerCPFValue, companyShop }) {
  if (
    ["cs-zerezes.myshopify.com", "zerezestest.myshopify.com"].includes(
      companyShop
    )
  ) {
    return ["", "00000000000", "00000000000000"].includes(customerCPFValue)
      ? null
      : customerCPFValue;
  }

  return customerCPFValue === "" ? null : customerCPFValue;
}

export function isCPFOptional({ companyShop }) {
  return !["cs-zerezes.myshopify.com", "zerezestest.myshopify.com"].includes(
    companyShop
  );
}

export function format(cpfDigitsString) {
  if (/^[0-9]{11}$/.test(cpfDigitsString)) {
    return `${cpfDigitsString.slice(0, 3)}.${cpfDigitsString.slice(
      3,
      6
    )}.${cpfDigitsString.slice(6, 9)}-${cpfDigitsString.slice(9, 11)}`;
  } else {
    throw new Error(`Invalid CPF digits string "${cpfDigitsString}"`);
  }
}

export function unformat(formattedCpfString) {
  if (/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/.test(formattedCpfString)) {
    return formattedCpfString.replace(/[^0-9]/g, "");
  } else {
    throw new Error(`Invalid formatted CPF string "${formattedCpfString}"`);
  }
}
