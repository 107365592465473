import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { ChargedSeparatelySwitcher as ChargedSeparatelySwitcherField } from "../../../components/ChargedSeparatelySwitcher/ChargedSeparatelySwitcher";

export function ChargedSeparatelySwitcher() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const shouldRenderThisField = () => {
    if (
      !currentPayment.paymentMethod ||
      currentPayment.paymentMethod === "CASH" ||
      currentPayment.paymentMethod === "STORE-CREDIT" ||
      currentPayment.paymentMethod === "LINK"
    ) {
      return false;
    }

    return true;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (chargedSeparately) =>
    setCurrentPayment({
      chargedSeparately,
      cardFlag: null,
      pixService: null,
      nsu: null,
      aut: null,
      automaticConciliation: null,
    });

  return (
    <ChargedSeparatelySwitcherField
      checked={currentPayment.chargedSeparately}
      onChange={handleChange}
    />
  );
}
