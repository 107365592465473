import React from "react";
import { v4 as uuid } from "uuid";

import { useCurrentMultiplePendingPayment } from "../../../../../../store/useMultiplePaymentsStore.js";
import {
  MULTIPLE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore.js";
import { PixPayment as PixPaymentPage } from "../../components/PixPayment/PixPayment.jsx";

export function PixPayment() {
  const { currentPayment, setCurrentPayment } =
    useCurrentMultiplePendingPayment();

  const { setMultiplePaymentsStep } = usePaymentFlowStore();

  const handleCancelPayment = () => {
    setCurrentPayment({ status: "EDITING", id: uuid() });
    setMultiplePaymentsStep(MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value);
  };

  return (
    <PixPaymentPage
      paymentId={currentPayment.id}
      paymentMethod={currentPayment.paymentMethod}
      value={currentPayment.value}
      onCancel={handleCancelPayment}
    />
  );
}
