import { Switcher } from "../../../../../../component/input/Switcher/Switcher";
import { Container } from "./styles";

export function ChargedSeparatelySwitcher({ checked, onChange }) {
  return (
    <Container>
      <Switcher label="Cobrei avulso" checked={checked} onChange={onChange} />
    </Container>
  );
}
