import styled from "styled-components";

export const ActionCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  color: #333;
  margin: 0;
  line-break: anywhere;
`;
