import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import { useEffect, useState } from "react";

const _isShopifyPos = isShopifyPos();

let useAppBridge = null;
if (_isShopifyPos) {
  useAppBridge = useShopifyAppBridge;
} else {
  useAppBridge = () => null;
}

const WEB_DEFAULT = {
  location: { id: "96093831447" },
};

export default function useDevice() {
  const type = _isShopifyPos ? "shopifyPos" : "shopifyWeb";
  // ^ "shopifyPos" (mobile app) or "shopifyWeb" (web app)

  const [shopifyPos, setShopifyPos] = useState(null);

  const appBridge = useAppBridge();

  useEffect(async () => {
    if (_isShopifyPos) {
      const data = await appBridge.getState("pos");
      // console.log("useDevice.js", "data", data);
      setShopifyPos(data);

      // const unsubscribe = appBridge.subscribe(async () => {
      //   console.log(
      //     "useDevice.js",
      //     "app-bridge-message",
      //     await appBridge.getState("pos")
      //   );
      // });
      // return unsubscribe;
    } else {
      if (process.env.NODE_ENV === "development") {
        setShopifyPos(WEB_DEFAULT);
      }
    }
  }, []);

  return { type, pos: shopifyPos };
}
