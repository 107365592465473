import { Page, Layout, Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import DateRangePicker from "../../component/DataRangePicker/DateRangePicker";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame";
import { TabPanel } from "../../component/Tabs/TabPanel.jsx";
import useDevice from "../../hook/useDevice.js";
import useFirestoreQuery from "../../hook/useFirestoreQuery";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import { usePaymentsStore } from "../../store/usePaymentsStore";
import {
  ANALYTICS_PAYMENT_TABS,
  usePaymentsSummaryTabsStore,
} from "../../store/usePaymentsSummaryTabsStore.js";
import { CashSummary } from "./components/CashSummary/CashSummary.jsx";
import { CreditSummary } from "./components/CreditSummary/CreditSummary.jsx";
import { DebitSummary } from "./components/DebitSummary/DebitSummary.jsx";
import { LinkSummary } from "./components/LinkSummary/LinkSummary.jsx";
import { FilterStaffOrLocation } from "./components/LocationFilter/FilterStaffOrLocation.jsx";
import { PixSummary } from "./components/PixSummary/PixSummary.jsx";
import { StoreCreditSummary } from "./components/StoreCreditSummary/StoreCreditSummary.jsx";
import { SummaryTable } from "./components/SummaryTable/SummaryTable.jsx";
import { SummaryValues } from "./components/SummaryValues/SummaryValues.jsx";
import { TotalSummary } from "./components/TotalSummary/TotalSummary.jsx";
import { PaymentsButtonContainer, Subtitle, Title } from "./payments.styles.js";

export default function Payments() {
  useHideLoadingIndicator();
  const companyShop = useShopifyShopUrl();
  const device = useDevice();
  const staffId = device?.pos?.user?.id ? `${device.pos.user.id}` : null;
  const posLocationId = device?.pos?.location?.id
    ? `${device.pos.location.id}`
    : null;
  const { paymentsSummary, setPaymentsSummary } = usePaymentsSummaryTabsStore();
  const { setPayments, startDate, endDate, staffFilter } = usePaymentsStore();

  const [query, setQuery] = useState([]);

  useEffect(() => {
    let newQuery;

    if (staffFilter === "mineSales" && staffId) {
      newQuery = [
        ["companyShop", "==", companyShop],
        ["status", "==", "settled"],
        ["createdAt", ">=", startDate],
        ["createdAt", "<=", endDate],
        ["staffId", "==", staffId],
      ];
    } else {
      newQuery = [
        ["companyShop", "==", companyShop],
        ["status", "==", "settled"],
        ["createdAt", ">=", startDate],
        ["createdAt", "<=", endDate],
        ["posLocationId", "==", posLocationId],
      ];
    }

    setQuery(newQuery);
  }, [staffFilter, startDate, endDate, posLocationId, staffId]);

  const paymentList = useFirestoreQuery("payment", ...query);

  useEffect(() => {
    setPayments(paymentList?.data || []);
  }, [paymentList.data]);

  if (!posLocationId || !staffId || paymentList.isLoading) {
    return (
      <Page>
        <LoadingFrame />
      </Page>
    );
  }

  const goBack = () => {
    setPaymentsSummary(ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value);
  };

  const showButton = () => {
    return paymentsSummary !== ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value;
  };

  const showSumary = () => {
    return paymentsSummary === ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value;
  };

  return (
    <Page>
      <PaymentsButtonContainer>
        {showButton() && (
          <Button plain large onClick={goBack}>
            Voltar
          </Button>
        )}
      </PaymentsButtonContainer>

      <Layout>
        <Layout.Section>
          <Title>Relatórios </Title>
          <Subtitle>Filtros</Subtitle>

          <FilterStaffOrLocation />
          <DateRangePicker />

          {showSumary() && <SummaryValues />}

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY.value}
          >
            <SummaryTable />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CASH.value}
          >
            <CashSummary />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_CREDIT.value}
          >
            <CreditSummary />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_DEBIT.value}
          >
            <DebitSummary />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_PIX.value}
          >
            <PixSummary />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_STORE_CREDIT.value}
          >
            <StoreCreditSummary />
          </TabPanel>

          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_LINK.value}
          >
            <LinkSummary />
          </TabPanel>
          <TabPanel
            value={paymentsSummary}
            index={ANALYTICS_PAYMENT_TABS.PAYMENTS_SUMMARY_TOTAL.value}
          >
            <TotalSummary />
          </TabPanel>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
