import { Image, Stack, Button, TextField } from "@shopify/polaris";
import { DeleteMinor, ToggleMinor } from "@shopify/polaris-icons";
import stoneSvg from "../../asset/stone.svg";
import mercadopagoSvg from "../../asset/mercadopago.svg";

export default function PspDeviceItem({
  isInsideShopifyPOS,
  psp,
  id,
  serialNumber,
  deviceRelationList,
  onDelete,
  onAssociate,
  onDissociate,
}) {
  const isAssociated =
    deviceRelationList.find((relation) => relation.pspDeviceId === id) !==
    undefined;
  return (
    <Stack
      wrap={false}
      spacing="tight"
      alignment="center"
      distribution="equalSpacing"
    >
      {isInsideShopifyPOS && (
        <div style={{ color: isAssociated ? "#038060" : "#D72D0D" }}>
          <Button
            monochrome
            outline
            icon={ToggleMinor}
            onClick={() =>
              isAssociated ? onDissociate(id) : onAssociate({ id, psp })
            }
          />
        </div>
      )}
      <Stack.Item fill>
        <TextField
          labelHidden
          value={serialNumber}
          autoComplete="off"
          disabled
        />
      </Stack.Item>
      <Stack.Item>
        {psp === "mercadopago" ? (
          <Image src={mercadopagoSvg} style={{ width: "48px" }} />
        ) : (
          <Image src={stoneSvg} style={{ width: "48px" }} />
        )}
      </Stack.Item>
      <Button icon={DeleteMinor} onClick={() => onDelete(id)} />
    </Stack>
  );
}
