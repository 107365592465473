import styled from "styled-components";

export const Container = styled.div``;

export const NfcePanelTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 18px;
`;

export const NfcePanelButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 24px;
  gap: 8px;
  margin-top: 8px;
`;

export const RegisterEmail = styled.div`
  padding-top: 20px;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 8px;
`;

export const ContainerSkeleton = styled.div`
  margin: 0 auto;
`;

export const SubtitlePayment = styled.div`
  font-size: 16px;
  padding-bottom: 18px;
  padding-top: 18px;
  text-align: center;
`;

export const ProgressStatusIcon = styled.div`
  padding: 8px;
`;

export const TitleFinishedPayment = styled.h3`
  font-size: 24px;
  padding: 8px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
`;
