import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { Pos } from "@shopify/app-bridge/actions";

import { Page, Button, Spinner } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState, useCallback } from "react";
import CopyToClipboard from "../../component/CopyClipboard/CopyClipboard.jsx";
import ErrorSituation from "../../component/errorSituation/ErrorSituation.jsx";
import { OrderCanceledFeedback } from "../../component/Feedback/OrderCanceledFeedback.jsx";
// import ReturnFeedback from "../../component/Feedback/ReturnFeedback.jsx";
import withBackButton from "../../component/hoc/withBackButton.jsx";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame.jsx";
import SendEmailModal from "../../component/modal/SendEmailModal.jsx";
import TitleBar from "../../component/TitleBar/TitleBar.jsx";
import useFirestoreDoc from "../../hook/useFirestoreDoc.js";
import useFirestoreQuery from "../../hook/useFirestoreQuery.js";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator.js";
import useProducer from "../../hook/useProducer.js";
import useShopifyOrder from "../../hook/useShopifyOrder.js";
import useShopifyOrderId from "../../hook/useShopifyOrderId.js";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl.js";
import backend from "../../service/backend.js";
import UnexpectedErrorPage from "../unexpectedErrorPage/UnexpectedErrorPage.jsx";
import CreateReturnPage from "./CreateReturn.jsx";

import {
  Container,
  CentralBlock,
  PrimaryText,
  SecondaryText,
  BottomBlock,
  ReturnButtonContainer,
  InlineContainer,
} from "./Return.styles.js";

export default withBackButton(ReturnOrderPage, "/one-button-page");

function ReturnOrderPage() {
  const { enableReturnMessage: enableReturnMessage } = useFlags();
  const producer = useProducer();
  const { returnCouponDiscount } = useFlags();
  const {enableThreeMonthsReturnMessage} = useFlags();

  const [loadingReturn, setLoadingReturn] = useState(false);
  const [loadingCoupon, setLoadingCoupon] = useState(false);


  useHideLoadingIndicator();

  const companyShop = useShopifyShopUrl();
  const shopifyOrderId = useShopifyOrderId();

  const shopifyOrderRequest = useShopifyOrder({ id: shopifyOrderId });

  const fiscalInvoiceRequest = useFirestoreDoc(
    "fiscalInvoice",
    `${companyShop}:${shopifyOrderId}:sale`,
  );

  const returnRequest = useFirestoreDoc(
    "return",
    `${companyShop}:${shopifyOrderId}`,
  );

  const fiscalInvoiceStatus = fiscalInvoiceRequest.data?.status ?? null;

  const [sendEmailModalIsOpen, setSendEmailModalIsOpen] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const openSendEmailModal = () => setSendEmailModalIsOpen(true);
  const closeSendEmailModal = () => setSendEmailModalIsOpen(false);

  const canCreateCoupon = useCallback(() => {
    return !!returnRequest?.data?.total?.amount;
  }, [returnRequest?.data]);


  const getReturnName = useCallback(() => {
    return returnRequest?.data?.code;
  }, [returnRequest?.data?.code]);

  const getReturnValue = useCallback(() => {
    return returnRequest?.data?.total?.amount;
  }, [returnRequest?.data?.total?.couponAmount]);

  const returnValue = getReturnValue();

  const hasCoupon = useCallback(() => {
    return returnRequest?.data?.code;
  }, [returnRequest?.data?.code]);

  const sendEmail = useCallback(
    async (customerEmail) => {
      setIsSendingEmail(true);

      await backend.executeProcess(
        {
          type: "sendDiscountEmail",
          input: {
            receiverEmail: customerEmail,
            companyShop,
            code: getReturnName(),
          },
        },
        { unique: true },
      );
      setIsSendingEmail(false);
      closeSendEmailModal();
    },
    [shopifyOrderRequest.data],
  );

  const shopifyPos = Pos.create(useShopifyAppBridge());
  const closeApp = () => shopifyPos.dispatch(Pos.Action.CLOSE);

  const returnWorkflowQuery = useFirestoreQuery(
    "workflow",
    ["type", "==", "processReturn"],
    ["input.companyShop", "==", companyShop],
    ["input.shopifyOrderId", "==", shopifyOrderId],
  );

  if (shopifyOrderRequest.isLoading) {
    return (
      <Page>
        <TitleBar title="Devolução" />
        <LoadingFrame title="Devolução" />
      </Page>
    );
  } else if (shopifyOrderRequest.didFail || returnWorkflowQuery.didFail) {
    return (
      <UnexpectedErrorPage
        error={shopifyOrderRequest.error ?? returnWorkflowQuery.error}
      />
    );
  } else if (shopifyOrderRequest.data) {
    const shopifyOrder = shopifyOrderRequest.data;
    const fiscalInvoiceNotCreated =
      shopifyOrder?.errorSituation?.isFiscalInvoiceNotCreated ||
      shopifyOrder?.nfceSaleStatus?.nfceProcessing;
    const orderIsNotPaid =
      !shopifyOrder?.errorSituation?.isPaid &&
      !shopifyOrder?.errorSituation?.isEcommerce &&
       shopifyOrder?.omieClientId === null;

    const nfCancelled = fiscalInvoiceStatus === "canceled";
    const isNotReturnable = shopifyOrder?.isReturnable === false;
    const blockReturn = shopifyOrder?.blockReturn === true;

    const possibilityOfErrors = {
      fiscalInvoiceNotCreated,
      nfCancelled,
      orderIsNotPaid,
    };

    if (enableReturnMessage) {
      possibilityOfErrors.isNotReturnable = isNotReturnable;
    }

    if (enableThreeMonthsReturnMessage){
      possibilityOfErrors.blockReturn = blockReturn
    }

    if (shopifyOrder?.wasCanceled) {
      return <OrderCanceledFeedback />;
    }

    const generateCoupon = () => {
      setLoadingCoupon(true);
      producer.produce(
        producer.createEvent({
          type: "createCoupon",
          payload: {
            shopifyOrderId: `${shopifyOrder.id}`,
            companyShop: shopifyOrder.companyShop,
          },
        }),
      );
    };

    if (loadingReturn || returnRequest?.data) {
      return (
        <Container>
          <SendEmailModal
            onSubmit={({ customerEmail }) => sendEmail(customerEmail)}
            initialCustomerEmail={shopifyOrder?.customer?.email}
            onClose={closeSendEmailModal}
            isOpen={sendEmailModalIsOpen}
          />
          <CentralBlock>
            {hasCoupon() || loadingCoupon ? (
              <>
                {hasCoupon() ? (
                  <>
                    <CircleTickMajor height="6vh" />
                    <SecondaryText>
                      Cupom de troca criado com sucesso
                    </SecondaryText>

                    <PrimaryText>CUPOM:</PrimaryText>

                    <InlineContainer>
                      <PrimaryText>{getReturnName()}</PrimaryText>
                      <CopyToClipboard textToCopy={getReturnName()} />
                    </InlineContainer>
                  </>
                ) : (
                  <>
                    <Spinner
                      accessibilityLabel="Spinner example"
                      size="large"
                    />
                    <div>Criando seu cupom...</div>
                  </>
                )}
              </>
            ) : (
              <>
                <CircleTickMajor height="6vh" />
                <PrimaryText>Devolução</PrimaryText>  
                  <PrimaryText>Valor Devolvido: R$ {returnValue}</PrimaryText>
                {typeof secondaryText === "string" && (
                  <SecondaryText>Pedido devolvido com sucesso</SecondaryText>
                )}
              </>
            )}
          </CentralBlock>

          <BottomBlock>
            {returnCouponDiscount && !hasCoupon() && (
              <ReturnButtonContainer>
                <Button
                  fullWidth
                  size="large"
                  onClick={generateCoupon}
                  loading={loadingCoupon}
                  disabled={loadingCoupon || !canCreateCoupon()}

                >
                  Gerar cupom de troca
                </Button>
              </ReturnButtonContainer>
            )}
            {hasCoupon() && (
              <ReturnButtonContainer>
                <Button
                  fullWidth
                  size="large"
                  onClick={openSendEmailModal}
                  loading={isSendingEmail}
                >
                  Enviar por e-mail
                </Button>
              </ReturnButtonContainer>
            )}
            <ReturnButtonContainer>
              <Button primary fullWidth size="large" onClick={() => closeApp()}>
                Fechar
              </Button>
            </ReturnButtonContainer>
          </BottomBlock>
        </Container>
      );
    }

    return (
      <ErrorSituation
        conditions={possibilityOfErrors}
        path={"/one-button-page"}
        cases={{
          fiscalInvoiceNotCreated: {
            title: "Não há nota fiscal para devolver",
            errorMessage:
              "A nota fiscal de venda ainda não foi emitida para este pedido. Não há nada para devolver no momento. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
          },
          orderIsNotPaid: {
            title: "Este Pedido ainda não foi pago",
            errorMessage:
              "Não é possível devolver um pedido que ainda não foi pago. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
          },
          nfCancelled: {
            title: "Não há nota fiscal para cancelar",
            errorMessage:
              "A nota fiscal de venda já foi cancelada para este pedido. Não há nada para cancelar no momento. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
          },
          isNotReturnable: {
            title: "Não é possivel devolver esse pedido",
            errorMessage:
              "O pedido está fora do prazo previsto para a devolução.",
          },
          blockReturn: {
            title: "Não é possível devolver esse pedido",
            errorMessage: "Não é possível realizar devoluções de vendas efetuadas há mais de 3 meses."
          }
        }}
      >
        <CreateReturnPage
          shopifyOrder={shopifyOrder}
          setLoadingReturn={setLoadingReturn}
          loadingReturn={loadingReturn}
        />
      </ErrorSituation>
    );
  }

  return (
    <Page>
      <TitleBar title="Devolução" />
      <LoadingFrame title="Devolução" />
    </Page>
  );
}
