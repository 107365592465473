import { Button } from "@shopify/polaris";
import {
  ActionCardContainer,
  ContentContainer,
  IconContainer,
  ActionCardContentContainer,
  Title,
  TitleContainer,
} from "./ActionCard.styles";

export function ActionCard({
  title,
  disabled,
  onIconClick,
  Icon,
  iconColor,
  children,
  loading,
}) {
  return (
    <ActionCardContainer>
      <ActionCardContentContainer>
        {typeof title === "string" ? (
          <Title>{title}</Title>
        ) : (
          <TitleContainer>{title}</TitleContainer>
        )}

        <ContentContainer>{children}</ContentContainer>
      </ActionCardContentContainer>

      <IconContainer>
        <Button
          loading={loading}
          disabled={disabled}
          plain
          icon={Icon}
          onClick={onIconClick}
          color={iconColor}
        />
      </IconContainer>
    </ActionCardContainer>
  );
}
