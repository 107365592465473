import React from "react";
import BackButton from "../backButton/BackButton";

export default function withBackButton(Component, backPage) {
  return function WithBackButton(props) {
    return (
      <>
        <BackButton backToPage={backPage} />
        <Component {...props} />
      </>
    );
  };
}
