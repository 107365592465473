import { FooterHelp } from "@shopify/polaris";
import React from "react";

export default function VersionFooter() {
  return (
    <FooterHelp>
      <span style={{ color: "#777", fontSize: ".9em" }}>
        Iglu POS v{process.env.VERSION}
      </span>
    </FooterHelp>
  );
}
