import { Button } from "@shopify/polaris";
import { useState } from "react";

import ConfirmationModal from "../../../../../../../../component/modal/Confirmation";
import { getCustomerCPF } from "../../../../../../../../helper/cpf";
import backend from "../../../../../../../../service/backend";
import { useIsReadyToGenerateNfce } from "../../../../../../../../store/useMultiplePaymentsStore";
import { useOrderStore } from "../../../../../../../../store/useOrderStore";
import {
  NFCE_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../../store/usePaymentFlowStore";
import { BackButton } from "../BackButton/BackButton";
import { Container } from "./GenerateNfceButton.styles";

export function GenerateNfceButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setNfceStep } = usePaymentFlowStore();

  const { isForShipping } = useOrderStore();

  const modalPrompt = isForShipping
    ? "Após confirmação, o pedido será marcado como pago."
    : "Após confirmação, a NFC-e será gerada e o pedido será marcado como pago.";

  const { shopifyOrderId, companyShop, staffId, cpf } = useOrderStore();

  const { isReady, isAlmostReady } = useIsReadyToGenerateNfce();

  if (isAlmostReady) {
    return (
      <Container>
        <BackButton disabled />

        <Button loading disabled primary>
          Gerar NF
        </Button>
      </Container>
    );
  }
  if (!isReady) {
    return null;
  }

  const goToNfcePage = () => setNfceStep(NFCE_STEPS.NFCE.value);

  const executeProcessSale = () => {
    return backend.executeProcess({
      type: "processSale",
      input: {
        companyShop,
        shopifyOrderId: shopifyOrderId,
        customerCPF: getCustomerCPF({ customerCPFValue: cpf, companyShop }),
        staffId,
      },
    });
  };

  const executePaymentProcess = async () => {
    executeProcessSale();
    goToNfcePage();
  };

  const handleOPenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  const textButton = isForShipping ? "Cobrar" : "Gerar NF";

  return (
    <>
      <Container>
        <BackButton />

        <Button onClick={handleOPenModal} primary>
          {textButton}
        </Button>
      </Container>

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirmação de Pagamento"
        prompt={modalPrompt}
        confirmText={isForShipping ? "Confirmar" : "Gerar NF"}
        onConfirm={executePaymentProcess}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
      />
    </>
  );
}
