import { useEffect, useState } from "react";
import { useAuthenticatedFetch } from "./useAuthenticatedFetch.js";
import useShopifyOrderId from "./useShopifyOrderId.js";
import useShopifyShopUrl from "./useShopifyShopUrl.js";

export default function useLink() {
  const shopifyOrderId = useShopifyOrderId();
  const companyShop = useShopifyShopUrl();
  const fetch = useAuthenticatedFetch();
  const [checkoutLink, setCheckoutLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // const response = await fetch(
  //   `${process.env.HOST}/api/nps/${companyShop}/${shopifyOrderId}`,
  // );

  console.log(`${process.env.HOST}/api/link/${companyShop}/${shopifyOrderId}`);

  const fetchLink = async ({ companyShop, shopifyOrderId }) => {
    setLoading({ shopifyOrderId, companyShop });
    try {
      const response = await fetch(
        `${process.env.HOST}/api/link/${companyShop}/${shopifyOrderId}`,
      );

      const jsonResponse = await response.json();

      const { checkoutLink: link, success } = jsonResponse;

      if (success) {
        console.log(link);
        setCheckoutLink(link);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyShop && shopifyOrderId) {
      fetchLink({ companyShop, shopifyOrderId });
    }
  }, [companyShop, shopifyOrderId]);

  return {
    error,
    loading,
    data: checkoutLink,
  };
}
