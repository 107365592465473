import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { SelectedAutomaticConciliation } from "../../../components/AutomaticConciliation/SelectedAutomaticConciliation";

export function SelectedAutomaticConciliationMultiple() {
  const { setCurrentPayment, currentPayment } = useCurrentMultiplePayment();
  const handleChange = (automaticConciliation) => {
    setCurrentPayment({ ...currentPayment, automaticConciliation });
  };
  return (
    <SelectedAutomaticConciliation
      setAutomaticConciliation={handleChange}
      automaticConciliation={currentPayment.automaticConciliation}
    />
  );
}
