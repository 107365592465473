import { Button } from "@shopify/polaris";
import { ReplayMinor } from "@shopify/polaris-icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUniquePaymentStore } from "../../../../../../../../../store/useUniquePaymentStore";

function determineButtonText(isForShipping, chargedSeparately, automaticLink, automaticLinkAtLocation) {
  if (isForShipping && !automaticLinkAtLocation) {
    return "Cobrar";
  } else if (chargedSeparately || !automaticLink || !automaticLinkAtLocation) {
    return "Gerar NF";
  } else {
    return "Gerar Link";
  }
}


function isButtonDisabled(requiredFieldsFlag, nsu, automaticLinkAtLocation) {
  if(!automaticLinkAtLocation){
  return requiredFieldsFlag && nsu === "";
  }
}

export function LinkSubmit({ onClick, isForShipping,automaticLinkAtLocation }) {
  const { chargedSeparately, status, nsu } = useUniquePaymentStore();
  const { requiredConciliationFields: requiredFieldsFlag, automaticLink } = useFlags();

  const buttonText = determineButtonText(isForShipping, chargedSeparately, automaticLink, automaticLinkAtLocation);
  const buttonShouldBeDisabled = isButtonDisabled(requiredFieldsFlag, nsu, automaticLinkAtLocation);

  if (status === "error") {
    return (
      <Button fullWidth icon={ReplayMinor} onClick={onClick}>
        {buttonText}
      </Button>
    );
  }
  return (
    <Button primary onClick={onClick} disabled={buttonShouldBeDisabled}>
      {buttonText}
    </Button>
  );
}
