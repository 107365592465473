import { Button } from "@shopify/polaris";
import { useState } from "react";

import ConfirmationModal from "../../../../../../component/modal/Confirmation";
import R$ from "../../../../../../component/FormatCurrency/R$";
import { Container, Price, PriceValue } from "./CancelPaymentButton.styles";

export default function CancelPaymentButton({
  value,
  onCancel,
  disabled,
  loading,
  modalPrompt,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <Container>
        <Price>Valor</Price>
        <PriceValue>
          <R$>{value / 100}</R$>
        </PriceValue>

        <Button
          fullWidth
          onClick={handleOpenModal}
          disabled={disabled}
          loading={loading}
        >
          Cancelar pagamento
        </Button>
      </Container>

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirmação de cancelamento"
        prompt={modalPrompt}
        confirmText="Confirmar"
        onConfirm={onCancel}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
      />
    </>
  );
}
