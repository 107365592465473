import React from "react";
import { SummaryCard } from "../../../../component/SummaryCard/SummaryCard";
import usePaymentData from "../../../../hook/usePaymentData";
import { usePaymentsStore } from "../../../../store/usePaymentsStore";
import {
  Container,
  ContainerTableInformation,
  Subtitle,
} from "./SummaryValues.styles";

export function SummaryValues() {
  const { payments } = usePaymentsStore();
  const { averageValueOrder, totalAllPayments, conciliation } =
    usePaymentData();

  const shopifyOrderIdCounts = {};
  payments.forEach((payment) => {
    const orderId = payment.shopifyOrderId;
    shopifyOrderIdCounts[orderId] = (shopifyOrderIdCounts[orderId] || 0) + 1;
  });

  const uniqueShopifyOrderIds = Object.keys(shopifyOrderIdCounts);

  const calculateTotalAmount = () => {
    const filteredPayments = (totalAllPayments || []).filter(
      (payment) =>
        payment.method !== "gift-card" && payment.method !== "store-credit",
    );

    let totalAmountCents = 0;
    for (const payment of filteredPayments) {
      const amountCents = parseInt(payment.amountCents, 10) || 0;
      totalAmountCents += amountCents;
    }
    return `R$ ${(totalAmountCents / 100).toFixed(2)}`;
  };

  const calculateAverageValue = () => {
    if (!uniqueShopifyOrderIds?.length || uniqueShopifyOrderIds?.length === 0) {
      return "R$ 0.00";
    }
    const avaregeByOrder = averageValueOrder / uniqueShopifyOrderIds?.length;
    return `R$ ${(avaregeByOrder / 100).toFixed(2)}`;
  };

  const Conciliation = () => {
    if (!payments || payments.length === 0) {
      return `${0}%`;
    }
    const averageConciliation = payments.length / conciliation;

    if (averageConciliation === Infinity) {
      return `${0}%`;
    }

    return `${averageConciliation.toFixed(2)}%`;
  };

  const totalFormatted = calculateTotalAmount();
  const averageValue = calculateAverageValue();
  const conciliationAvarage = Conciliation();
  const orders = uniqueShopifyOrderIds?.length;

  const CARD_DETAILS_GROUP = [
    {
      id: 1,
      title: "Pedidos",
      text: orders,
    },
    {
      id: 2,
      title: "Faturamento",
      text: totalFormatted,
    },
    {
      id: 3,
      title: "Pagamentos avulsos",
      text: conciliationAvarage,
    },
    {
      id: 4,
      title: "Ticket Médio",
      text: averageValue,
    },
  ];

  return (
    <>
      <ContainerTableInformation>
        <Subtitle>Resumo</Subtitle>
        <Container>
          {CARD_DETAILS_GROUP.map((item) => (
            <SummaryCard
              key={item.id}
              title={item.title}
              text={item.text}
              onClick={item.id === 2}
              icon={item.id === 2 && item.icon}
            />
          ))}
        </Container>
      </ContainerTableInformation>
    </>
  );
}
