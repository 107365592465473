// Errors caught by an `ErrorBoundary` during React's rendering.
import { UnknownError } from "@iglu/common/error";

export const ERR_FRONTEND_REACT_UNKNOWN = "ERR_FRONTEND_REACT_UNKNOWN";

export default class ReactError extends UnknownError {
  constructor(message = null, { code = null, componentStack } = {}) {
    super(message, { code: code ?? ERR_FRONTEND_REACT_UNKNOWN });
    this.name = "ReactError";
    this.componentStack = componentStack;
  }

  static serialize(javascript) {
    return { componentStack: javascript.componentStack };
  }
}
