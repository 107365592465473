import {
  CardInformationContainer,
  CardInformationText,
  CardInformationButtonDescription,
  CardInformationGrid,
} from "./SummaryCard.styles";

export function SummaryCard({ title, text, onClick }) {
  return (
    <CardInformationContainer onClick={onClick}>
      <CardInformationText>{text}</CardInformationText>
      <CardInformationGrid>
        <CardInformationButtonDescription>
          {title}
        </CardInformationButtonDescription>
      </CardInformationGrid>
    </CardInformationContainer>
  );
}
