import { Banner } from "@shopify/polaris";
import { DiamondAlertMajor } from "@shopify/polaris-icons";
import { ErrorBannerContainer } from "./ErrorBanner.styles";

export default function ErrorBanner({ error }) {
  return (
    <ErrorBannerContainer>
      <Banner
        bannerOpen
        title={error}
        icon={DiamondAlertMajor}
        status="critical"
      ></Banner>
    </ErrorBannerContainer>
  );
}
