import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import { Share } from "@shopify/app-bridge/actions";
import React, { useCallback, useEffect, useState } from "react";
import useFirestoreDoc from "../../../../../../hook/useFirestoreDoc.js";
// import useFirestoreQuery from "../../../../../../hook/useFirestoreQuery";

import { useOrderStore } from "../../../../../../store/useOrderStore";
import {
  NFCE_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore.js";
import { useUniquePaymentStore } from "../../../../../../store/useUniquePaymentStore.js";
import { PaymentLink } from "../../components/LinkPayment/PaymentLink";


const _isShopifyPos = isShopifyPos();

let useAppBridge = null;
if (_isShopifyPos) {
  useAppBridge = useShopifyAppBridge;
} else {
  useAppBridge = () => null;
}

export function LinkPayment() {
  const { shopifyOrderId, companyShop } = useOrderStore();
  const { id: paymentId } = useUniquePaymentStore();
  const [status, setStatus] = useState("creating");

  const appBridge = useAppBridge();

  const paymentSnapShot = useFirestoreDoc(
    "payment",
    `${companyShop}:${shopifyOrderId}:${paymentId}`,
  );

  const [pendingPaymentUrl, setPendingPaymentUrl] = useState(null);


  const { setNfceStep } = usePaymentFlowStore();

  const handlePaid = () => {
    setNfceStep(NFCE_STEPS.NFCE.value);
  };

  useEffect(() => {
    setStatus(paymentSnapShot?.data?.status);
    
    if (paymentSnapShot?.data?.status === "settled") {
      handlePaid();
      return;
    }
    
    if (paymentSnapShot?.data?.status === "pending") {
      setPendingPaymentUrl(paymentSnapShot?.data?.paymentUrl);
    }
  }, [paymentSnapShot?.data?.status]);

  const copyLinkToClipboard = useCallback(() => {
    if (pendingPaymentUrl) {
      navigator.clipboard
        .writeText(pendingPaymentUrl)
        .then(() => {
          alert("O link foi copiado com sucesso!");
        })
        .catch((error) => {
          console.error("Erro ao copiar o link:", error);
          alert(
            "Ocorreu um erro ao copiar o link. Por favor, tente novamente.",
          );
        });
    }
  }, [pendingPaymentUrl]);

  const handleShare = useCallback(() => {
    if (appBridge) {
      Share.create(appBridge).dispatch(Share.Action.SHOW, {
        text: pendingPaymentUrl,
      });
    } else if (navigator.share) {
      navigator.share({ url: pendingPaymentUrl });
    } else {
      console.error("Couldn't share");
    }
  }, [appBridge, pendingPaymentUrl]);

  if (paymentSnapShot.isLoading) {
    return null;
  }

  return (
    <div>
      <PaymentLink
        status={status}
        handleCopy={copyLinkToClipboard}
        handleShare={handleShare}
      />
    </div>
  );
}
