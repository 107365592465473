import { Page, Layout, Button } from "@shopify/polaris";
import { format } from "date-fns";
import React from "react";
import BackButton from "../../component/backButton/BackButton.jsx";
import LoadingFrame from "../../component/LoadingFrame/LoadingFrame";
import PaymentCard from "../../component/PaymentCard/PaymentCard.jsx";
import PaymentFilter from "../../component/PaymentFilter/PaymentFilter.jsx";
import useFirestoreQuery from "../../hook/useFirestoreQuery";
import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import { usePixAnalyticsStore } from "../../store/usePixAnalyticsStore.js";
import { Title } from "../IgluAnalytics/IgluAnalytics.styles.js";
import {
  StyledPaymentCard,
  StyledPaymentCardContainer,
} from "./PixAnalytics.styles.js";

export default function PixAnalytics() {
  useHideLoadingIndicator();

  const companyShop = useShopifyShopUrl();
  const { selectedDate, filterUntilDate } = usePixAnalyticsStore();

  const paymentQuery = useFirestoreQuery(
    "payment",
    ["companyShop", "==", companyShop],
    ["method", "==", "pix"],
    ["status", "==", "settled"],
    ["createdAt", ">=", format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX")],
    ["createdAt", "<=", format(filterUntilDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX")],
  );

  const pixPayments = paymentQuery?.data ?? [];

  const exportToCSV = () => {
    const data = [
      [
        "id",
        "Valor",
        "Shopify Order ID",
        "Nome do Pedido",
        "Avulso",
        "Tecnospeed ID",
        "Id da location",
      ],
      ...pixPayments.map((payment) => [
        payment?.id,
        payment?.amountCents,
        payment?.shopifyOrderId,
        payment?.orderName,
        payment?.wasConciliation ? "Sim" : "Não",
        payment?.tecnospeedPixPaymentId,
        payment?.posLocationId,
      ]),
    ];

    const csvContent = data.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const locationQuery = useFirestoreQuery("posLocation", [
    "companyShop",
    "==",
    companyShop,
  ]);

  if (locationQuery.isLoading) {
    return (
      <Page>
        <LoadingFrame />
      </Page>
    );
  }

  return (
    <Page>
      <Layout>
        <BackButton backToPage={"/"} />

        <Layout.Section>
          <Title>Relatório Pix</Title>

          <Button onClick={exportToCSV}>Exportar para CSV</Button>

          <PaymentFilter />

          <StyledPaymentCardContainer>
            {pixPayments.map((payment) => (
              <StyledPaymentCard key={payment.id}>
                <PaymentCard
                  value={(payment?.amountCents / 100).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  orderName={payment?.orderName}
                  wasConciliation={payment?.wasConciliation ? "Sim" : "Não"}
                  tecnospeedId={payment?.tecnospeedPixPaymentId || "0"}
                />
              </StyledPaymentCard>
            ))}
          </StyledPaymentCardContainer>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
