import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 300px;
  margin: 0 auto;
`;

export const FilterLabel = styled.label`
  margin-bottom: 5px;
`;

export const CalendarContainer = styled.div`
  display: inline-block;
  text-align: center;
`;
