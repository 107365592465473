import styled, { keyframes } from "styled-components";

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  margin: 32px;
  justify-content: center;
  display: flex;
`;

export const LoadingIndicatorSpinner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  border-top: 6px solid green;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
  position: relative;

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-bottom: 6px solid green;
    border-left: 6px solid transparent;
  }
`;
