import StandardError from "../StandardError/StandardError.jsx";

export default function ErrorSituation({
  cases,
  conditions,
  children,
  path,
  ExtraActions,
}) {
  const [key] =
    Object.entries(conditions).find(
      // eslint-disable-next-line no-unused-vars
      ([key, value]) => value === true
    ) || [];

  if (key && conditions[key]) {
    const { title, errorMessage } = cases[key];
    return (
      <StandardError
        title={title}
        placeHolder={errorMessage}
        path={path}
        ExtraActions={ExtraActions}
      />
    );
  }

  return <>{children}</>;
}
