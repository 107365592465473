import { useFlags } from "launchdarkly-react-client-sdk";
import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { NsuIdField as NsuIdFieldInput } from "../../../components/NsuIdField/NsuIdField";

export function NsuField() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const { requiredConciliationFields: requiredFieldsFlag } = useFlags();

  const shouldRenderThisField = () => {
    if (
      currentPayment.chargedSeparately &&
      currentPayment.automaticConciliation
    ) {
      return false;
    }
    if (currentPayment.paymentMethod === "PIX") {
      return false;
    }

    return currentPayment.chargedSeparately;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (nsu) => setCurrentPayment({ nsu });

  const nsuText = requiredFieldsFlag ? "NSU (Obrigatório)" : "NSU (Opcional)";

  return (
    <NsuIdFieldInput
      value={currentPayment.nsu}
      labelText={nsuText}
      onChange={handleChange}
    />
  );
}
