import { useEffect, useState } from "react";
import createSingleTefPaymentRefund, {
  STATUS as SINGLE_REFUND_STATUS,
} from "../helper/createSingleTefPaymentRefund.js";
import useFirestoreQuery from "./useFirestoreQuery.js";
import useShopifyOrderId from "./useShopifyOrderId.js";
import useShopifyShopUrl from "./useShopifyShopUrl.js";

export const STATUS = {
  LOADING: "loading",
  NOOP: "noop",
  READY: "ready",
  EXECUTING: "executing",
  DONE: "done",
};

export default function _() {
  const [status, setStatus] = useState(STATUS.LOADING);
  const [error, setError] = useState(null);
  const shopifyShopHost = useShopifyShopUrl();
  const shopifyOrderId = useShopifyOrderId();

  const settledPaymentListQuery = useFirestoreQuery(
    "payment",
    ["companyShop", "==", shopifyShopHost],
    ["shopifyOrderId", "==", shopifyOrderId],
    ["status", "==", "settled"],
    ["wasConciliation", "==", false],
  );

  const refundedPaymentListQuery = useFirestoreQuery(
    "payment",
    ["companyShop", "==", shopifyShopHost],
    ["shopifyOrderId", "==", shopifyOrderId],
    ["status", "==", "refunded"],
  );

  const [refundList, setRefundList] = useState(null);

  useEffect(() => {
    if (
      refundList === null &&
      settledPaymentListQuery.didSucceed &&
      refundedPaymentListQuery.didSucceed
    ) {
      const paymentList = [
        ...settledPaymentListQuery.data,
        ...refundedPaymentListQuery.data,
      ];

      const initialRefundList = paymentList
        .filter(
          (payment) =>
            payment.service === "softwareexpress" &&
            ["credit", "debit"].includes(payment.method),
        )
        .sort((p0, p1) => new Date(p0.createdAt) - new Date(p1.createdAt))
        .map((payment) =>
          createSingleTefPaymentRefund({
            payment,
            onError: setError,
            onNext: (nextRefund) => {
              setRefundList((prev) => {
                const next = [...prev];
                const index = next.findIndex(
                  (refund) => refund.payment.id === nextRefund.payment.id,
                );
                next[index] = nextRefund;
                setStatus(aggregateRefundListStatus(next));
                return next;
              });
            },
          }),
        );

      setRefundList(initialRefundList);
      setStatus(aggregateRefundListStatus(initialRefundList));
    }
  }, [refundList, settledPaymentListQuery, refundedPaymentListQuery]);

  const execute = ({
    locationCnpj,
    softwareexpressClientId,
    posDeviceId,
    printerWifiIp,
  }) => {
    for (const refund of refundList) {
      const sideEffect = refund.execute({
        locationCnpj,
        softwareexpressClientId,
        posDeviceId,
        printerWifiIp,
      });
      if (sideEffect === "did-open-bridge") {
        return;
      }
    }
    // All refunds are done.
    // Update will come in through onNext from Bridge through Firestore.
  };

  return { status, error, list: refundList ?? [], execute };
}

function aggregateRefundListStatus(refundList) {
  refundList = refundList.filter(
    (r) => r.status !== SINGLE_REFUND_STATUS.INTERRUPTED.NOT_SAME_DAY,
  );
  // ^ Filter out "unretriable" refunds.

  if (refundList.length === 0) {
    return STATUS.NOOP;
  }

  if (refundList.every((r) => r.status === SINGLE_REFUND_STATUS.DONE)) {
    return STATUS.DONE;
  } else if (refundList.every((r) => r.status === SINGLE_REFUND_STATUS.READY)) {
    return STATUS.READY;
  } else {
    return STATUS.EXECUTING;
  }
}
