import React from "react";
import ContentLoader from "react-content-loader";

export default function QRCodeSkeleton(props) {
  return (
    <ContentLoader
      speed={2}
      width={180}
      height={250}
      viewBox="0 0 300 325"
      backgroundColor="#a7a1a1"
      foregroundColor="#ecebeb"
      {...props}
    >
      <path d="M  38.073 h 47.592 v 47.592  M 38.073 223.002 h 47.592  H 38.073 z" />
      <path d="M 0 163.852 v 144.815 h 123.739 v -66.629  v 65.269 h 34.674 v -28.555 h 27.195   v -32.634 h 25.156 v 32.634 v -34.674  h -34.674 v 32.634 H 218.58 v -67.308 h -31.612 V 176.77 h 32.634 v 32.634  v -21.756 h 19.717 V 236.6 h 34.674 v -50.991 h -21.756  h 21.756 V 0  v 0.228   h -28.555 V 0 H 0 v 144.135 h 21.756 H 0 z m 101.982 123.059 H 21.756 v -80.226 h 80.226 v 80.226 z m 52.351 -77.507 h 29.578 v 30.595 h -29.578 v -30.595 z m 0 -65.269 h 27.533 v 30.595 h -27.533 v -30.595 z m 119.66 8.839 h -18.7 v -10.878 h -68.326 v -18.357 h 87.025 v 29.235 z M 206.684 21.756 h 80.226 v 80.226 h -80.226 V 21.756 z m -21.756 87.705 h -30.595 v -67.76 h 30.595 v 67.76 z m -32.634 32.635 h -28.468 v -30.595 h 28.468 v 30.595 z M 21.756 21.756 h 80.226 v 80.226 H 21.756 V 21.756 z m 67.396 101.983 v 20.396 h 30.508 v 32.634 h 32.634 v 30.595 h -28.555 v -22.436 H 34.674 v -8.159 h 54.391 v -34.674 H 34.674 v -18.357 h 54.478 z" />
    </ContentLoader>
  );
}
