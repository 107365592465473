import { Button } from "@shopify/polaris";

export function LinkSubmit({ disabled, onClick }) {
  const getIsDisabled = () => {
    return disabled;
  };

  const isDisabled = getIsDisabled();

  const handleClick = () => {
    onClick();
  };

  return (
    <Button primary disabled={isDisabled} onClick={handleClick}>
      Continuar
    </Button>
  );
}
