// Errors received via the `error` event on `window`.
import { UnknownError } from "@iglu/common/error";

export const ERR_FRONTEND_UNCAUGHT_UNKNOWN = "ERR_FRONTEND_UNCAUGHT_UNKNOWN";

export default class UncaughtError extends UnknownError {
  constructor(message = null, { code = null, error } = {}) {
    super(message, { code: code ?? ERR_FRONTEND_UNCAUGHT_UNKNOWN });
    this.name = "UncaughtError";
    this.fileName = error.filename;
    this.lineNumber = error.lineno;
    this.columnNumber = error.colno;
  }

  static serialize(javascript) {
    return {
      fileName: javascript.filename,
      lineNumber: javascript.lineno,
      columnNumber: javascript.colno,
    };
  }
}
