import styled from "styled-components";

export const SelectedAutomaticConciliationContainer = styled.div`
  margin-top: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const ActionCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled.p`
  font-weight: bold;
`;

export const IconContainer = styled.div``;

export const Text = styled.p`
  color: #333;
  margin: 0;
  line-break: anywhere;
`;
