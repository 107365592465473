import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;
`;

export const CentralBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  display: grid;
  grid-gap: 2vh;
  & > * {
    justify-self: center;
  }
`;

export const PrimaryText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2em;
  font-weight: bold;
`;

export const SecondaryText = styled.div`
  color: #575757;
`;

export const BottomBlock = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  width: 90vw;
`;

export const ButtonBlock = styled.div`
  padding-bottom: 10px;
`;

export const ReturnButtonContainer = styled.div`
  margin-bottom: 10px;
`;

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: center;
  margin: 0 auto;
`;
