import { useState } from "react";
import { Card, Heading, Button } from "@shopify/polaris";
import styled from "styled-components";

const Body = styled.div`
  margin-top: 24px;
  font-size: medium;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  > Button {
    margin-top: 10px;
  }
`;

export default function ConfirmationCard({
  orderName,
  primaryAction,
  children,
  primaryDisabled = false,
  prompt = "Confirmar",
}) {
  const [primaryLoading, setPrimaryLoading] = useState(false);
  return (
    <Card
      sectioned
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Heading>Pedido {orderName}</Heading>
        </div>
      }
    >
      <Body>{children}</Body>
      <Card.Section sectioned>
        <ActionButtons>
          <Button
            size="large"
            fullWidth
            primary
            onClick={async () => {
              setPrimaryLoading(true);
              primaryAction();
              setPrimaryLoading(false);
            }}
            disabled={primaryDisabled}
            loading={primaryLoading}
          >
            {prompt}
          </Button>
        </ActionButtons>
      </Card.Section>
    </Card>
  );
}
