export function format(cnpjDigitsString) {
  if (/^[0-9]{14}$/.test(cnpjDigitsString)) {
    return `${cnpjDigitsString.slice(0, 2)}.${cnpjDigitsString.slice(
      2,
      5
    )}.${cnpjDigitsString.slice(5, 8)}/${cnpjDigitsString.slice(
      8,
      12
    )}-${cnpjDigitsString.slice(12, 14)}`;
  } else {
    throw new Error(`Invalid CNPJ digits string "${cnpjDigitsString}"`);
  }
}

export function unformat(formattedCnpjString) {
  if (
    /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/.test(
      formattedCnpjString
    )
  ) {
    return formattedCnpjString.replace(/[^0-9]/g, "");
  } else {
    throw new Error(`Invalid rendered CNPJ string "${formattedCnpjString}"`);
  }
}
