import React, { useEffect, useState } from "react";
import PageSkeleton from "../../../../../component/skeletonLoading/PageSkeleton";
import { useFiscalInvoiceStore } from "../../../../../store/useFiscalInvoiceStore";
import { useOrderStore } from "../../../../../store/useOrderStore";

export default function HtmlToImageConverter() {
  const [htmlContent, setHtmlContent] = useState(null);

  const [loading, setLoading] = useState(false);

  const { fiscalInvoiceId } = useFiscalInvoiceStore();

  const _fiscalInvoiceId = fiscalInvoiceId;

  const { companyShop, shopifyOrderId } = useOrderStore();

  const type = "sale";

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const url = `${process.env.HOST}/api/nfce/${encodeURIComponent(
          _fiscalInvoiceId,
        )}/${encodeURIComponent(companyShop)}/${encodeURIComponent(
          shopifyOrderId,
        )}?type=${type}`;

        const response = await fetch(url);
        const fetchedHtmlContent = await response.text();

        setHtmlContent(fetchedHtmlContent);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching HTML:", error);
      }
    };

    setLoading(true);
    if (_fiscalInvoiceId && companyShop) {
      fetchHtmlContent();
    }
  }, [_fiscalInvoiceId, companyShop]);

  if (loading && !_fiscalInvoiceId) {
    return <PageSkeleton />;
  }
  return (
    <div
      id="html-container"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}
