import { Button } from "@shopify/polaris";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import ConfirmationModal from "../../../../../../../../../component/modal/Confirmation";
import { getCustomerCPF } from "../../../../../../../../../helper/cpf";
import useDevice from "../../../../../../../../../hook/useDevice";
import useFirestoreDoc from "../../../../../../../../../hook/useFirestoreDoc";
import backend from "../../../../../../../../../service/backend";
import { useConfigurationStore } from "../../../../../../../../../store/useConfigurationStore";
import { useLocationStore } from "../../../../../../../../../store/useLocationStore";
import { useOrderStore } from "../../../../../../../../../store/useOrderStore";
import {
  NFCE_STEPS,
  UNIQUE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../../../../store/usePaymentFlowStore";
import { useUniquePaymentStore } from "../../../../../../../../../store/useUniquePaymentStore";
import { CardsSubmit } from "./CardsSubmit";
import { CashSubmit } from "./CashSubmit";
import { ExchangeSubmit } from "./ExchangeSubmit";
import { LinkSubmit } from "./LinkSubmit";
import { PixSubmit } from "./PixSubmit";

export function SubmitButtonUniquePayment() {
  const {
    automaticConciliation,
    id: paymentId,
    paymentMethod,
    paymentInstallments,
    chargedSeparately,
    nsu,
    aut,
    cardFlag,
  } = useUniquePaymentStore();

  const { configuration } = useConfigurationStore();

  const { location } = useLocationStore();

  const device = useDevice();
  const staffId = device?.pos?.user?.id ? `${device.pos.user.id}` : null;

  const { setUniquePaymentStep, setNfceStep } = usePaymentFlowStore();
  const { automaticLink } = useFlags();

  const {
    shopifyOrderId,
    companyShop,
    posLocationId,
    posDeviceId,
    orderTotalInCents,
    cpf,
    isForShipping,
  } = useOrderStore();

  const locationConfig = useFirestoreDoc("posLocation", `${companyShop}:${posLocationId}`);

  const isAutomaticLinkAtLocation = locationConfig?.data?.automaticLink ?? false;

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const modalTitle = "Confirmação de Pagamento";
  const modalPrompt = isForShipping
    ? "Após confirmação, o pedido será marcado como pago."
    : "Após confirmação, a NFC-e será gerada e o pedido será marcado como pago.";

  const SubmitButton = () => {
    const handleClick = () => {
      if (chargedSeparately) {
        setModalOpen(true);
        return;
      }
      handleGenerateNFC();
    };

    const defaultButtonText = isForShipping ? "Cobrar" : "Gerar NF";

    switch (paymentMethod) {
      case "PIX":
        return <PixSubmit onClick={handleClick} />;
      case "CASH":
        return (
          <CashSubmit onClick={handleOpenModal} isForShipping={isForShipping} />
        );
      case "CREDIT":
      case "DEBIT":
        return <CardsSubmit onClick={handleClick} />;
      case "LINK":
        return (
          <LinkSubmit
          onClick={automaticLink ? handleClick : handleOpenModal}
          isForShipping={isForShipping}
          automaticLinkAtLocation={isAutomaticLinkAtLocation}
          />
      
        );
      case "EXTERNAL-LINK":
        return (
          <LinkSubmit 
          onClick={automaticLink ? handleClick : handleOpenModal}
          isForShipping={isForShipping}
          automaticLinkAtLocation={isAutomaticLinkAtLocation}
           />
        );
      case "STORE-CREDIT":
        return (
          <ExchangeSubmit
            onClick={handleOpenModal}
            isForShipping={isForShipping}
          />
        );

      default:
        return (
          <Button primary disabled>
            {defaultButtonText}
          </Button>
        );
    }
  };

  const executeProcessSale = () => {
    return backend.executeProcess({
      type: "processSale",
      input: {
        companyShop,
        shopifyOrderId: shopifyOrderId,
        customerCPF: getCustomerCPF({ customerCPFValue: cpf, companyShop }),
        staffId,
      },
    });
  };

  const executeProcessPayment = () => {
    const payment = {
      method: paymentMethod.toLowerCase(),
      installments: paymentInstallments,
      amountCents: String(orderTotalInCents),
      wasConciliation: chargedSeparately,
      orderId: automaticConciliation?.id || undefined,
      nsu: nsu || automaticConciliation?.nsu || undefined,
      cardFlag: cardFlag || automaticConciliation?.cardFlag || undefined,
      aut: aut || automaticConciliation?.aut || undefined,
    };

    return backend.executeProcess({
      type: "processPayment",
      input: {
        companyShop,
        shopifyOrderId,
        uuid: paymentId,
        posLocationId,
        posDeviceId,
        payment,
        staffId,
      },
    });
  };

  const paymentCardService =
    location?.payment?.card?.service ?? configuration?.payment?.card?.service;

  if (typeof paymentCardService !== "string")
    throw new Error(
      `Bad configuration \`.payment.card.service\` = "${paymentCardService}"`,
    );

  const handleGenerateNFC = async () => {
    executeProcessPayment();
    executeProcessSale();

    if (["CASH", "STORE-CREDIT"].includes(paymentMethod) || chargedSeparately) {
      setNfceStep(NFCE_STEPS.NFCE.value);
      return;
    }
    if (["LINK", "EXTERNAL-LINK"].includes(paymentMethod)) {
      if (automaticLink && isAutomaticLinkAtLocation) {
        setUniquePaymentStep(UNIQUE_PAYMENT_STEPS.LINK.value);
      } else {
        setNfceStep(NFCE_STEPS.NFCE.value);
      }
    }   

    if (["PIX"].includes(paymentMethod)) {
      return setUniquePaymentStep(UNIQUE_PAYMENT_STEPS.PIX.value);
    }

    /* TEF */
    if (
      paymentCardService === "softwareexpress" &&
      ["CREDIT", "DEBIT"].includes(paymentMethod)
    ) {
      // Using "location" here as a synonym for "physical store".
      const softwareexpressClientId =
        location?.payment?.card?.softwareexpress?.clientId ?? "";
      const locationCnpj = location?.cnpj ?? "";
      const cents = String(orderTotalInCents);
      const method = paymentMethod.toLowerCase();
      const installments = paymentInstallments;

      window.open(
        `iglu://receive-tef-payment/?${new URLSearchParams({
          // backend-origin
          borg:
            process.env.NODE_ENV === "development"
              ? window.location.origin
              : window.location.origin.replace(/app-/, ""),
          pkey: `${companyShop}:${shopifyOrderId}:${paymentId}`, // payment-document-key
          // ^ It doesn't feel safe to do this...
          clientId: softwareexpressClientId,
          clientCnpj: locationCnpj,
          staffId,
          posDeviceId,
          cents,
          method,
          ...(method === "credit" ? { installments } : {}),
          pwip: location?.printer?.wifi?.ip,
        }).toString()}`,
        "_blank",
      );
    }
    /* TEF */

    if (["DEBIT", "CREDIT"].includes(paymentMethod)) {
      setUniquePaymentStep(UNIQUE_PAYMENT_STEPS.CARD.value);
    }
  };

  const confirmModalText = isForShipping ? "Cobrar" : "Gerar NF";

  return (
    <>
      <SubmitButton />

      <ConfirmationModal
        isOpen={modalOpen}
        title={modalTitle}
        prompt={modalPrompt}
        confirmText={confirmModalText}
        onConfirm={handleGenerateNFC}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
      />
    </>
  );
}
