import styled from "styled-components";

export const OneButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;

  @media (min-width: 500px) {
    padding: 32px;
  }
`;

export const OneButtonPayment = styled.button`
  height: 120px;
  border-radius: 16px;
  width: 100%;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding: 24px;
  border: none;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  -webkit-tap-highlight-color: rgba(221, 221, 221, 0.4);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  &:hover {
    background-color: #dddddd;
  }
`;

export const OneButtonStandard = styled.button`
  height: 80px;
  border-radius: 16px;
  width: 100%;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding: 24px;
  border: none;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  -webkit-tap-highlight-color: rgba(221, 221, 221, 0.4);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  &:hover {
    background-color: #dddddd;
  }
`;
