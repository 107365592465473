import styled from "styled-components";

export const PaymentTypesCardContainer = styled.div`
  border: solid 1px #e0e0e0;
  border-radius: 12px;
  margin: 16px;
`;
export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 24px;

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin: 0;
  }
`;
