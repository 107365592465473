import useFirestoreDoc from "../hook/useFirestoreDoc.js";
import { useLocationStore } from "../store/useLocationStore.js";
import useDevice from "./useDevice.js";
import useShopifyShopUrl from "./useShopifyShopUrl.js";

export default function useLocation() {
  const shopifyShopUrl = useShopifyShopUrl();

  const device = useDevice();
  const locationId = `${device?.pos?.location?.id ?? ""}`;

  const { location, setLocation } = useLocationStore();

  useFirestoreDoc("posLocation", `${shopifyShopUrl}:${locationId}`, {
    next: setLocation,
  });

  return location;
}
