import styled from "styled-components";

export const SelectButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f7;
  border-radius: 10px;
  border: 1px solid #61726b;
  width: 90%;
  height: 40px;
  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  padding: 0 18px;
  background-color: #fdfdfd;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const SelectButtonIconContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;
