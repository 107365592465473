import { useEffect, useState } from "react";
import { usePaymentsStore } from "../store/usePaymentsStore";

function cashSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const cashPayments = paymentData.filter(
    (payment) => payment.method === "cash",
  );
  return cashPayments;
}

function creditSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const creditPayments = paymentData.filter(
    (payment) => payment.method === "credit",
  );

  return creditPayments;
}

function debitSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const debitPayments = paymentData.filter(
    (payment) => payment.method === "debit",
  );

  return debitPayments;
}

function pixSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const pixPayments = paymentData.filter((payment) => payment.method === "pix");

  return pixPayments;
}

function linkSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const linkPayments = paymentData.filter(
    (payment) => payment.method === "link",
  );

  return linkPayments;
}

function giftCardSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const giftCardPayments = paymentData.filter(
    (payment) => payment.method === "gift-card",
  );

  return giftCardPayments;
}

function storeCreditSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  const storeCreditPayments = paymentData.filter(
    (payment) => payment.method === "store-credit",
  );

  return storeCreditPayments;
}

function calculateTotalPaymentsMethod(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return {
      credit: 0,
      debit: 0,
      pix: 0,
      cash: 0,
      link: 0,
      "gift-card": 0,
      "store-credit": 0,
      total: 0,
    };
  }

  const methodsOptions = [
    "credit",
    "debit",
    "pix",
    "cash",
    "link",
    "gift-card",
    "store-credit",
    "total",
  ];

  const totalPaymentsMethod = {};

  methodsOptions.forEach((method) => {
    totalPaymentsMethod[method] = 0;
  });

  paymentData.forEach((payment) => {
    const method = payment.method;
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    if (methodsOptions.includes(method)) {
      totalPaymentsMethod[method] += amountCents / 100;
    }
  });

  return totalPaymentsMethod;
}

function countPaymentMethodOccurrences(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return {
      credit: 0,
      debit: 0,
      pix: 0,
      cash: 0,
      link: 0,
      "gift-card": 0,
      "store-credit": 0,
      total: 0,
    };
  }

  const methodsOptions = [
    "credit",
    "debit",
    "pix",
    "cash",
    "link",
    "gift-card",
    "store-credit",
  ];

  const totalPaymentsMethod = {};

  methodsOptions.forEach((method) => {
    totalPaymentsMethod[method] = 0;
  });

  paymentData.forEach((payment) => {
    const method = payment.method;
    if (methodsOptions.includes(method)) {
      totalPaymentsMethod[method] += 1;
    }
  });

  return totalPaymentsMethod;
}

function AllPaymentsSummary(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return [];
  }

  paymentData.forEach((payment) => {
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    payment.amountCents = amountCents;
  });
  const allPayments = paymentData;

  return allPayments;
}

function AverageValueOrder(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return 0;
  }

  const filteredPayments = paymentData.filter(
    (payment) =>
      payment.method !== "gift-card" && payment.method !== "store-credit",
  );

  const totalAmountCents = filteredPayments.reduce((accumulator, payment) => {
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    return accumulator + amountCents;
  }, 0);

  const averageValueOrder = totalAmountCents;

  return averageValueOrder;
}

function ConciliationPayment(paymentData) {
  if (!paymentData || paymentData.length === 0) {
    return 0;
  }

  const conciliation = paymentData.filter(
    (payment) => payment.wasConciliation === true,
  );

  const countConciliation = conciliation.length || 0;

  return countConciliation;
}

function usePaymentData() {
  const [data, setData] = useState({
    totalPaymentsMethod: {},
    totalCash: 0,
    totalCredit: 0,
    totalDebit: 0,
    totalPix: 0,
    totalLink: 0,
    totalGiftCard: 0,
    totalStoreCredit: 0,
    totalAllPayments: [],
    occurrences: {},
    averageValueOrder: 0,
    conciliation: 0,
  });
  const { payments } = usePaymentsStore();
  useEffect(() => {
    let filteredData = payments;

    const totalPaymentsMethod = calculateTotalPaymentsMethod(filteredData);
    const totalCash = cashSummary(filteredData);
    const totalCredit = creditSummary(filteredData);
    const totalDebit = debitSummary(filteredData);
    const totalPix = pixSummary(filteredData);
    const totalLink = linkSummary(filteredData);
    const totalGiftCard = giftCardSummary(filteredData);
    const totalStoreCredit = storeCreditSummary(filteredData);
    const totalAllPayments = AllPaymentsSummary(filteredData);
    const occurrences = countPaymentMethodOccurrences(filteredData);
    const averageValueOrder = AverageValueOrder(filteredData);
    const conciliation = ConciliationPayment(filteredData);

    const updatedData = {
      totalPaymentsMethod,
      totalCash,
      totalCredit,
      totalDebit,
      totalPix,
      totalLink,
      totalGiftCard,
      totalStoreCredit,
      totalAllPayments,
      occurrences,
      averageValueOrder,
      conciliation,
    };

    setData(updatedData);
  }, [payments]);

  return data;
}

export default usePaymentData;
