import styled from "styled-components";

export const TertiaryButton = styled.button`
  background-color: transparent;
  margin: 10px;
  color: black;
  border: none;
  margin-top: 8px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition:
    color 0.3s,
    text-decoration 0.3s;

  &:hover {
    color: black;
    text-decoration: underline;
  }
`;
