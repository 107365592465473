import styled from "styled-components";

export const ActionCardContainer = styled.div`
  padding: 8px;
  border: 1px solid #333;
  border-radius: 4px;

  display: flex;
`;

export const TitleContainer = styled.div``;

export const Title = styled.p`
  font-weight: bold;
  color: #333;
`;

export const ActionCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentContainer = styled.div`
  padding-right: 16px;
  margin-top: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 0 8px;
`;

export const Icon = styled.div``;
