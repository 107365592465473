import { useEffect, useState } from "react";
import { isShopifyPOS } from "@shopify/app-bridge-utils";
import { TitleBar as ShopifyTitleBar } from "@shopify/app-bridge-react";

const _isShopifyPOS = isShopifyPOS();

export default function TitleBar({ title, breadcrumbs }) {
  useState(() => (document.title = title));
  // ^ Execute a side-effect *once before rendering*
  useEffect(() => (document.title = title), [title]);

  if (_isShopifyPOS) {
    return (
      <ShopifyTitleBar
        title={title}
        breadcrumbs={breadcrumbs}
        primaryAction={null}
      />
    );
  } else {
    return null;
  }
}
