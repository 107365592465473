import { useCallback } from "react";

import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { PaymentMethodSelect as PaymentMethodSelectField } from "../../../components/PaymentMethodSelect/PaymentMethodSelect";
import { Container } from "./PaymentMethodSelect.styles";

export function PaymentMethodSelect() {
  const { paymentMethod, setPaymentMethod } = useUniquePaymentStore();

  const handleSelectChange = useCallback(
    (value) => setPaymentMethod(value),
    []
  );

  return (
    <Container>
      <PaymentMethodSelectField
        value={paymentMethod}
        onChange={handleSelectChange}
      />
    </Container>
  );
}
