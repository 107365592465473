import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h3`
  font-size: 21px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: grey;
`;

export const Subtitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: grey;
`;

export const QrCodeContainer = styled.div`
  display: Grid;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
