import React from "react";

const AlertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0C8.058 0 0 8.058 0 18C0 27.942 8.058 36 18 36C27.942 36 36 27.942 36 18C36 8.058 27.942 0 18 0ZM16.2 9C16.2 8.004 17.004 7.2 18 7.2C18.498 7.2 18.948 7.404 19.272 7.728C19.596 8.052 19.8 8.502 19.8 9V18C19.8 18.996 18.996 19.8 18 19.8C17.004 19.8 16.2 18.996 16.2 18V9ZM18 28.2C16.674 28.2 15.6 27.126 15.6 25.8C15.6 24.474 16.674 23.4 18 23.4C19.326 23.4 20.4 24.474 20.4 25.8C20.4 27.126 19.326 28.2 18 28.2Z"
      fill="black"
    />
  </svg>
);

export default AlertIcon;
