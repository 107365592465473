import styled from "styled-components";

export const Container = styled.div``;

export const ActionsContainer = styled.div`
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
