import styled from "styled-components";

export const Container = styled.div``;

export const ErrorFieldContainer = styled.div`
  display: flex;
  gap: 8px;

  margin-top: 8px;
`;

export const ErrorField = styled.p`
  font-size: 14px;
  color: #d72c0d;
`;

export const IconContainer = styled.div``;
