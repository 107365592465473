import { EmptyState } from "@shopify/polaris";
import { Component } from "react";
import mountain from "../../asset/mountain.svg";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    error.componentStack = info.componentStack.split(/\n/);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      // const Fallback = this.props.fallback;
      return (
        <>
          <EmptyState
            heading="Ops, ocorreu um erro inesperado... "
            image={mountain}
            imageContained
          >
            <p> Estamos tentando resolver, tente novamente mais tarde.</p>
            <p>
              {this.state.error.name} {this.state.error.message}
            </p>
          </EmptyState>
        </>
      );
    }

    return this.props.children;
  }
}
