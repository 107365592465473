import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  gap: 16px;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 16px;
  }
`;

export const Subtitle = styled.div`
  font-size: 13px;
  color: #284b54;
  padding: 20px;
  font-weight: bold;
`;

export const ContainerTableInformation = styled.div`
  width: 100%;
  cursor: pointer;
`;
