import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { SelectedAutomaticConciliation } from "../../../components/AutomaticConciliation/SelectedAutomaticConciliation";

export function SelectedAutomaticConciliationUnique() {
  const { setAutomaticConciliation, automaticConciliation } =
    useUniquePaymentStore();

  return (
    <SelectedAutomaticConciliation
      setAutomaticConciliation={setAutomaticConciliation}
      automaticConciliation={automaticConciliation}
    />
  );
}
