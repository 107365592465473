import { useNavigate as useAppBridgeNavigate } from "@shopify/app-bridge-react";
import {
  isShopifyEmbedded as isShopifyWeb,
  isShopifyPOS as isShopifyPos,
} from "@shopify/app-bridge-utils";
import { useNavigate as useBrowserRouterNavigate } from "react-router-dom";

export function useNavigate() {
  return isShopifyWeb() || isShopifyPos()
    ? useAppBridgeNavigate()
    : useBrowserRouterNavigate();
}
