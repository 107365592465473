import styled from "styled-components";

export const Container = styled.div`
  padding-top: 24px;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: grey;
  justify-content: center;
  text-align: center;
`;

export const PriceValue = styled.div`
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  padding-bottom: 8px;
`;
