import { Card, EmptyState, Page } from "@shopify/polaris";
import TitleBar from "../component/TitleBar/TitleBar.jsx";
import useHideLoadingIndicator from "../hook/useHideLoadingIndicator.js";
import notFoundSvg from "../asset/notFound.svg";

export default function NotFoundPage() {
  useHideLoadingIndicator();

  return (
    <Page>
      <TitleBar title="Página não Encontrada" />
      <Card>
        <Card.Section>
          <EmptyState
            heading="Nenhuma página encontrada neste endereço"
            image={notFoundSvg}
            imageContained
            fullWidth={false}
          >
            <p>Verifique o endereço e tente novamente.</p>
          </EmptyState>
        </Card.Section>
      </Card>
    </Page>
  );
}
