import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { ChooseAutomaticConciliation } from "../../../components/AutomaticConciliation/ChooseAutomaticConciliation";

export function ChooseAutomaticMultiplesConciliation() {
  const { setCurrentPayment, currentPayment } = useCurrentMultiplePayment();

  const handleChange = (automaticConciliation) => {
    setCurrentPayment({ ...currentPayment, automaticConciliation });
  };
  return (
    <ChooseAutomaticConciliation setAutomaticConciliation={handleChange} />
  );
}
