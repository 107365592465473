import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2b6ffb0c68df502f03e468a5a96f7d65@o4506282718986240.ingest.sentry.io/4506282719117312",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /iglu\.com.br\//],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import "./styles/global.css";

import ReactDOM from "react-dom";
import "./error/setupRuntime.js";
import App from "./component/App/App.jsx";

ReactDOM.render(<App />, document.getElementById("app"));
