import { useCallback } from "react";

import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { CardFlagSelect as CardFlagSelectInput } from "../../../components/CardFlagSelect/CardFlagSelect";

export function CardFlagSelect() {
  const {
    paymentMethod,
    chargedSeparately,
    cardFlag,
    setCardFlag,
    automaticConciliation,
  } = useUniquePaymentStore();

  const handleChange = useCallback((value) => setCardFlag(value), []);

  const shouldRenderThisField = () => {
    if (chargedSeparately && automaticConciliation) {
      return false;
    }
    if (
      (paymentMethod === "CREDIT" || paymentMethod === "DEBIT") &&
      chargedSeparately
    ) {
      return true;
    }

    return false;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  return <CardFlagSelectInput value={cardFlag} onChange={handleChange} />;
}
