import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Subtitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: grey;
  padding-bottom: 8px;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: grid;
  justify-content: center;
  gap: 8px;
`;

export const Wrapper = styled.div`
  padding: 5vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2vmin;
`;
