import { Button } from "@shopify/polaris";

import useDevice from "../../../../../../../../hook/useDevice";
import backend from "../../../../../../../../service/backend";
import { useConfigurationStore } from "../../../../../../../../store/useConfigurationStore";
import {
  getIsReadyToGenerateNfce,
  useCurrentMultiplePayment,
  useMultiplePaymentStore,
  useMultiplePaymentsUtils,
} from "../../../../../../../../store/useMultiplePaymentsStore";
import { useOrderStore } from "../../../../../../../../store/useOrderStore";
import { CardsSubmit } from "./CardsSubmit";
import { CashSubmit } from "./CashSubmit";
import { ExchangeSubmit } from "./ExchangeSubmit";
import { LinkSubmit } from "./LinkSubmit";
import { PixSubmit } from "./PixSubmit";

export function SubmitButton() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const { addDefaultPayment, payments } = useMultiplePaymentStore();

  const { remainAmount } = useMultiplePaymentsUtils();
  const device = useDevice();
  const staffId = device?.pos?.user?.id ? `${device.pos.user.id}` : null;

  const { shopifyOrderId, companyShop, posLocationId, posDeviceId } =
    useOrderStore();

  const { configuration } = useConfigurationStore();

  const paymentCardService =
    location?.payment?.card?.service ?? configuration?.payment?.card?.service;

  if (typeof paymentCardService !== "string")
    throw new Error(
      `Bad configuration \`.payment.card.service\` = "${paymentCardService}"`,
    );

  const handleExecutePaymentProcess = () => {
    setCurrentPayment({ status: "PENDING" });

    backend.executeProcess({
      type: "processPayment",
      input: {
        companyShop,
        shopifyOrderId,
        uuid: currentPayment.id,
        posLocationId,
        posDeviceId,
        staffId,
        payment: {
          method: currentPayment.paymentMethod.toLowerCase(),
          installments: currentPayment.paymentInstallments,
          amountCents: String(currentPayment.value),
          nsu: currentPayment.nsu,
          aut: currentPayment.aut,
          cardFlag: currentPayment.cardFlag,
          wasConciliation: currentPayment.chargedSeparately,
        },
      },
    });

    if (
      ["CREDIT", "DEBIT"].includes(currentPayment.paymentMethod) &&
      !currentPayment.chargedSeparately
    ) {
      return;
    }

    const isReadyToGenerateNfce = getIsReadyToGenerateNfce();
    if (!isReadyToGenerateNfce) {
      addDefaultPayment();
    }
  };

  const isDisabled =
    remainAmount < 0 ||
    currentPayment.value === 0 ||
    payments.some((payment) => payment.status === "PENDING");

  if (["CREDIT", "DEBIT"].includes(currentPayment.paymentMethod)) {
    return (
      <CardsSubmit
        disabled={isDisabled}
        onClick={handleExecutePaymentProcess}
      />
    );
  }

  if (currentPayment.paymentMethod === "CASH") {
    return (
      <CashSubmit disabled={isDisabled} onClick={handleExecutePaymentProcess} />
    );
  }

  if (currentPayment.paymentMethod === "STORE-CREDIT") {
    return (
      <ExchangeSubmit
        disabled={isDisabled}
        onClick={handleExecutePaymentProcess}
      />
    );
  }

  if (currentPayment.paymentMethod === "PIX") {
    return (
      <PixSubmit disabled={isDisabled} onClick={handleExecutePaymentProcess} />
    );
  }

  if (currentPayment.paymentMethod === "LINK") {
    return (
      <LinkSubmit disabled={isDisabled} onClick={handleExecutePaymentProcess} />
    );
  }

  return (
    <Button primary disabled>
      Continuar
    </Button>
  );
}
