import { CPFField as CPFFieldInput } from "../../../../../../component/input/CPFField/CPFField";
import { useOrderStore } from "../../../../../../store/useOrderStore";
import { Container } from "./CPFField.styles";

export function CPFField() {
  const { cpf, setCpf } = useOrderStore();

  const handleChange = (value) => setCpf(value);

  return (
    <Container>
      <CPFFieldInput value={cpf} onChange={handleChange} />
    </Container>
  );
}
