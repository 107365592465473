import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { ChooseAutomaticConciliation } from "../../../components/AutomaticConciliation/ChooseAutomaticConciliation";

export function ChooseAutomaticUniqueConciliation() {
  const { setAutomaticConciliation } = useUniquePaymentStore();
  return (
    <ChooseAutomaticConciliation
      setAutomaticConciliation={setAutomaticConciliation}
    />
  );
}
