import {
  Card,
  Filters,
  ResourceList,
  ResourceItem,
  TextContainer,
  Spinner,
  Stack,
  Heading,
} from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format as cnpjFormatter } from "../../helper/cnpj.js";
import useFirestoreQuery from "../../hook/useFirestoreQuery.js";
import backend from "../../service/backend.js";

export default function LocationListCard({ shopifyShopUrl }) {
  const navigate = useNavigate();

  const locationListQuery = useFirestoreQuery("posLocation", [
    "companyShop",
    "==",
    shopifyShopUrl,
  ]);

  useEffect(async () => {
    if (locationListQuery.data?.length === 0) {
      await backend.executeProcess(
        {
          type: "config/importLocationList",
          input: { companyShop: shopifyShopUrl },
        },
        { unique: true },
      );
    }
  }, [locationListQuery.data]);

  const [filterValue, setFilterValue] = useState("");
  const handleClearFilterValue = useCallback(() => setFilterValue(""), []);

  const resourceName = {
    singular: "local",
    plural: "locais",
  };
  const renderItem = useCallback(
    ({ id, name, cnpj }) => {
      // POSLocationItem
      return (
        <ResourceItem onClick={() => navigate(`/location/${id}`)}>
          <Heading element="h3">{name}</Heading>
          {typeof cnpj === "string" ? (
            <p>
              <div>{cnpjFormatter(cnpj)}</div>
            </p>
          ) : (
            <p style={{ color: "#f00" }}>Sem CNPJ configurado</p>
          )}
        </ResourceItem>
      );
    },
    [navigate],
  );

  const filteredList = useMemo(() => {
    if (locationListQuery.didSucceed) {
      return locationListQuery.data
        .sort((l0, l1) => l0.name.localeCompare(l1.name))
        .filter((location) =>
          location.name.toLowerCase().includes(filterValue.toLowerCase()),
        );
    } else {
      return [];
    }
  }, [locationListQuery, filterValue]);

  if (locationListQuery.isLoading) {
    return (
      <Card sectioned title="Locais">
        <Stack distribution="center" alignment="center">
          <Stack.Item>
            <Spinner />
          </Stack.Item>
        </Stack>
      </Card>
    );
  } else if (locationListQuery.didFail) {
    return (
      <Card sectioned title="Locais">
        <TextContainer>
          <p style={{ color: "#f00", textAlign: "center" }}>
            {locationListQuery.error?.message}
          </p>
        </TextContainer>
      </Card>
    );
  } else {
    return (
      <Card sectioned title="Locais">
        <ResourceList
          resourceName={resourceName}
          items={filteredList}
          renderItem={renderItem}
          filterControl={
            <Filters
              queryValue={filterValue}
              filters={[]}
              onQueryChange={setFilterValue}
              onQueryClear={handleClearFilterValue}
              onClearAll={handleClearFilterValue}
            />
          }
        />
      </Card>
    );
  }
}
