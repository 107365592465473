import { Icon } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";

import { Container, Title, GroupedForwardButton, Button } from "./styles";

function ForwardButton({ text, onClick }) {
  return (
    <Button onClick={onClick}>
      {text}
      <div>
        <Icon source={ChevronRightMinor} color="subdued" />
      </div>
    </Button>
  );
}

export function GroupedButton({ title, options = [] }) {
  return (
    <Container>
      {title && <Title>{title}</Title>}

      <GroupedForwardButton>
        {options.map((option, index) => (
          <ForwardButton
            key={option.id ?? option.text ?? index}
            text={option.text}
            onClick={option.onClick}
          />
        ))}
      </GroupedForwardButton>
    </Container>
  );
}
