import { Icon } from "@shopify/polaris";
import {
  CardInformationContainer,
  CardChevron,
  CardInformationText,
  CardInformationButtonDescription,
  CardInformationGrid,
} from "./CardInformation.styles";

export function CardInformation({ title, text, onClick, icon }) {
  return (
    <CardInformationContainer onClick={onClick}>
      <CardInformationText>{text}</CardInformationText>
      <CardInformationGrid>
        <CardInformationButtonDescription>
          {title}
          {icon && (
            <CardChevron>
              <Icon source={icon} />
            </CardChevron>
          )}
        </CardInformationButtonDescription>
      </CardInformationGrid>
    </CardInformationContainer>
  );
}
