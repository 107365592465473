import {
  Card,
  Heading,
  IndexTable,
  Stack,
  Spinner,
  Tag,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { useCallback, useState } from "react";
import useDevice from "../../hook/useDevice.js";
import useFirestoreQuery from "../../hook/useFirestoreQuery.js";
import backend from "../../service/backend.js";

const resourceName = {
  singular: "pagamento",
  plural: "pagamentos",
};

export default function PendingPaymentListCard({
  shopifyShopUrl,
  posLocation,
}) {
  const device = useDevice();

  const pendingPaymentListQuery = useFirestoreQuery(
    "payment",
    ["companyShop", "==", shopifyShopUrl],
    ["posLocationId", "==", posLocation.id],
    ["status", "==", "pending"],
  );

  const pendingPaymentList = pendingPaymentListQuery.data ?? [];

  const [selectedPaymentIdList, setSelectedPaymentIdList] = useState([]);
  const handleSelectionChange = useCallback(
    (quantity, mode, paymentShopifyOrderId) => {
      // console.log(quantity, mode, paymentShopifyOrderId);
      // ^ TO-DO: debug dangling cancel button on mobile.
      if (quantity === "page") {
        return setSelectedPaymentIdList(
          mode
            ? pendingPaymentList.map(({ shopifyOrderId }) => shopifyOrderId)
            : [],
        );
      }

      if (mode) {
        return setSelectedPaymentIdList((prevState) => [
          ...prevState,
          paymentShopifyOrderId,
        ]);
      }

      return setSelectedPaymentIdList((prevState) =>
        prevState.filter(
          (selectedItem) => selectedItem !== paymentShopifyOrderId,
        ),
      );
    },
    [setSelectedPaymentIdList, pendingPaymentList],
  );

  const cancelPayment = useCallback(async () => {
    const selectedPayment = pendingPaymentListQuery.data.find(
      (payment) => payment.shopifyOrderId === selectedPaymentIdList[0],
    );
    await backend.executeProcess(
      {
        type: "cancelPayment",
        input: {
          companyShop: shopifyShopUrl,
          shopifyOrderId: selectedPayment.shopifyOrderId,
          posLocationId: selectedPayment.posLocationId,
          paymentMethod: selectedPayment.method,
          paymentPSP: selectedPayment.psp,
          paymentId: selectedPayment?.id || null,
          pagarmeOrderId: selectedPayment.pagarmeOrderId ?? null,
          mercadopagoPaymentIntentIds:
            selectedPayment.mercadopagoPaymentIntentIds ?? null,
          tecnospeedPixPaymentId:
            selectedPayment.tecnospeedPixPaymentId ?? null,
        },
      },
      { unique: true },
    );
  }, [
    shopifyShopUrl,
    posLocation.id,
    pendingPaymentListQuery.data,
    selectedPaymentIdList,
  ]);

  if (pendingPaymentListQuery.isLoading) {
    return (
      <Card sectioned>
        <Heading>Pagamentos pendentes</Heading>
        <Stack distribution="center" alignment="center">
          <Stack.Item>
            <Spinner />
          </Stack.Item>
        </Stack>
      </Card>
    );
  } else if (pendingPaymentListQuery.didFail) {
    return (
      <Card sectioned>
        <Heading>Pagamentos pendentes</Heading>
        <TextContainer>
          <p style={{ color: "#f00", textAlign: "center" }}>
            {pendingPaymentListQuery.error.message}
          </p>
        </TextContainer>
      </Card>
    );
  } else {
    return (
      <Card sectioned>
        <Heading>Pagamentos pendentes</Heading>
        <IndexTable
          loading={pendingPaymentListQuery.isLoading}
          condensed={device.type === "shopifyPos"}
          resourceName={resourceName}
          items={pendingPaymentList}
          itemCount={pendingPaymentList.length}
          selectedItemsCount={selectedPaymentIdList.length}
          onSelectionChange={handleSelectionChange}
          bulkActions={[
            {
              content: "Cancelar",
              disabled: selectedPaymentIdList.length !== 1,
              // ^ "You may only cancel one payment at a time."
              onAction: () => cancelPayment(),
            },
          ]}
          headings={[{ title: "Pedido" }, { title: "Maquininhas" }]}
        >
          {pendingPaymentList.map((pendingPaymentItem, index) => (
            <PendingPaymentItem
              {...pendingPaymentItem}
              key={`${pendingPaymentItem.posLocationId}:pagarme:${pendingPaymentItem.pagarmeOrderId}`}
              index={index}
              isSelected={selectedPaymentIdList.includes(
                pendingPaymentItem.shopifyOrderId,
              )}
            />
          ))}
        </IndexTable>
      </Card>
    );
  }
}

function PendingPaymentItem({
  index,
  isSelected,
  shopifyOrderName,
  shopifyOrderId,
  pagarmeDeviceIds,
}) {
  return (
    <IndexTable.Row id={shopifyOrderId} position={index} selected={isSelected}>
      <IndexTable.Cell>
        <TextStyle variation="strong">{shopifyOrderName}</TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Stack>
          {(pagarmeDeviceIds ?? []).map((pagarmeDeviceId) => (
            <Tag key={pagarmeDeviceId}>{pagarmeDeviceId}</Tag>
          ))}
        </Stack>
      </IndexTable.Cell>
    </IndexTable.Row>
  );
}
