import { useAppBridge } from "@shopify/app-bridge-react";
import {
  Features,
  Group,
  Scanner,
  Cart,
  Toast,
} from "@shopify/app-bridge/actions";

import { useAuthenticatedFetch } from "../hook/useAuthenticatedFetch";
import useShopifyShopUrl from "../hook/useShopifyShopUrl";

export default function Barcode() {
  const fetch = useAuthenticatedFetch();
  const appBridge = useAppBridge();
  const companyShop = useShopifyShopUrl();
  appBridge.featuresAvailable().then((a) => {
    console.log(a);
    console.log("aaa---aaa");
  });
  const scanner = Scanner.create(appBridge);
  const features = Features.create(appBridge);
  //   const pos = Pos.create(appBridge);
  const cart = Cart.create(appBridge);
  const toastNoticeSuccess = Toast.create(appBridge, {
    message: "Produto adicionado",
    duration: 5000,
  });
  const toastNoticeError = Toast.create(appBridge, {
    message: "Não foi possivel adicionar o produto",
    duration: 5000,
  });
  // Subscribe to the update action (triggered when the permission dialog is interacted with)
  features.subscribe(Features.Action.REQUEST_UPDATE, function (payload) {
    if (payload.feature[Scanner.Action.OPEN_CAMERA]) {
      const available = payload.feature[Scanner.Action.OPEN_CAMERA].Dispatch;
      // If the Camera Scanner actions were enabled, open a Scanner
      if (available) {
        scanner.dispatch(Scanner.Action.OPEN_CAMERA);
      }
    }
  });
  // Dispatch an action to request access to Scanner actions
  features.dispatch(Features.Action.REQUEST, {
    feature: Group.Scanner,
    action: Scanner.Action.OPEN_CAMERA,
  });
  features.dispatch(Features.Action.REQUEST, {
    feature: Group.Cart,
    action: Cart.Action.ADD_LINE_ITEM,
  });
  cart.subscribe(Cart.Action.UPDATE, function () {
    toastNoticeSuccess.dispatch(Toast.Action.SHOW);
    scanner.dispatch(Scanner.Action.OPEN_CAMERA);
  });
  appBridge.error(function (data) {
    console.info("[client] Error received: ", data);
  });
  appBridge.subscribe(async function (payload) {
    if (
      payload.group === "Scanner" &&
      payload.type === "APP::SCANNER::CAPTURE"
    ) {
      const barcode = payload.payload.data.scanData;

      const response = await fetch(
        `${process.env.HOST}/api/find-product/${companyShop}/${barcode.replace(
          / /g,
          "",
        )}`,
      );

      const jsonResponse = await response.json();
      if (jsonResponse.success) {
        const globalVariantId = jsonResponse.variantId;
        const split = globalVariantId.split("/");
        const variantId = split[split.length - 1];
        cart.dispatch(Cart.Action.ADD_LINE_ITEM, {
          data: {
            variantId: parseInt(variantId),
            quantity: 1,
          },
        });
      }
      toastNoticeError.dispatch(Toast.Action.SHOW);
    }
  });
  return <div></div>;
}
