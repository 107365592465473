import { serialize as serializeError, UnknownError } from "@iglu/common/error";
import useShopifyOrderId from "../../hook/useShopifyOrderId";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import backend from "../../service/backend";

const shopifyShopUrl = useShopifyShopUrl();
const shopifyOrderId = useShopifyOrderId();

export default async function handleError(eventOrError) {
  let error = null;

  if (eventOrError instanceof Event) {
    // JS global error event
    const event = eventOrError;
    event.stopPropagation();
    error = event.error;
  } else if (eventOrError instanceof Error) {
    // React render error
    error = eventOrError;
  } else {
    let serializedInput = null;
    try {
      serializedInput = JSON.stringify(eventOrError);
    } catch (_) {
      /* noop; */
    }
    error = new UnknownError(`\`handleError\` received \`${serializedInput}\``);
  }

  try {
    await backend.executeProcess(
      {
        type: "analytics/uploadFrontendError",
        input: {
          companyShop: shopifyShopUrl,
          shopifyOrderId,
          error: serializeError(error),
        },
      },
      { unique: true },
    );
  } catch (error) {
    console.error(error);
    // ^ This is a 100% client-side/offline error that we have no visibility of.
  }

  if (eventOrError instanceof Event) {
    return false;
  }
}
