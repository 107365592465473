import { Title } from "../Title/Title";
import { ContinueButton } from "./components/ContinueButton/ContinueButton";
import { CPFField } from "./components/CPFField/CPFField";
// import { OrderTypeRadioGroup } from "./components/OrderTypeRadioGroup/OrderTypeRadioGroup";
import { ActionsContainer, Container, Description } from "./styles";

export function InformationPanel() {
  return (
    <Container>
      <Title>Pagamento</Title>

      <Description>Informações Adicionais</Description>

      <CPFField />

      {/* <OrderTypeRadioGroup /> */}

      <ActionsContainer>
        <ContinueButton />
      </ActionsContainer>
    </Container>
  );
}
