import styled from "styled-components";

export const Container = styled.div``;

export const ActionsContainer = styled.div`
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #6d7175;
`;
