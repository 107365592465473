import { useConfigurationStore } from "../../../../../../../store/useConfigurationStore";
import { useUniquePaymentStore } from "../../../../../../../store/useUniquePaymentStore";
import { ChooseAutomaticUniqueConciliation } from "./ChooseAutomaticUniqueConciliation";
import { SelectedAutomaticConciliationUnique } from "./SelectedAutomaticConciliationUnique";

export function AutomaticConciliation() {
  const { paymentMethod, chargedSeparately, automaticConciliation } =
    useUniquePaymentStore();

  const { configuration } = useConfigurationStore();
  const paymentCardService = configuration?.payment?.card?.service;

  const shouldRenderThisField = () => {
    if (paymentCardService !== "pagarme") {
      return false;
    }
    if (
      (paymentMethod === "DEBIT" || paymentMethod === "CREDIT") &&
      chargedSeparately
    ) {
      return true;
    }

    return false;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  if (automaticConciliation) {
    return <SelectedAutomaticConciliationUnique />;
  }

  return <ChooseAutomaticUniqueConciliation />;
}
