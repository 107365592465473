import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const NfProgressStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ProgressStatusText = styled.p`
  font-weight: bold;
  color: #6d7175;
  margin-left: 8px;
  margin-top: 16px;
  text-align: center;
`;

export const ProgressStatusIcon = styled.div`
  padding: 8px;
`;

export const TitleFinishedPayment = styled.h3`
  font-size: 18px;
  padding: 8px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: grey;
`;
