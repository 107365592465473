import { Card, Stack, Button } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";

const LocationReport = () => {
  const navigate = useNavigate();

  const renderPixReport = () => {
    navigate("/pix-analytics");
  };

  return (
    <>
      <Card sectioned title="Relatórios">
        <Stack distribution="center" alignment="center">
          <Stack.Item>
            <Button onClick={renderPixReport}>Abrir Relatório Pix</Button>
          </Stack.Item>
        </Stack>
      </Card>
    </>
  );
};

export default LocationReport;
