import styled from "styled-components";

export const ChooseAutomaticConciliationContainer = styled.div`
  margin-top: 16px;
`;

export const ModalSectionContainer = styled.div`
  -webkit-tap-highlight-color: transparent;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;
