import useHideLoadingIndicator from "../../hook/useHideLoadingIndicator";
import UnexpectedErrorPage from "../../page/unexpectedErrorPage/UnexpectedErrorPage";

export default function ErrorFallback({ error }) {
  useHideLoadingIndicator();

  // <BrowserRouterProvider>
  //   <AppBridgeProvider>
  //     <PolarisProvider>
  // ^ Needs all these to function
  return <UnexpectedErrorPage error={error} />;
}
