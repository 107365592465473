import React, { useEffect, useState } from "react";

import R$ from "../../../../../../component/FormatCurrency/R$.jsx";
import Loading from "../../../../../../component/Loading/Loading.jsx";
import useFirestoreDoc from "../../../../../../hook/useFirestoreDoc.js";
import backend from "../../../../../../service/backend.js";
import { useOrderStore } from "../../../../../../store/useOrderStore";
import CancelPaymentButton from "../../components/CancelPaymentButton/CancelPaymentButton.jsx";
import {
  Price,
  PriceValue,
} from "../CancelPaymentButton/CancelPaymentButton.styles.js";
import {
  Container,
  LoadingContainer,
  SubtitleMachinePayment,
} from "./MachinePayment.styles";

export function MachinePayment({
  paymentId,
  paymentMethod,
  value,
  onPaid,
  onCancel,
}) {
  const { shopifyOrderId, companyShop, posLocationId } = useOrderStore();

  const [cancelClicked, setCancelClicked] = useState(false);

  const paymentSnapShot = useFirestoreDoc(
    "payment",
    `${companyShop}:${shopifyOrderId}:${paymentId}`,
  );

  useEffect(() => {
    switch (paymentSnapShot?.data?.status) {
      case "settled":
        onPaid?.();
        break;

      case "canceled":
      case "error":
        onCancel();
    }
  }, [paymentSnapShot?.data?.status]);

  const handleCancelPayment = () => {
    setCancelClicked(true);

    return backend.executeProcess(
      {
        type: "cancelPayment",
        input: {
          companyShop,
          shopifyOrderId,
          posLocationId,
          paymentMethod: paymentMethod.toLowerCase(),
          paymentId: paymentId,
          pagarmeOrderId: null,
          mercadopagoPaymentIntentIds: null,
          tecnospeedPixPaymentId: null,
        },
      },
      { unique: true },
    );
  };

  const getIsWaitingPayment = () => {
    return paymentSnapShot?.data?.status === "pending";
  };

  const getIsPaymentInProgress = () => {
    switch (paymentSnapShot?.data?.status) {
      case "settled":
      case "pending":
      case "canceled":
      case "error":
        return false;
      default:
        return true;
    }
  };

  const isPaymentInProgress = getIsPaymentInProgress();
  const isWaitingPayment = getIsWaitingPayment();

  return (
    <Container>
      {isPaymentInProgress && (
        <>
          <SubtitleMachinePayment>
            Enviando pagamento para a maquininha...
          </SubtitleMachinePayment>

          <LoadingContainer>
            <Loading />
          </LoadingContainer>

          <Price>Valor</Price>
          <PriceValue>
            <R$>{value / 100}</R$>
          </PriceValue>
        </>
      )}

      {isWaitingPayment && (
        <>
          <SubtitleMachinePayment>
            Inserir ou aproximar o cartão na maquininha e efetuar o pagamento.
            Aguardando finalização do pagamento...
          </SubtitleMachinePayment>

          <CancelPaymentButton
            disabled={cancelClicked}
            loading={cancelClicked}
            value={value}
            onCancel={handleCancelPayment}
            modalPrompt="Após confirmar, o pagamento será cancelado. Não se esqueça de apertar o botão X em vermelho na maquininha."
          />
        </>
      )}
    </Container>
  );
}
