import React from "react";

import { useOrderStore } from "../../../../../../store/useOrderStore.js";
import {
  NFCE_STEPS,
  UNIQUE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore.js";
import { useUniquePaymentStore } from "../../../../../../store/useUniquePaymentStore.js";
import { MachinePayment as MachinePaymentPage } from "../../components/MachinePayment/MachinePayment.jsx";

export function MachinePayment() {
  const {
    id: paymentId,
    paymentMethod,
    setPaymentId,
  } = useUniquePaymentStore();

  const { orderTotalInCents } = useOrderStore();

  const { setNfceStep, setUniquePaymentStep } = usePaymentFlowStore();

  const handlePaid = () => {
    setNfceStep(NFCE_STEPS.NFCE.value);
  };

  const handleCancelPayment = () => {
    setPaymentId();
    setUniquePaymentStep(UNIQUE_PAYMENT_STEPS.PAYMENT_FORM.value);
  };

  return (
    <MachinePaymentPage
      paymentId={paymentId}
      paymentMethod={paymentMethod}
      value={orderTotalInCents}
      onPaid={handlePaid}
      onCancel={handleCancelPayment}
    />
  );
}
