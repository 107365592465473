import styled from "styled-components";

export const SelectButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f7;
  border-radius: 10px;
  border: 1px solid #61726b;
  width: 90%;
  height: 40px;
  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  padding: 0 18px;
  background-color: #fdfdfd;
  position: relative;
  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const CalendarContainer = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;

  @media (min-width: 450px) {
    top: 35vh;
  }

  @media (min-width: 768px) {
    top: 35vh;
  }

  @media (min-width: 1024px) {
    top: 30vh;
    left: 30%;
  }
`;
export const SelectButtonIconContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const BoldText = styled.strong`
  font-weight: bold;
`;

export const ButtonSelectDate = styled.button`
  border: none;
  cursor: pointer;
  padding: 15px;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #036ddc;
`;
