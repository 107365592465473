import { Button } from "@shopify/polaris";
import { MobileBackArrowMajor } from "@shopify/polaris-icons";
import React from "react";

import { useNavigate } from "../../hook/useNavigate";
import { ContainerButton } from "./BackButton.styles";

export default function BackButton({ backToPage }) {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(backToPage);

  return (
    <ContainerButton>
      <Button onClick={handleGoBack} icon={MobileBackArrowMajor} />
    </ContainerButton>
  );
}
