import { TabPanel } from "../../../../../component/Tabs/TabPanel";
import {
  MULTIPLE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../store/usePaymentFlowStore";
import { MachinePayment } from "./MachinePayment/MachinePayment";
import { Container } from "./MultiplePayments.styles";
import { PaymentList } from "./PaymentList/PaymentList";
import { PixPayment } from "./PixPayment/PixPayment";

export function MultiplePayments() {
  const { multiplePaymentsStep } = usePaymentFlowStore();

  return (
    <Container>
      <TabPanel
        value={multiplePaymentsStep}
        index={MULTIPLE_PAYMENT_STEPS.PAYMENT_FORM_LIST.value}
      >
        <PaymentList />
      </TabPanel>
      <TabPanel
        value={multiplePaymentsStep}
        index={MULTIPLE_PAYMENT_STEPS.CARD.value}
      >
        <MachinePayment />
      </TabPanel>
      <TabPanel
        value={multiplePaymentsStep}
        index={MULTIPLE_PAYMENT_STEPS.PIX.value}
      >
        <PixPayment />
      </TabPanel>
    </Container>
  );
}
