import { MoneyField } from "../../../../../../../component/input/MoneyField/MoneyField";
import {
  useCurrentMultiplePayment,
  useMultiplePaymentsUtils,
} from "../../../../../../../store/useMultiplePaymentsStore";

const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function AmountPaidField() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const { remainAmount } = useMultiplePaymentsUtils();

  const formatCurrency = (value) => {
    return moneyFormatter.format(value);
  };

  const getLabelDescription = () => {
    if (remainAmount < 0) {
      const positiveRemainAmount = -remainAmount;
      const moneyInReal = positiveRemainAmount / 100;
      const money = formatCurrency(moneyInReal);
      return `(${money} excedentes)`;
    }

    const moneyInReal = remainAmount / 100;
    const money = formatCurrency(moneyInReal);

    return `(Restam ${money})`;
  };

  const getHasError = () => {
    return remainAmount < 0;
  };

  const hasError = getHasError();
  const labelDescription = getLabelDescription();

  const handleChange = (value) => setCurrentPayment({ value });

  return (
    <MoneyField
      label="Quantia"
      value={currentPayment.value}
      onChange={handleChange}
      labelDescription={labelDescription}
      error={hasError}
    />
  );
}
