import { Modal, Stack } from "@shopify/polaris";

ConfirmationModal.defaultProps = {
  title: "Confirmação",
  prompt: "Deseja realmente executar essa ação?",
  confirmText: "Confirmar",
  cancelText: "Cancelar",
  small: false,
  destructive: false,
};

export default function ConfirmationModal({
  isOpen,
  title,
  prompt,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  onCancel,
  small,
  destructive,
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={title}
      small={false}
      fullScreen={false}
      primaryAction={{
        content: confirmText,
        onAction: () => {
          onClose();
          onConfirm();
        },
        destructive,
      }}
      secondaryActions={[
        {
          content: cancelText,
          onAction: onCancel,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <p>{prompt}</p>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
