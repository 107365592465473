import { Button } from "@shopify/polaris";
import { ExternalMinor, LinkMinor } from "@shopify/polaris-icons";
import React from "react";
import CheckIcon from "../../../../asset/check";
import {
  PaymentStatus,
  WaitingPaymentContainer,
} from "./PaymentLinkProgressStatus.styles";

export function PaymentLinkProgressStatus({ handleCopy, handleShare }) {
  return (
    <WaitingPaymentContainer>
      <CheckIcon />
      <PaymentStatus>Link criado com sucesso</PaymentStatus>
      <Button fullWidth icon={LinkMinor} onClick={handleCopy}>
        Copiar link
      </Button>
      <Button fullWidth icon={ExternalMinor} onClick={handleShare}>
        Compartilhar link
      </Button>
    </WaitingPaymentContainer>
  );
}
