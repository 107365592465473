import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";
import backend from "../service/backend.js";

export default function useShopifyOrder({ id: shopifyOrderId }) {
  const companyShop = useShopifyShopUrl();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(async () => {
    try {
      const response = await backend.executeProcess(
        {
          type: "shopify/importShopifyOrder",
          input: { companyShop, shopifyOrderId, uuid: uuidv4() },
        },
        { unique: true }
      );
      setData(response);
    } catch (e) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const didFail = !!error;

  return {
    isLoading,
    didFail,
    error,
    data,
  };
}
