import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingIcon = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

export const PaymentStatus = styled.h2`
  font-size: 16px;
  color: #333;
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 0 12px;
  white-space: nowrap;
  font-weight: bold;
`;

export const PaymentStatusDone = styled.h2`
  font-size: 16px;
  color: #333;
  margin-top: 16px;
  padding: 0 12px;
  white-space: nowrap;
  font-weight: bold;
`;

export const ProgressStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin: 24px;
`;

export const LoadingStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
`;

export const WaitingPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 24px;
`;

export const SubtitlePayment = styled.p`
  font-size: 14px;
  color: #808080;
  text-align: center;
`;
