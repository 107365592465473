import { useAppBridge as useShopifyAppBridge } from "@shopify/app-bridge-react";
import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import { Share } from "@shopify/app-bridge/actions";
import { Button, RangeSlider } from "@shopify/polaris";
import { ShareMinor } from "@shopify/polaris-icons";
import { useState } from "react";
import {
  ButtonContainer,
  Container,
  Subtitle,
  Wrapper,
} from "./QRCodeModal.styles";

const _isShopifyPos = isShopifyPos();

let useAppBridge = null;
if (_isShopifyPos) {
  useAppBridge = useShopifyAppBridge;
} else {
  useAppBridge = () => null;
}

export default function QRCodeModal({ isOpen, qrCode, emvCode }) {
  const appBridge = useAppBridge();

  const handleShare = () => {
    if (appBridge) {
      Share.create(appBridge).dispatch(Share.Action.SHOW, {
        text: emvCode,
      });
    } else if (navigator.share) {
      navigator.share({ text: emvCode });
      // ^ TO-DO: test this
    } else {
      console.error("Couldn't share");
    }
  };

  const [size, setSize] = useState(45);

  if (!isOpen) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Subtitle>QR Code expira em 12h00</Subtitle>

        <RangeSlider
          label="Tamanho"
          output={true}
          value={size}
          min={45}
          max={95}
          onChange={setSize}
        />
        <img
          src={qrCode}
          style={{
            display: "block",
            width: `${size - 5}vmin`,
            height: `${size - 5}vmin`,
            alignSelf: "center",
          }}
          alt="QR-Code PIX"
        />
        <ButtonContainer>
          <Button fullWidth onClick={handleShare} icon={ShareMinor}>
            Compartilhar
          </Button>
        </ButtonContainer>
      </Wrapper>
    </Container>
  );
}
