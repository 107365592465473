import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { CardFlagSelect as CardFlagSelectInput } from "../../../components/CardFlagSelect/CardFlagSelect";

export function CardFlagSelect() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const shouldRenderThisField = () => {
    if (
      currentPayment.chargedSeparately &&
      currentPayment.automaticConciliation
    ) {
      return false;
    }
    if (
      (currentPayment.paymentMethod === "CREDIT" ||
        currentPayment.paymentMethod === "DEBIT") &&
      currentPayment.chargedSeparately
    ) {
      return true;
    }

    return false;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  const handleChange = (cardFlag) => setCurrentPayment({ cardFlag });

  return (
    <CardFlagSelectInput
      value={currentPayment.cardFlag}
      onChange={handleChange}
    />
  );
}
