const ClockIcon = ({ width = 40, height = 40, fill = "#202222" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill={fill}
  >
    <path
      d="M20 2C10.0748 2 2 10.0748 2 20C2 29.9252 10.0748 38 20 38C29.9252 38 38 29.9252 38 20C38 10.0748 29.9252 2 20 2ZM20 34.4C12.0602 34.4 5.6 27.9398 5.6 20C5.6 12.0602 12.0602 5.6 20 5.6C27.9398 5.6 34.4 12.0602 34.4 20C34.4 27.9398 27.9398 34.4 20 34.4ZM21.8 19.2548V11C21.8 10.5226 21.6104 10.0648 21.2728 9.72721C20.9352 9.38964 20.4774 9.2 20 9.2C19.5226 9.2 19.0648 9.38964 18.7272 9.72721C18.3896 10.0648 18.2 10.5226 18.2 11V20C18.2 20.4788 18.389 20.936 18.7274 21.2726L24.1274 26.6726C24.2942 26.8403 24.4925 26.9734 24.7109 27.0643C24.9293 27.1551 25.1635 27.2019 25.4 27.2019C25.6365 27.2019 25.8707 27.1551 26.0891 27.0643C26.3075 26.9734 26.5058 26.8403 26.6726 26.6726C26.8399 26.5056 26.9726 26.3072 27.0632 26.0888C27.1537 25.8705 27.2004 25.6364 27.2004 25.4C27.2004 25.1636 27.1537 24.9295 27.0632 24.7112C26.9726 24.4928 26.8399 24.2944 26.6726 24.1274L21.8 19.2548Z"
      fill={fill}
    />
  </svg>
);

export default ClockIcon;
