import getMaxInstallments from "../../../../../../../helper/getMaxInstallments";
import {
  useCurrentMultiplePayment,
  useMultiplePaymentStore,
} from "../../../../../../../store/useMultiplePaymentsStore";
import { InstallmentSelect as InstallmentSelectInput } from "../../../components/InstallmentSelect/InstallmentSelect";
import { Container } from "./InstallmentSelect.styles";

export function InstallmentSelect() {
  const { currentPayment, setCurrentPayment } = useCurrentMultiplePayment();

  const { installmentsConfig } = useMultiplePaymentStore();

  if (currentPayment.paymentMethod !== "CREDIT") {
    return null;
  }

  const isDisabled = currentPayment.value === 0;

  const installmentOptions = getMaxInstallments({
    installments: installmentsConfig,
    totalAmount: currentPayment.value / 100,
  });

  const handleChange = (paymentInstallments) =>
    setCurrentPayment({ paymentInstallments });

  return (
    <Container>
      <InstallmentSelectInput
        options={installmentOptions}
        value={currentPayment.paymentInstallments}
        onChange={handleChange}
        disabled={isDisabled}
      />
    </Container>
  );
}
