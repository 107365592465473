import { Button, Modal, RadioButton } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";

import Loading from "../../../../../../component/Loading/Loading";
import backend from "../../../../../../service/backend";
import { useOrderStore } from "../../../../../../store/useOrderStore";
import {
  LoadingContainer,
  ChooseAutomaticConciliationContainer,
  ModalSectionContainer,
} from "./ChooseAutomaticConciliation.styles";

const moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function ChooseAutomaticConciliation({ setAutomaticConciliation }) {
  const [conciliations, setConciliations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConciliation, setSelectedConciliation] = useState(null);

  const { shopifyOrderId, companyShop } = useOrderStore();

  const findConciliations = async () => {
    setIsLoading(true);
    const listPossiblePayments = () =>
      backend.executeProcess(
        {
          type: "pagarme/listPossiblePayments",
          input: {
            companyShop,
            shopifyOrderId,
          },
        },
        { unique: true }
      );
    const response = await listPossiblePayments();
    setConciliations(response.possiblePayments);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      findConciliations();
    }
  }, [isModalOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedConciliation(null);
  };

  const handleOpen = useCallback(() => setIsModalOpen(true), []);

  const handleChange = useCallback(
    (_, newValue) => setSelectedConciliation(newValue),
    []
  );

  const activator = (
    <Button onClick={handleOpen} plain>
      Conciliação Automática
    </Button>
  );

  return (
    <ChooseAutomaticConciliationContainer>
      <Modal
        activator={activator}
        open={isModalOpen}
        onClose={handleClose}
        title="Selecione a transação a conciliar"
        primaryAction={{
          content: "Conciliar",
          disabled: selectedConciliation === null,
          onAction: () =>
            setAutomaticConciliation(
              conciliations.find(
                (CONCILIATION) => CONCILIATION.id === selectedConciliation
              )
            ),
        }}
        secondaryActions={[
          {
            content: "Recarregar",
            onAction: findConciliations,
          },
          {
            content: "Cancelar",
            onAction: handleClose,
          },
        ]}
      >
        <ModalSectionContainer>
          <Modal.Section>
            {isLoading ? (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            ) : conciliations.length > 0 ? (
              conciliations.map((CONCILIATION) => (
                <RadioButton
                  key={CONCILIATION.id}
                  label={`${moneyFormatter.format(CONCILIATION.amount)} - ${
                    CONCILIATION.createdAt
                  }`}
                  // helpText={`NSU: ${CONCILIATION.nsu} - AUT: ${CONCILIATION.aut}`}
                  helpText={`${CONCILIATION.customer}`}
                  checked={CONCILIATION.id === selectedConciliation}
                  onChange={handleChange}
                  id={CONCILIATION.id}
                  name="conciliation"
                />
              ))
            ) : (
              <LoadingContainer>Nenhum pagamento encontrado</LoadingContainer>
            )}
          </Modal.Section>
        </ModalSectionContainer>
      </Modal>
    </ChooseAutomaticConciliationContainer>
  );
}
