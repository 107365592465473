import styled from "styled-components";

export const Container = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Label = styled.label`
  color: ${({ error }) => (error ? "#D72C0D" : "#555eba")};
  font-size: 16px;
`;

export const DescriptionLabel = styled.label`
  color: ${({ error }) => (error ? "#D72C0D" : "#919191")};
  font-size: 14px;
`;

export const ErrorField = styled.p`
  font-size: 14px;
  color: #d72c0d;
`;
