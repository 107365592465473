export const formatCurrency = (string) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(string);
};

export default function R$({ children: totalAmountString }) {
  return <>{formatCurrency(totalAmountString)}</>;
}
