import R$ from "../../../../component/FormatCurrency/R$";
import { useOrderStore } from "../../../../store/useOrderStore";
import {
  CardHeaderContainer,
  CardHeaderOrder,
  CardHeaderPrice,
} from "./CardHeader.styles";

export function CardHeader() {
  const { orderTotalInCents, shopifyOrderName } = useOrderStore();

  return (
    <CardHeaderContainer>
      <CardHeaderOrder>Pedido {shopifyOrderName}</CardHeaderOrder>
      <CardHeaderPrice>
        <R$>{orderTotalInCents / 100}</R$>
      </CardHeaderPrice>
    </CardHeaderContainer>
  );
}
