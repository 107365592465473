import { create } from "zustand";

export const ANALYTICS_TABS = {
  DASHBOARD: {
    value: 1,
  },
  SUMMARY_SALE_STEP: {
    value: 2,
  },
};

export const useAnalyticsTabsStore = create((set) => ({
  dashboardTab: ANALYTICS_TABS.DASHBOARD.value,
  summarySaleStep: ANALYTICS_TABS.SUMMARY_SALE_STEP.value,

  setSummarySaleStep(summarySaleStep) {
    set({ summarySaleStep });
  },

  setDashboardTab(dashboardTab) {
    set({ dashboardTab });
  },
}));
