import { Page, Layout, List, Spinner } from "@shopify/polaris";
import { CircleAlertMajor } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import ConfirmationCard from "../component/ConfirmationCard/ConfirmationCard.jsx";
import ErrorSituation from "../component/errorSituation/ErrorSituation.jsx";
import Feedback from "../component/Feedback/Feedback.jsx";
import { OrderCanceledFeedback } from "../component/Feedback/OrderCanceledFeedback.jsx";
import withBackButton from "../component/hoc/withBackButton.jsx";
import LoadingFrame from "../component/LoadingFrame/LoadingFrame.jsx";
import ConfirmationModal from "../component/modal/Confirmation.jsx";
import TitleBar from "../component/TitleBar/TitleBar.jsx";
import useConfiguration from "../hook/useConfiguration.js";
import useDevice from "../hook/useDevice.js";
import useFirestoreDoc from "../hook/useFirestoreDoc.js";
import useHideLoadingIndicator from "../hook/useHideLoadingIndicator.js";
import useLocation from "../hook/useLocation.js";
import useRefundTefPaymentList, {
  STATUS as REFUND_STATUS,
} from "../hook/useRefundTefPaymentList.js";
import useShopifyOrderId from "../hook/useShopifyOrderId.js";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";
import backend from "../service/backend.js";
import UnexpectedErrorPage from "./unexpectedErrorPage/UnexpectedErrorPage.jsx";

function CancelSalePage() {
  useHideLoadingIndicator();

  const companyShop = useShopifyShopUrl();
  const shopifyOrderId = useShopifyOrderId();

  const configuration = useConfiguration();
  const location = useLocation();
  const device = useDevice();
  const posDeviceId = `${device?.pos?.device?.serialNumber}`;

  const shopifyOrderDoc = useFirestoreDoc(
    "shopifyOrder",
    `${companyShop}:${shopifyOrderId}`,
  );

  const {
    status: refundStatus,
    error: refundError,
    list: refundList,
    execute: executeRefunds,
  } = useRefundTefPaymentList();

  const fiscalInvoiceRequest = useFirestoreDoc(
    "fiscalInvoice",
    `${companyShop}:${shopifyOrderId}:sale`,
  );
  const nfceId = fiscalInvoiceRequest?.data?.id ?? null;

  const [cancellationSubmissionStatus, setCancellationSubmissionStatus] =
    useState(null);

  const submitCancellation = async () => {
    try {
      setCancellationSubmissionStatus("submitting");
      backend.executeProcess({
        type: "cancelOrder",
        input: { companyShop, shopifyOrderId },
      });
      setCancellationSubmissionStatus("ok");
    } catch (error) {
      console.error(error);
      setCancellationSubmissionStatus("error");
    }
  };
  const resetSubmission = () => setCancellationSubmissionStatus(null);

  const execute = async () => {
    if ([REFUND_STATUS.READY, REFUND_STATUS.EXECUTING].includes(refundStatus)) {
      const locationCnpj = location?.cnpj ?? "";
      const softwareexpressClientId =
        location?.payment?.card?.softwareexpress?.clientId;
      // TO-DO: validate data here and render error views.
      executeRefunds({
        locationCnpj,
        softwareexpressClientId,
        posDeviceId,
        printerWifiIp: location?.printer?.wifi?.ip,
      });
    } else if (
      [REFUND_STATUS.NOOP, REFUND_STATUS.DONE].includes(refundStatus)
    ) {
      await submitCancellation();
    } else {
      throw new Error("unreachable");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmCancel = () => {
    setIsModalOpen(false);
    execute();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(async () => {
    if (refundList.length > 0 && refundStatus === REFUND_STATUS.DONE) {
      await submitCancellation();
    }
  }, [refundStatus]);

  if (
    !configuration ||
    !location ||
    !posDeviceId ||
    shopifyOrderDoc.isLoading ||
    refundStatus === REFUND_STATUS.LOADING ||
    fiscalInvoiceRequest.isLoading
  ) {
    return (
      <Page narrowWidth>
        <TitleBar title="Cancelar [Carregando...]" />
        <LoadingFrame />
        {/*<VersionFooter />*/}
        {/*TO-DO: version in all screens*/}
      </Page>
    );
  } else if (
    shopifyOrderDoc.didFail ||
    // TO-DO: refund-failure state
    fiscalInvoiceRequest.didFail ||
    refundError !== null
  ) {
    return (
      <UnexpectedErrorPage
        error={
          fiscalInvoiceRequest.error || shopifyOrderDoc.error || refundError
        }
      />
    );
  } else {
    const shopifyOrder = shopifyOrderDoc.data;
    if (!shopifyOrder?.wasCanceled && cancellationSubmissionStatus === null) {
      const actionList = [
        ...refundList.map((refund) => refund.actionStatement),
        nfceId !== null ? "Cancelar NFC-e na Sefaz" : null,
        nfceId !== null ? "Cancelar NFC-e no ERP" : null,
        "Re-estocar itens no Shopify",
        shopifyOrder?.displayFinancialStatus === "PENDING"
          ? "Cancelar pedido no Shopify"
          : null,
      ].filter((a) => a !== null);

      const eCommerce = shopifyOrder?.errorSituation?.isEcommerce;
      const fiscalCantBeCanceled =
        shopifyOrder?.errorSituation?.isFiscalInvoiceAlreadyCreated;
      const nfCancelled = shopifyOrder?.errorSituation?.isSaleNfCancelled;
      const nfReturned = shopifyOrder?.errorSituation?.isNfeReturned;
      const possibilityOfErrors = {
        eCommerce,
        fiscalCantBeCanceled,
        nfCancelled,
        nfReturned,
      };

      return (
        <Page>
          <TitleBar title="Cancelar" />

          {isModalOpen && (
            <ConfirmationModal
              isOpen={isModalOpen}
              title="Cancelamento do pedido"
              prompt="Após confirmação, o pedido será cancelado."
              confirmText="Cancelar pedido"
              cancelText={"Voltar"}
              onCancel={handleCloseModal}
              onClose={handleCloseModal}
              onConfirm={handleConfirmCancel}
            />
          )}

          <ErrorSituation
            conditions={possibilityOfErrors}
            path={"/one-button-page"}
            cases={{
              eCommerce: {
                title: "Não é possível cancelar a nota fiscal",
                errorMessage:
                  "Desculpe, não é possível cancelar a nota fiscal deste pedido, pois ele foi feito pelo e-commerce. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
              },
              fiscalCantBeCanceled: {
                title: "Não é possível cancelar a nota fiscal",
                errorMessage:
                  "Desculpe, não é possível cancelar a nota fiscal deste pedido, pois já se passaram mais de 30 minutos desde a sua emissão. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
              },
              nfCancelled: {
                title: "Não há nota fiscal para cancelar",
                errorMessage:
                  "A nota fiscal de venda já foi cancelada para este pedido. Não há nada para cancelar no momento. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
              },
              nfReturned: {
                title: "Não há nota fiscal para cancelar",
                errorMessage:
                  "Este pedido já foi devolvido anteriormente. Não é possível cancelar. Se precisar de assistência, entre em contato com o responsável financeiro da sua empresa.",
              },
            }}
          >
            <Layout>
              <Layout.Section>
                <ConfirmationCard
                  orderName={shopifyOrder.name}
                  primaryAction={handleOpenModal}
                  primaryDisabled={
                    refundList.length > 0 &&
                    (refundList.some(
                      (r) => r.status === REFUND_STATUS.EXECUTING,
                    ) ||
                      refundStatus === REFUND_STATUS.DONE)
                  }
                  prompt={
                    refundStatus === REFUND_STATUS.NOOP
                      ? "Confirmar"
                      : "Realizar o próximo estorno"
                  }
                >
                  {actionList.length > 1 ? (
                    <>
                      <List>
                        {actionList.map((action, actionIndex) => (
                          <List.Item key={actionIndex}>{action}</List.Item>
                        ))}
                      </List>
                      <p style={{ marginTop: "16px" }}>
                        Confirmar a execução das ações acima?
                      </p>
                    </>
                  ) : (
                    <p style={{ marginTop: "16px" }}>
                      Re-estocar os itens deste pedido no Shopify?
                    </p>
                  )}
                </ConfirmationCard>
              </Layout.Section>
            </Layout>
          </ErrorSituation>
        </Page>
      );
    } else {
      if (cancellationSubmissionStatus === "submitting") {
        return <Feedback PolarisIcon={Spinner} primaryText="Cancelando..." />;
      } else if (cancellationSubmissionStatus === "error") {
        return (
          <Feedback
            PolarisIcon={CircleAlertMajor}
            primaryText="Falha no cancelamento"
            secondaryText="Verifique a conexão e tente novamente"
            primaryAction={{ text: "Voltar", onTap: resetSubmission }}
          />
        );
      } else {
        return <OrderCanceledFeedback />;
      }
    }
  }
}

export default withBackButton(CancelSalePage, "/one-button-page");
