import { Button } from "@shopify/polaris";
import {
  Container,
  CentralBlock,
  PrimaryText,
  SecondaryText,
  BottomBlock,
} from "./Feedback.styles.js";

export default function Feedback({
  PolarisIcon,
  primaryText,
  secondaryText,
  primaryAction,
}) {
  return (
    <Container>
      <CentralBlock>
        <PolarisIcon height="6vh" />
        <PrimaryText>{primaryText}</PrimaryText>
        {typeof secondaryText === "string" && (
          <SecondaryText>{secondaryText}</SecondaryText>
        )}
      </CentralBlock>
      {isValidPrimaryAction(primaryAction) && (
        <BottomBlock>
          <Button primary fullWidth size="large" onClick={primaryAction.onTap}>
            {primaryAction.text}
          </Button>
        </BottomBlock>
      )}
    </Container>
  );
}

function isValidPrimaryAction(primaryAction) {
  return (
    typeof primaryAction?.text === "string" &&
    typeof primaryAction.onTap === "function"
  );
}
