import { getProcessId } from "@iglu/common/backend";
import backend from "../service/backend.js";

export default function useProducer() {
  return {
    createEvent: (event) => event,
    produce: async ({ type, payload }) => {
      await backend.executeProcess({ type, input: payload }, { sync: false });
      return { workflowId: getProcessId({ type, input: payload }) };
    },
  };
}
