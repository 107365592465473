import firestore from "@iglu/common/firestore";
import Big from "big.js";
import { doc, onSnapshot } from "firebase/firestore";
import useShopifyOrderId from "../hook/useShopifyOrderId.js";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";

export const STATUS = {
  READY: "ready",
  INTERRUPTED: {
    NOT_SAME_DAY: "interrupted/not-same-day", // must be the same calendar day
    // BRIDGE: "interrupted/bridge", // Iglu bridge either failed or aborted the command-execution.
  },
  EXECUTING: "executing",
  DONE: "done",
};

export default function createSingleTefPaymentRefund({
  payment,
  onError,
  onNext,
}) {
  const shopifyShopHost = useShopifyShopUrl();
  const shopifyOrderId = useShopifyOrderId();

  const paymentCreatedAtDate = new Date(payment.createdAt);

  let status =
      payment.status === "refunded"
        ? STATUS.DONE
        : paymentCreatedAtDate >= todayFirstInstantDate() &&
          paymentCreatedAtDate <= todayLastInstantDate()
        ? STATUS.READY
        : STATUS.INTERRUPTED.NOT_SAME_DAY,
    actionStatement = getRefundPaymentActionStatement({
      payment,
      refundStatus: status,
    }),
    execute = ({
      locationCnpj,
      softwareexpressClientId,
      posDeviceId,
      printerWifiIp,
    }) => {
      switch (status) {
        case STATUS.READY:
          status = STATUS.EXECUTING;
          actionStatement = `${getRefundPaymentActionStatement({
            payment,
            refundStatus: status,
          })}`;
          onNext({ payment, status, actionStatement, execute });
          window.open(
            `iglu://refund-tef-payment/?${new URLSearchParams({
              cnpj: locationCnpj,
              clid: softwareexpressClientId,
              pdid: posDeviceId,
              borg:
                !import.meta.env || !import.meta.env.PROD
                  ? window.location.origin
                  : window.location.origin.replace(/app-/, ""),
              pkey: `${shopifyShopHost}:${shopifyOrderId}:${payment.id}`, // payment-document-key
              pnsu: payment.nsu,
              pcat: getPaymentCreatedAtDate(payment), // payment-created-at-date ddmmyyyy
              pcent: payment.amountCents,
              pmeth: payment.method,
              pinst: payment.installments,
              pwip: printerWifiIp,
            })}`,
            "_blank"
          );

          return "did-open-bridge";

        case STATUS.INTERRUPTED.NOT_SAME_DAY:
          alert(
            "Pagamentos só podem ser estornados no mesmo dia 😅😓, por favor entre em contato com o Financeiro"
          );
          return "did-not-open-bridge";

        case STATUS.DONE:
          return "did-not-open-bridge";

        case STATUS.EXECUTING: // Shouldn't be reachable if the interface is constrained precisely/correctly.
        default:
          throw new Error("unreachable");
      }
    };

  const unsubscribeFromOnSnapshot = onSnapshot(
    doc(
      firestore,
      "payment",
      `${shopifyShopHost}:${shopifyOrderId}:${payment.id}`
    ),
    {
      error: (error) => {
        unsubscribeFromOnSnapshot();
        onError(error);
      },
      next: (paymentSnapshot) => {
        const nextPayment = paymentSnapshot.data() ?? null;
        if (nextPayment?.status === "refunded") {
          unsubscribeFromOnSnapshot();
          status = STATUS.DONE;
        } else {
          status = STATUS.READY;
        }
        onNext({
          payment: nextPayment,
          status,
          actionStatement: getRefundPaymentActionStatement({
            payment: nextPayment,
            refundStatus: status,
          }),
          execute,
        });
      },
    }
  );

  return {
    payment,
    status,
    actionStatement,
    execute,
  };
}

function todayFirstInstantDate() {
  let d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

function todayLastInstantDate() {
  let d = new Date();
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
}

function getRefundPaymentActionStatement({ payment, refundStatus }) {
  return `Estornar pagamento de ${getPaymentAmount(
    payment
  )} no ${getPaymentMethod(payment)} feito hoje às ${getPaymentHourMinuteSecond(
    payment
  )}${
    refundStatus === STATUS.DONE
      ? " ✅"
      : refundStatus === STATUS.EXECUTING
      ? " ⏳"
      : ""
  }`;
}

function getPaymentAmount({ amountCents }) {
  const amountDecimal = Big(amountCents)
    .div(Big("100"))
    .round(2, Big.roundHalfUp)
    .toString();
  return `R$ ${amountDecimal}`;
}

function getPaymentMethod({ method }) {
  if (method === "credit") {
    return "crédito";
  } else if (method === "debit") {
    return "débito";
  } else {
    throw new Error("unreachable");
  }
}

function getPaymentHourMinuteSecond({ createdAt }) {
  const date = new Date(createdAt),
    hour = `${date.getHours()}`.padStart(2, "0"),
    minute = `${date.getMinutes()}`.padStart(2, "0"),
    second = `${date.getSeconds()}`.padStart(2, "0");
  return `${hour}:${minute}:${second}`;
}

// Format ddmmyyyy
function getPaymentCreatedAtDate({ createdAt }) {
  const date = new Date(createdAt),
    day = `${date.getDate()}`.padStart(2, "0"),
    month = `${date.getMonth() + 1}`.padStart(2, "0"),
    year = `${date.getFullYear()}`;
  return `${day}${month}${year}`;
}
