import { Icon } from "@shopify/polaris";
import { CircleTickMinor } from "@shopify/polaris-icons";
import ErrorBanner from "../ErrorBanner/ErrorBanner";
import StandardError from "../StandardError/StandardError";
import {
  Container,
  NfProgressStatusContainer,
  ProgressStatusIcon,
  ProgressStatusText,
  TitleFinishedPayment,
} from "./NfProgressStatus.styles";
export default function NfProgressStatus({
  nfceInProgress,
  nfceDone,
  ncmError,
  importProductError,
  skuError,
  nfceError,
}) {
  return (
    <NfProgressStatusContainer>
      {nfceInProgress === true && (
        <>
          <Container>
            <ProgressStatusIcon>
              <Icon source={CircleTickMinor} color="success" />
            </ProgressStatusIcon>
            <TitleFinishedPayment>Pagamento Finalizado</TitleFinishedPayment>
          </Container>
          <ProgressStatusText>
            Estamos gerando sua nota fiscal e ela será enviada automaticamente
            para o email do cliente.
          </ProgressStatusText>
        </>
      )}

      {nfceDone === true && (
        <ProgressStatusText>
          Sua nota fiscal foi gerada e já foi enviada para o email do cliente!
          :)
        </ProgressStatusText>
      )}

      {importProductError === true && nfceError === true && (
        <StandardError
          title={"Erro ao gerar a Nota Fiscal"}
          placeHolder={
            <>
              O produto não foi encontrado no <strong>ERP</strong> da empresa.
            </>
          }
          message={
            <>
              Contate o responsável da sua empresa para{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                cadastrar os dados fiscais{" "}
              </span>
              dos produtos no sistema e avise o time Iglu ao finalizar.
            </>
          }
          hideBackButton={true}
        />
      )}
      {ncmError === true && nfceError === true && (
        <StandardError
          title={"Erro ao gerar a Nota Fiscal"}
          placeHolder={
            <>
              NCM <strong>faltante</strong> ou <strong>inválido</strong> em um
              ou mais itens do pedido.
            </>
          }
          message={
            <>
              Contate o responsável da sua empresa para{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                cadastrar os dados fiscais{" "}
              </span>
              dos produtos no sistema e avise o time Iglu ao finalizar.
            </>
          }
          hideBackButton={true}
        />
      )}

      {skuError && nfceError === true && (
        <StandardError
          title={"Erro ao gerar a Nota Fiscal"}
          placeHolder={
            <>
              <strong> SKU faltante</strong> em um ou mais itens do pedido.
            </>
          }
          message={
            <>
              Contate o responsável da sua empresa para{" "}
              <strong> cadastrar</strong> os produtos no sistema e refaça o
              pedido.
            </>
          }
          hideBackButton={true}
        />
      )}

      {nfceError === true && !ncmError && !skuError && !importProductError && (
        <ErrorBanner error="Ocorreu um erro ao gerar sua nota fiscal. Tente novamente ou entre em contato com o suporte!" />
      )}
    </NfProgressStatusContainer>
  );
}
