import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 16px;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerTableInformation = styled.div`
  width: 100%;
  cursor: pointer;
`;
