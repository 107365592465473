import { Page, DataTable } from "@shopify/polaris";
import React from "react";
import useFiscalData from "../../../../hook/useFiscalData";
import { SalesSummaryContainer } from "./SalesSummary.styles";

export function SalesSummary() {
  const {
    numberOfOrders,
    totalSalesValue,
    totalDiscounts,
    totalReturn,
    totalGrossSales,
  } = useFiscalData();

  function formatCurrency(value) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  const order = numberOfOrders;
  const grossSale = formatCurrency(totalGrossSales);
  const discount = formatCurrency(totalDiscounts);
  const returnOdersValue = formatCurrency(totalReturn);
  const sale = formatCurrency(totalSalesValue);

  const SALES_SUMMARY = [
    ["Pedidos", order],
    ["Vendas brutas", grossSale],
    ["Descontos", discount],
    ["Devoluções", returnOdersValue],
    ["Vendas líquidas", sale],
  ];

  return (
    <SalesSummaryContainer>
      <Page title={"Resumo"}>
        <DataTable
          columnContentTypes={["payment", "value"]}
          headings={[]}
          rows={SALES_SUMMARY.map((item) => [item[0], item[1]])}
        />
      </Page>
    </SalesSummaryContainer>
  );
}
