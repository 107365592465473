import styled from "styled-components";

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  padding-bottom: 8px;
  margin-top: 18px;
  margin-left: 12px;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  color: #284b54;
  padding: 12px;
  font-weight: bold;
`;

export const PaymentsButtonContainer = styled.div`
  margin-top: 14px;
  margin-left: 16px;
`;
