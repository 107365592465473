import { TextField } from "@shopify/polaris";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Container } from "./styles";

export function NsuIdField({ value, onChange }) {
  const { requiredConciliationFields: requiredFieldsFlag } = useFlags();

  return (
    <Container>
      <TextField
        requiredIndicator={requiredFieldsFlag}
        label={
          requiredFieldsFlag ? "NSU ID (Obrigatório)" : "NSU ID (Opcional)"
        }
        value={value ?? undefined}
        onChange={onChange}
      />
    </Container>
  );
}
