import { Page, DataTable } from "@shopify/polaris";
import usePaymentData from "../../../../hook/usePaymentData";
import { extractHour } from "../TotalSummary/TotalSummary";
import { CreditSummaryContainer } from "./CreditSummary.styles";

export function CreditSummary() {
  const { totalCredit } = usePaymentData();

  const rows = (totalCredit || []).map((payment) => [
    extractHour(payment.createdAt),
    `R$${(payment.amountCents / 100).toFixed(2)}`,
    payment.aut || "-",
    payment.wasConciliation ? "Sim" : "Não",
    payment.orderName || "-",
  ]);

  let totalAmountCents = 0;
  for (const payment of totalCredit || []) {
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    totalAmountCents += amountCents;
  }

  const totalCreditFormatted = `R$${(totalAmountCents / 100).toFixed(2)}`;

  return (
    <CreditSummaryContainer>
      <Page title="Crédito">
        <DataTable
          showTotalsInFooter
          truncate
          fixedFirstColumns
          columnContentTypes={["text", "numeric", "text", "numeric"]}
          headings={["Hora", "Valor", "AUT", "Avulso", "Pedido"]}
          rows={rows}
          totals={["", totalCreditFormatted, "", "", ""]}
          totalsName={{
            singular: "Total",
          }}
        />
      </Page>
    </CreditSummaryContainer>
  );
}
