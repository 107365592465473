import { TabPanel } from "../../../../../component/Tabs/TabPanel";
import {
  UNIQUE_PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../store/usePaymentFlowStore";
import { Title } from "../../Title/Title";
import { PaymentForm } from "./components/PaymentForm/PaymentForm";
import { LinkPayment } from "./LinkPayment/LinkPayment";
import { MachinePayment } from "./MachinePayment/MachinePayment";
import { PixPayment } from "./PixPayment/PixPayment";
import { Container } from "./UniquePayment.styles";

export function UniquePayment() {
  const { uniquePaymentStep } = usePaymentFlowStore();

  return (
    <Container>
      <Title>Pagamento</Title>

      <TabPanel
        value={uniquePaymentStep}
        index={UNIQUE_PAYMENT_STEPS.PAYMENT_FORM.value}
      >
        <PaymentForm />
      </TabPanel>

      <TabPanel
        value={uniquePaymentStep}
        index={UNIQUE_PAYMENT_STEPS.CARD.value}
      >
        <MachinePayment />
      </TabPanel>

      <TabPanel
        value={uniquePaymentStep}
        index={UNIQUE_PAYMENT_STEPS.PIX.value}
      >
        <PixPayment />
      </TabPanel>

      <TabPanel
        value={uniquePaymentStep}
        index={UNIQUE_PAYMENT_STEPS.LINK.value}
      >
        <LinkPayment />
      </TabPanel>
    </Container>
  );
}
