import { create } from "zustand";

export const useOrderStore = create((set) => ({
  shopifyOrderId: null,
  cpf: null,
  paymentService: null,
  companyShop: null,
  shopifyOrderName: null,
  posLocationId: null,
  posDeviceId: null,
  orderTotalInCents: null,
  totalAmount: null,
  staffId: null,
  email: null,
  orderType: "DEFAULT",
  shippingLine: null,
  channelName: null,
  isForShipping: false,

  setStaffId(staffId) {
    set({ staffId });
  },

  setCpf(cpf) {
    set({ cpf });
  },

  setOrderTotalInCents(orderTotalInCents) {
    set({ orderTotalInCents: parseInt(orderTotalInCents, 10) });
  },

  setPaymentService(paymentService) {
    set({ paymentService });
  },

  setShopifyOrderId(shopifyOrderId) {
    set({ shopifyOrderId });
  },

  setCompanyShop(companyShop) {
    set({ companyShop });
  },

  setShopifyOrderName(shopifyOrderName) {
    set({ shopifyOrderName });
  },

  setPosLocationId(posLocationId) {
    set({ posLocationId });
  },

  setPosDeviceId(posDeviceId) {
    set({ posDeviceId });
  },

  setEmail(email) {
    set({ email });
  },

  setTotalAmount(totalAmount) {
    set({ totalAmount });
  },

  setOrderType(orderType) {
    set({ orderType });
  },

  setShippingLine(shippingLine) {
    set({ shippingLine });
  },

  setChannelName(channelName) {
    set({ channelName });
  },

  setIsForShipping(isForShipping) {
    set({ isForShipping });
  },
}));
