import {
  Card,
  TextContainer,
  FormLayout,
  TextField,
  Image,
  Stack,
  Heading,
} from "@shopify/polaris";
import useFirestoreDoc from "../../hook/useFirestoreDoc.js";
import LoadingSkeleton from "../LoadingSkeleton/LoadingSkeleton.jsx";

const DEFAULT_LOGO_URL = "https://iglu-public.s3.amazonaws.com/iglubw.svg";

export default function VirtualStoreCard({ shopifyShopUrl }) {
  const merchantRequest = useFirestoreDoc("company", shopifyShopUrl);
  const merchantConfigurationRequest = useFirestoreDoc(
    "configuration",
    shopifyShopUrl,
  );
  return (
    <Card sectioned>
      <Stack
        wrap={false}
        spacing="extraTight"
        distribution="trailing"
        alignment="center"
      >
        <Stack.Item fill>
          <TextContainer spacing="loose">
            <Heading>Loja Virtual</Heading>
            <FormLayout>
              {merchantRequest.isLoading ? (
                <>
                  <p style={{ marginBottom: "var(--p-space-1)" }}>Nome</p>
                  <LoadingSkeleton height="36px" borderRadius="3px" />
                </>
              ) : (
                <TextField
                  label="Nome"
                  value={merchantRequest?.data?.name || "-"}
                  autoComplete="off"
                  disabled
                />
              )}
              <TextField
                label="URL"
                value={shopifyShopUrl}
                autoComplete="off"
                disabled
              />
            </FormLayout>
          </TextContainer>
        </Stack.Item>
        <Stack.Item>
          {merchantConfigurationRequest.didSucceed ? (
            <div style={{ padding: "0 20px" }}>
              <Image
                source={
                  merchantConfigurationRequest?.data?.logoUrl ||
                  DEFAULT_LOGO_URL
                }
                alt="Logo da marca do varejista"
                width={100}
              />
            </div>
          ) : (
            <LoadingSkeleton height="80px" width="80px" margin="30px" />
          )}
        </Stack.Item>
      </Stack>
    </Card>
  );
}
