import styled from "styled-components";

export const StandardErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding: 24px;
  background-color: #fff;
`;

export const StandardErrorTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  padding: 24px;
  padding-left: 16px;
`;

export const StandardErrorButtonContainer = styled.div`
  gap: 12px;
  display: flex;
  padding: 24px;
  justify-content: center;
`;

export const StandardErrorPlaceholder = styled.div`
  margin: 30px;
`;

export const StandardErrorMessage = styled.p`
  margin-top: 10px;
`;
