import { useCallback, useEffect } from "react";
import { onCLS, onFID, onLCP } from "web-vitals";
import useShopifyOrderId from "../../hook/useShopifyOrderId";
import useShopifyShopUrl from "../../hook/useShopifyShopUrl";
import backend from "../../service/backend";

export default function useCoreWebVitalsMeasurements() {
  const shopifyShopUrl = useShopifyShopUrl();
  const shopifyOrderId = useShopifyOrderId();
  const uploadWebVitalMeasurement = useCallback(async (measurement) => {
    // TO-DO: send more info about sender: e.g. page=window.location.pathname
    await backend.executeProcess(
      {
        type: "analytics/uploadWebVitalMeasurement",
        input: {
          companyShop: shopifyShopUrl,
          shopifyOrderId,
          measurement,
        },
      },
      { unique: true },
    );
  }, []);
  useEffect(() => {
    onLCP(uploadWebVitalMeasurement, { reportAllChanges: false });
    onFID(uploadWebVitalMeasurement, { reportAllChanges: false });
    onCLS(uploadWebVitalMeasurement, { reportAllChanges: false });
  }, []);
}
