import { Button } from "@shopify/polaris";
import { useUniquePaymentStore } from "../../../../../../../../../store/useUniquePaymentStore";

export function PixSubmit({ onClick }) {
  const { chargedSeparately } = useUniquePaymentStore();

  const buttonText = chargedSeparately ? "Gerar NF" : "Gerar QR code";

  return (
    <Button primary onClick={onClick}>
      {buttonText}
    </Button>
  );
}
