import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #6d7175;
`;

export const GroupedForwardButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-top: 16px;
`;

export const Button = styled.button`
  all: unset;

  cursor: pointer;
  -webkit-tap-highlight-color: rgba(221, 221, 221, 0.4);

  padding: 16px;
  border: 1px solid #aeb4b9;
  border-radius: 2px;

  display: flex;
  justify-content: space-between;
`;
