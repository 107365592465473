import { useEffect } from "react";

export default function useHideLoadingIndicator() {
  useEffect(() => {
    const loadingIndicator = document.getElementById("loading-indicator");
    if (loadingIndicator) {
      loadingIndicator.remove();
    }
  }, []);
}
