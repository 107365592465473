import { Icon } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import {
  SelectButtonContainer,
  SelectButtonIconContainer,
} from "./SelectButton.styles";

export function SelectButton({ text, onClick }) {
  return (
    <>
      <SelectButtonContainer onClick={onClick}>
        {text}
        <SelectButtonIconContainer>
          <Icon source={ChevronDownMinor} color="subdued" />
        </SelectButtonIconContainer>
      </SelectButtonContainer>
    </>
  );
}
