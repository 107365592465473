import { Select } from "@shopify/polaris";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import {
  PAYMENT_STEPS,
  usePaymentFlowStore,
} from "../../../../../../store/usePaymentFlowStore";
import { Container } from "./PaymentMethodSelect.styles";

export function PaymentMethodSelect({ value, onChange }) {
  const {
    disableStoreCredit,
    disableMethodCash,
    disableMethodPix,
    disableMethodCredit,
    disableMethodDebit,
    disableMethodLink,
    automaticLink,
  } = useFlags();
  const { paymentStep } = usePaymentFlowStore();

  const options = [];

  if (!disableStoreCredit) {
    options.push({ label: "Troca - Créditos", value: "STORE-CREDIT" });
  }
  if (!disableMethodCash) {
    options.push({ label: "Dinheiro", value: "CASH" });
  }
  if (!disableMethodCredit) {
    options.push({ label: "Crédito", value: "CREDIT" });
  }
  if (!disableMethodDebit) {
    options.push({ label: "Débito", value: "DEBIT" });
  }
  if (!disableMethodPix) {
    options.push({ label: "PIX", value: "PIX" });
  }
  if (!disableMethodLink) {
    if (automaticLink && paymentStep === PAYMENT_STEPS.UNIQUE_PAYMENT.value) {
      options.push({ label: "Link de Pagamento", value: "LINK" });
    } else if (!automaticLink) {
      options.push({ label: "Link de Pagamento", value: "LINK" });
    }
  }

  return (
    <Container>
      <Select
        placeholder="Selecione o método de pagamento..."
        options={options}
        value={value ?? undefined}
        onChange={onChange}
      />
    </Container>
  );
}
