import { Container, Checkbox, Slider, Switch, Label } from "./styles";

export function Switcher({ name, id, label, checked, onChange }) {
  const handleClick = () => onChange?.(!checked);

  const handleChange = (event) => onChange(event.target.checked);

  return (
    <Container>
      <Switch>
        <Checkbox
          name={name}
          id={id}
          checked={checked}
          onChange={handleChange}
        />
        <Slider />
      </Switch>

      <Label htmlFor={id} onClick={handleClick}>
        {label}
      </Label>
    </Container>
  );
}
