import { Page, DataTable } from "@shopify/polaris";
import usePaymentData from "../../../../hook/usePaymentData";
import { extractHour } from "../TotalSummary/TotalSummary";
import { PixSummaryContainer } from "./PixSummary.styles";

export function PixSummary() {
  const { totalPix } = usePaymentData();

  const rows = (totalPix || []).map((payment) => [
    extractHour(payment.createdAt),
    `R$${(payment.amountCents / 100).toFixed(2)}`,
    payment.wasConciliation ? "Sim" : "Não",
    payment.orderName || "-",
  ]);

  let totalAmountCents = 0;

  for (const payment of totalPix || []) {
    const amountCents = parseInt(payment.amountCents, 10) || 0;
    totalAmountCents += amountCents;
  }

  const totalPixFormatted = `R$${(totalAmountCents / 100).toFixed(2)}`;

  return (
    <PixSummaryContainer>
      <Page title="Pix">
        <DataTable
          showTotalsInFooter
          truncate
          fixedFirstColumns
          columnContentTypes={["text", "numeric", "numeric", "text"]}
          headings={["Hora", "Valor", "Avulso", "Pedido"]}
          rows={rows}
          totals={["", totalPixFormatted, "", ""]}
          totalsName={{
            singular: "Total",
          }}
        />
      </Page>
    </PixSummaryContainer>
  );
}
