import styled from "styled-components";

export const Container = styled.div``;

export const SubtitleMachinePayment = styled.h4`
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: grey;
  padding-bottom: 24px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
  padding: 24px;
`;
