import { useNavigate as useAppBridgeNavigate } from "@shopify/app-bridge-react";
import {
  isShopifyEmbedded as isShopifyWeb,
  isShopifyPOS as isShopifyPos,
} from "@shopify/app-bridge-utils";
import { Button, Icon } from "@shopify/polaris";
import { CircleAlertMajor } from "@shopify/polaris-icons";
import { useNavigate as useBrowserRouterNavigate } from "react-router-dom";
import {
  StandardErrorButtonContainer,
  StandardErrorContainer,
  StandardErrorMessage,
  StandardErrorPlaceholder,
  StandardErrorTitle,
} from "./StandardError.styles";

let useNavigate = null;

if (isShopifyWeb() || isShopifyPos()) {
  useNavigate = useAppBridgeNavigate;
} else {
  useNavigate = useBrowserRouterNavigate;
}

export default function StandardError({
  title,
  placeHolder,
  message,
  hideBackButton,
  path,
  ExtraActions,
}) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(path);
  };

  return (
    <StandardErrorContainer>
      <Icon source={CircleAlertMajor} color="base" />
      <StandardErrorTitle>{title}</StandardErrorTitle>
      <StandardErrorPlaceholder>{placeHolder}</StandardErrorPlaceholder>

      <StandardErrorMessage>{message}</StandardErrorMessage>
      <StandardErrorButtonContainer>
        {!hideBackButton && <Button onClick={handleBack}>Voltar</Button>}

        {ExtraActions && <ExtraActions />}
      </StandardErrorButtonContainer>
    </StandardErrorContainer>
  );
}
