import { useCurrentMultiplePayment } from "../../../../../../../store/useMultiplePaymentsStore";
import { useConfigurationStore } from "../../../../../../../store/useConfigurationStore";
import { ChooseAutomaticMultiplesConciliation } from "./ChooseAutomaticMultiplesConciliation";
import { SelectedAutomaticConciliationMultiple } from "./SelectedAutomaticConciliationMultiple";

export function AutomaticConciliation() {
  const { currentPayment } = useCurrentMultiplePayment();

  const { configuration } = useConfigurationStore();
  const paymentCardService = configuration?.payment?.card?.service;

  const shouldRenderThisField = () => {
    if (paymentCardService !== "pagarme") {
      return false;
    }
    if (
      (currentPayment.paymentMethod === "DEBIT" ||
        currentPayment.paymentMethod === "CREDIT") &&
      currentPayment.chargedSeparately
    ) {
      return true;
    }

    return false;
  };

  if (!shouldRenderThisField()) {
    return null;
  }

  if (currentPayment.automaticConciliation) {
    return <SelectedAutomaticConciliationMultiple />;
  }

  return <ChooseAutomaticMultiplesConciliation />;
}
