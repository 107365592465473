import { Page, DataTable } from "@shopify/polaris";
import React from "react";
import useFiscalData from "../../../../hook/useFiscalData";
import { PaymentTypesCardContainer } from "./PaymentTypesCard.styles.js";

const numberFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

function formatCurrency(value) {
  return numberFormatter.format(value);
}

export function PaymentTypesCard() {
  const { totalPaymentsMethod } = useFiscalData();

  const methods = [
    ["Crédito", formatCurrency(totalPaymentsMethod.credit)],
    ["Débito", formatCurrency(totalPaymentsMethod.debit)],
    ["Dinheiro", formatCurrency(totalPaymentsMethod.cash)],
    ["Pix", formatCurrency(totalPaymentsMethod.pix)],
    ["Link de Pagamento", formatCurrency(totalPaymentsMethod.link)],
    ["Cartão de Presente", formatCurrency(totalPaymentsMethod["gift-card"])],
    ["Troca- Créditos", formatCurrency(totalPaymentsMethod["store-credit"])],
  ];

  return (
    <PaymentTypesCardContainer>
      <Page title="Métodos de Pagamentos">
        <DataTable
          columnContentTypes={["paymentMethod", "value", "orderQuantity"]}
          headings={[]}
          rows={methods}
        />
      </Page>
    </PaymentTypesCardContainer>
  );
}
