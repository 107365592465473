import { create } from "zustand";

export const useFiscalInvoiceStore = create((set) => ({
  fiscalInvoiceStatus: null,
  fiscalInvoiceId: null,
  fiscalInvoiceVincoError: null,

  setFiscalInvoiceStatus(fiscalInvoiceStatus) {
    set({ fiscalInvoiceStatus });
  },
  setFiscalInvoiceId(fiscalInvoiceId) {
    set({ fiscalInvoiceId });
  },
  setFiscalInvoiceVincoError(fiscalInvoiceVincoError) {
    set({ fiscalInvoiceVincoError });
  },
}));
