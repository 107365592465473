import { create } from "zustand";

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const startToday = new Date(
  Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 3, 0, 0),
);
const startTomorrow = new Date(
  Date.UTC(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate(),
    3,
    0,
    0,
  ),
);

const utcStartDate = startToday.toISOString();
const utcEndDate = startTomorrow.toISOString();

export const usePaymentsStore = create((set) => {
  return {
    payments: [],
    startDate: utcStartDate,
    endDate: utcEndDate,
    locations: null,
    selectedLocation: null,
    locationToFilter: null,
    staffFilter: "allSales",

    setSelectLocation(selectedLocation) {
      set({ selectedLocation });
    },
    setLocations(locations) {
      set({ locations, selectedLocation: locations[0] });
    },
    setPayments(payments) {
      set({ payments });
    },
    setStartDate(startDate) {
      set({ startDate });
    },
    setEndDate(endDate) {
      set({ endDate });
    },

    setLocationToFilter(locationToFilter) {
      set({ locationToFilter });
    },

    setStaffFilter(staffFilter) {
      set({ staffFilter });
    },
  };
});
