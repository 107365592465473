import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 24px;

  -webkit-tap-highlight-color: transparent;
`;

export const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.2s;

  border-radius: 27px;

  ::before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 0;
    bottom: 0;
    background-color: #fff;
    transition: 0.2s;
    border-radius: 50%;

    border: 1px solid rgba(217, 217, 217, 0.52);
  }
`;

export const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${Slider} {
    background-color: #2c6ecb;
  }

  :checked + ${Slider}:before {
    transform: translateX(32px);
  }
`;

Checkbox.defaultProps = {
  type: "checkbox",
};

export const Label = styled.label``;
