import { isShopifyPOS as isShopifyPos } from "@shopify/app-bridge-utils";
import {
  Button,
  Card,
  FormLayout,
  Layout,
  Page,
  TextField,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import LoadingFrame from "../component/LoadingFrame/LoadingFrame.jsx";
import PendingPaymentListCard from "../component/PendingPaymentListCard/PendingPaymentListCard.jsx";
import PspDeviceListCard from "../component/PspDeviceListCard/PspDeviceListCard.jsx";
import TitleBar from "../component/TitleBar/TitleBar.jsx";
import VersionFooter from "../component/VersionFooter.jsx";
import { format as formatCnpj } from "../helper/cnpj.js";
import useFirestoreDoc from "../hook/useFirestoreDoc.js";
import useHideLoadingIndicator from "../hook/useHideLoadingIndicator.js";
import useShopifyShopUrl from "../hook/useShopifyShopUrl.js";
import UnexpectedErrorPage from "./unexpectedErrorPage/UnexpectedErrorPage.jsx";

const _isShopifyPos = isShopifyPos();

export default function LocationPage() {
  const shopifyShopUrl = useShopifyShopUrl();

  const configurationDoc = useFirestoreDoc("configuration", shopifyShopUrl);

  const { locationId } = useParams();
  const posLocationDoc = useFirestoreDoc(
    "posLocation",
    `${shopifyShopUrl}:${locationId}`,
  );

  const navigate = useNavigate();

  useHideLoadingIndicator();

  if (configurationDoc.isLoading || posLocationDoc.isLoading) {
    return (
      <Page narrowWidth>
        <TitleBar title="Carregando local..." />
        <LoadingFrame />
        <VersionFooter />
      </Page>
    );
  } else if (configurationDoc.didFail || posLocationDoc.didFail) {
    return (
      <UnexpectedErrorPage
        error={
          [configurationDoc.error, posLocationDoc.error].find((r) => r.error)
            .error
        }
      />
    );
  } else {
    const configuration = configurationDoc.data;
    const isTef = typeof configuration?.payment?.tefHouse === "string";

    const posLocation = posLocationDoc.data;

    return (
      <Page
        breadcrumbs={
          _isShopifyPos
            ? [{ content: "Locais", onAction: () => navigate("/") }]
            : []
        }
      >
        <TitleBar
          title={posLocation.name}
          breadcrumbs={[{ content: "Locais", onAction: () => navigate("/") }]}
        />
        <Layout>
          <Layout.Section>
            <Card title="CNPJ">
              <Card.Section>
                <FormLayout>
                  <TextField
                    value={formatCnpj(posLocation.cnpj) ?? "___.___.___-__"}
                    disabled={true}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
            {isTef ? (
              <Card title="TEF">
                <Card.Section>
                  <FormLayout>
                    <TextField
                      label="Adquirente"
                      value={capitalize(configuration.payment.acquirer)}
                      disabled={true}
                    />
                    {/* <TextField
                      label="TEF House"
                      value={configuration.payment.tefHouse}
                      disabled={true}
                    /> */}
                    <TextField
                      label="Dispositivo"
                      value={configuration.payment.device}
                      disabled={true}
                    />
                    <Button
                      fullWidth
                      onClick={() =>
                        window.open("iglu://open-tef-admin-menu/", "_blank")
                      }
                    >
                      Abrir Menu Gerencial
                    </Button>
                  </FormLayout>
                </Card.Section>
              </Card>
            ) : (
              <>
                <PspDeviceListCard
                  shopifyShopUrl={shopifyShopUrl}
                  posLocation={posLocation}
                />
                <PendingPaymentListCard
                  shopifyShopUrl={shopifyShopUrl}
                  posLocation={posLocation}
                />
              </>
            )}
          </Layout.Section>
        </Layout>
        <VersionFooter />
      </Page>
    );
  }
}

function capitalize(string) {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
}
