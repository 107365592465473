import { v4 as uuid } from "uuid";
import { create } from "zustand";

export const useUniquePaymentStore = create((set) => ({
  id: uuid(),
  paymentMethod: null,
  paymentInstallments: "1",
  linkPaymentService: undefined,
  chargedSeparately: false,
  cardFlag: undefined,
  pixService: undefined,
  nsu: "",
  aut: "",
  automaticConciliation: null,
  status: "creating",

  setPayment(payment) {
    set({
      id: payment.id,
      paymentMethod: payment.paymentMethod,
      paymentInstallments: payment.paymentInstallments,
      linkPaymentService: payment.linkPaymentService,
      chargedSeparately: payment.chargedSeparately,
      cardFlag: payment.cardFlag,
      pixService: payment.pixService,
      nsu: payment.nsu,
      aut: payment.aut,
      automaticConciliation: payment.automaticConciliation,
    });
  },

  setPaymentId(paymentId = uuid()) {
    set({ id: paymentId });
  },

  setPaymentMethod(paymentMethod) {
    set({
      paymentMethod,
      paymentInstallments: "1",
      chargedSeparately: false,
      cardFlag: null,
      pixService: null,
      nsu: "",
      aut: "",
      automaticConciliation: null,
    });
  },

  setPaymentInstallments(paymentInstallments) {
    set({ paymentInstallments });
  },

  setLinkPaymentService(linkPaymentService) {
    set({ linkPaymentService });
  },

  setChargedSeparately(chargedSeparately) {
    set({
      chargedSeparately,
      cardFlag: null,
      pixService: null,
      nsu: "",
      aut: "",
      automaticConciliation: null,
    });
  },

  setCardFlag(cardFlag) {
    set({ cardFlag });
  },

  setPixService(pixService) {
    set({ pixService });
  },

  setNsu(nsu) {
    set({ nsu });
  },

  setAut(aut) {
    set({ aut });
  },

  setAutomaticConciliation(automaticConciliation) {
    set({ automaticConciliation });
  },

  setStatus(status) {
    set({ status });
  },
}));
